.p-jobs {

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
        @include flex-align(center);
    }

    &__content {
        @include xy-cell(12);

        @include breakpoint(medium only) {
            @include xy-cell(10);
        }
    }

    &__block-area {
        @extend .c-page-grid__outer;
    }
}