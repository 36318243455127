.p-isycasemap {
    $crisis-web: '.crisis-web';

    @extend .c-page-grid;
    @include gradient-bg();

    &__outer {
        @extend .c-page-grid__outer;

        #{$crisis-web} & {
            justify-content: center;
        }
    }

    &__sidenav {
        @extend .c-page-grid--two-cols__side;
    }

    &__article {
        @extend .c-page-grid--two-cols__main;

        #{$crisis-web} & {
            @extend .c-page-grid--center__inner;
        }
    }

    &__container {
        @extend .c-page-grid--two-cols__article;
    }

    &__inner {
        @extend .c-page-grid--center__content;
        position: relative;
        @include breakpoint(large) {
            overflow: hidden;
        }

        #{$crisis-web} & {
            @extend .c-page-grid--center__article;
        }
    }
}