.c-example {
    padding: rem-calc(15);
    .c-information-block & {
        padding: 0;
    }
    &__outer {
        display: block;
        @include xy-gutters($negative: true);
    }
    &__item {
        @include xy-cell(12);
        @include breakpoint(medium) {
            @include xy-cell(6);
        }
        float: left;
    }
    &__label {
        font-weight: bold;
    }
    &__image-wrapper {
        position: relative;
    }
    &__image {
        margin-bottom: rem-calc(10);
        .c-editor & {
            margin-bottom: rem-calc(10);
        }
    }
    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        width: rem-calc(40);
        height: rem-calc(40);
        margin: rem-calc(10);
        font-size: rem-calc(20);
        border-radius: 100%;
        background: $s-black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $s-white;
        &--right {
            background: $s-teal;
        }
        &--wrong {
            background: $s-orange;
        }
    }
    &__caption {
        @include xy-cell(12);
        font-size: rem-calc(14);
        line-height: normal;
    }
}