.c-article-list {
    $c-article-list: '.c-article-list';
    $c-block-grid-half: '.c-block-grid--half';
    $c-editor: '.c-editor';
    $p-landing: '.p-landing';
    $using-keyboard: '.using-keyboard';
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }

    &__outer {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
    }

    &__inner {
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }

    &__heading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(5);
    }

    &__link {
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        span {
            display: inline;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__figure {
        margin: rem-calc(0 0 15 0);
        flex: 0 0 auto;
        
        @include breakpoint(medium) {
            width: 33.33333%;
            padding: rem-calc(0 30 0 0);
        }
    }

    &__preamble {
        margin: 0;
    }

    &__categories {
        display: flex;
        flex-wrap: wrap;
    }

    &__category {
        background: $s-orange-light;
        color: #8F2D0A;
        margin: rem-calc(0 10 10 0);
        text-transform: uppercase;
        font-family: $s-font-family-alt;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(1);
        padding: rem-calc(4 10);
        border-radius: rem-calc(2);
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: rem-calc(20);

        @include breakpoint(medium) {
            flex-direction: row;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    /* Different layout on Landing Page */
    #{$p-landing} & {

        &__inner {

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__item {

            @include breakpoint(large) {
                flex-direction: row;
            }
        }
    }

    /* Different layout on Landing Page + Block - Half Width */
    #{$p-landing} #{$c-block-grid-half} & {

        &__item {

            @include breakpoint(large) {
                flex-direction: column;
            }
        }

        &__figure {

            @include breakpoint(large) {
                width: 100%;
                margin: rem-calc(0 0 15 0);
            }
        }
    }

    /* Different layout in Block - Half Width */
    #{$c-block-grid-half} & {

        &__inner {

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }
    }

    /* Different layout in Editor */
    #{$c-editor} & {
        margin: rem-calc(30 0);

        @include breakpoint(medium) {
            margin: rem-calc(50 0);
        }

        &__figure {
            
            @include breakpoint(large only) {
                width: 100%;
                padding: 0;
                margin: rem-calc(0 0 15 0);
            }

            img {
                margin: 0;
            }
        }

        &__inner {
    
            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__item {

            @include breakpoint(large only) {
                flex-direction: column;
            }
        }

        &--has-images {
            #{$c-article-list}__figure {
                
                @include breakpoint(xlarge) {
                    padding: rem-calc(0 15 0 0);
                }
            }

            #{$c-article-list}__description {

                @include breakpoint(xlarge) {
                    padding: rem-calc(0 0 0 15);
                }
            }
        }
    }
}