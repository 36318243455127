.p-permit-listing {
    @extend .c-page-grid;
    @include gradient-bg();
    overflow: hidden;

    &__outer {
        @extend .c-page-grid__outer;
    }

    &__results {
        @include xy-cell;
        padding-top: rem-calc(30);
        margin-top: rem-calc(30);
        border-top: rem-calc(2) solid $s-gray-light;
    }

    &__list {
        @extend .c-page-grid__outer;
    }

    &__header {
        position: relative;
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(10);
        }
    }
    &__search {
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(10);
        }
    }

    &__sidenav {
        @extend .c-page-grid--two-cols__side;
    }

    &__article {
        @extend .c-page-grid--two-cols__main;
    }

    &__container {
        @extend .c-page-grid--two-cols__article;
    }

    &__inner {
        @extend .c-page-grid--two-cols__content;
        position: relative;
    }

    &__preamble {
        @extend .preamble;
    }
    &__label {
        font-weight: bold;
    }
    
}
