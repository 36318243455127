.c-page-info {
    padding: rem-calc(15 0 10 0);

    @include breakpoint(medium) {
        padding: rem-calc(15 0 15 0);
    }
    
    &__label {
        font-size: rem-calc(16);
        font-weight: $s-font-bold;
        margin-bottom: rem-calc(10);
    }
}