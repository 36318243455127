.c-hero {
  $c-hero: '.c-hero';
  $using-keyboard: '.using-keyboard';
  min-height: rem-calc(135);
  margin-bottom: rem-calc(15);
  @include xy-cell(12, $gutters:0);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    height: rem-calc(300);
    @include xy-cell(12);
    margin-bottom: rem-calc(32);
  }

  &__outer {

    @include breakpoint (medium) {
      height: 100%;
    }

    position: relative;
    overflow: hidden;
  }

  &__inner {
    @include breakpoint(medium) {
      height: 100%;
      position: relative;
    }
  }

  &__content {
    justify-content: center;
    height: 100%;
    overflow: hidden;

    @include breakpoint(large) {
      display: flex;
    }
  }

  &__list {
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    grid-auto-rows: 1fr;
  }

  &__intro {
    align-self: center;
    text-align: center;
    min-height: rem-calc(135);
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium) {
      min-height: rem-calc(300);
    }

    @include breakpoint(large) {
      min-height: auto;
    }
  }

  &__heading {
    font-size: rem-calc(28);
    line-height: rem-calc(40);
    padding: rem-calc(0 20);
    background: $s-white;
    min-height: rem-calc(40);
    display: inline-block;
    border-radius: rem-calc(2);
    margin: 0;
    max-width: rem-calc(380);
    margin-bottom: rem-calc(30);
    @include breakpoint(small down) {
      margin-bottom: rem-calc(15);
      display: none;
    }

    @include breakpoint(medium) {
      text-align: center;
      flex-grow: inherit;
      padding: rem-calc(0 40);
      line-height: rem-calc(70);
      min-height: rem-calc(70);
      font-size: rem-calc(38);
      max-width: rem-calc(380);
    }

    @include breakpoint(large) {
      max-width: rem-calc(580);
    }
  }

  &__item {
    margin-bottom: rem-calc(10);

    @include breakpoint(large) {
      margin-bottom: rem-calc(30);
    }
  }

  &__link {
    width: auto;
    color: $s-white;
    font-size: rem-calc(16);
    font-weight: $s-font-bold;
    position: relative;
    display: inline;

    @include breakpoint(large) {
      font-family: $s-font-family-alt;
      font-size: rem-calc(18);
    }

    &:hover, &:focus {
      color: $s-white;
    }
  }

  &__background {
    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @include breakpoint(small down) {
      max-height: rem-calc(170);
    }

    z-index: -1;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    picture {
      height: 100%;
      width: 100%;
      flex: none;
      min-height: rem-calc(200);

      @include breakpoint(medium) {
        min-height: rem-calc(450);
      }

      @include breakpoint(large) {
        min-height: rem-calc(400);
      }
    }

    img, source {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      height: 100%;
      width: 100%;

      @include breakpoint(small down) {
        max-height: rem-calc(170);
      }
    }
  }

  &--start {
    margin: 0;
    width: 100%;
    height: unset;

    @include breakpoint(medium) {
      height: rem-calc(400);
    }

    @include breakpoint(large) {
      height: rem-calc(450);
    }

    #{$c-hero} {

      &__heading {
        text-shadow: rem-calc(0 0 5) rgba(0, 0, 0, 0.6);
        color: $s-white;
        background: none;
        height: auto;
        padding: 0;
        text-align: left;
        font-size: rem-calc(38);
        line-height: rem-calc(50);

        @include breakpoint(large) {
          font-size: rem-calc(50);
          line-height: rem-calc(60);
          margin-bottom: rem-calc(50);
          text-align: center;
        }
      }

      &__intro {
        flex-grow: 1;
        min-height: auto;

        @include breakpoint(medium down) {
          justify-content: left;
          align-self: auto;
        }
      }

      &__inner {
        display: flex;
        flex-direction: column;
        // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);

        @include breakpoint(large) {
          justify-content: center;
        }
      }

      &__content {
        padding: rem-calc(24 15 0 15);
        display: block;
        height: auto;

        @include breakpoint(medium down) {
          display: flex;
          flex-direction: column;
        }

        @include breakpoint(large) {
          align-self: center;
          padding: 0;
        }
      }

      &__list {
        background: none;
        padding: rem-calc(0 0 30 0);

        @include breakpoint(small down) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: rem-calc(15);
          padding: 0;
        }

        @include breakpoint(385 down) {
          grid-template-columns: 1fr;
        }

        @include breakpoint(large) {
          flex-direction: row;
          padding: 0;
        }
      }

      &__item {

        @include breakpoint(small down) {
          width: 100%;
        }

        @include breakpoint(large) {
          margin: 0;
        }

        &:nth-child(1) {

          #{$c-hero}__link {
            background: $s-blue;
            color: $s-white;

            &:hover, &:focus {
              color: $s-blue;
              background: $s-teal-lighter;
            }
          }
        }

        &:nth-child(2) {

          #{$c-hero}__link {
            background: $s-teal-dark;
            color: $s-white;

            &:hover, &:focus {
              color: $s-teal-dark;
              background: $s-teal-lighter;
            }
          }
        }

        &:nth-child(3) {

          #{$c-hero}__link {
            background: $s-pink-dark;
            color: $s-white;

            &:hover, &:focus {
              color: $s-pink-dark;
              background: $s-pink-light;
            }
          }
        }

        &:nth-child(4) {

          #{$c-hero}__link {
            background: $s-pink;
            color: $s-white;

            &:hover, &:focus {
              color: $s-pink;
              background: $s-pink-light;
            }
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {

          #{$c-hero}__link {

            #{$using-keyboard} & {

              &:focus {
                background: $s-yellow;
              }
            }
          }
        }
      }

      &__link {
        @extend .c-button;
        @extend .c-button--alt;
        display: inline-block;
        line-height: rem-calc(40);
        width: auto;

        @include breakpoint(small down) {
          display: flex;
          width: 100%;
          height: 100%;
          text-align: center;
          padding: rem-calc(14 25 14 25);
          line-height: rem-calc(18);
        }

        @include breakpoint(large) {
          margin: rem-calc(0 5);
        }
      }
    }
  }

  &--list,
  &--center {

    #{$c-hero} {

      &__intro {
        flex-grow: 1;
        min-height: auto;

        @include breakpoint(medium down) {
          @include responsive-embed(25 by 12);
          position: absolute;
          top: 0;
          width: 100%;
        }

        @include breakpoint(large) {
          flex: 0 0 50%;
        }
      }

      &__inner {

        @include breakpoint(medium down) {
          display: flex;
          flex-direction: column;
        }
      }

      &__content {

        @include breakpoint(medium down) {
          order: 2;
        }
      }

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
      }

      &__background {
        height: 100%;

        @include breakpoint(medium down) {
          position: relative;
          order: 1;
        }
      }

      &__wrapper {
        align-items: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        img {
          @include breakpoint(medium down) {
            object-fit: cover;
            height: auto;
          }
        }

        picture {
          min-height: auto;
        }
      }
    }
  }

  &--list {

    #{$c-hero} {
      overflow: hidden;

      &__block {
        @include xy-grid();
        @include xy-gutters($gutters: true);
        @include flex-align(center);
        background: $s-brand;

        @include breakpoint(large) {
          flex: 0 0 50%;
        }
      }

      &__links {
        @include xy-cell(12);
        margin: rem-calc(28 32 40 32);
        @include breakpoint(medium) {
          @include xy-cell(10);
        }

        @include breakpoint(large) {
          display: flex;
          align-items: center;
        }

        h1 {
          display: block;
          margin-bottom: unset;
          color: white;
          font-size: rem-calc(28);
          font-style: normal;
          font-weight: 700;

          @include breakpoint(medium) {
            display: none;
          }
        }
      }

      &__list {
        padding-top: rem-calc(12);

        @include breakpoint(medium down) {
          @include xy-grid();
          @include xy-gutters($gutters: true);
        }

        @include breakpoint(medium) {
          padding: rem-calc(15 0 30 0);
        }

        @include breakpoint(large) {
          padding: rem-calc(15 0 20 0);
        }
      }

      &__item {
        margin: rem-calc(0 0 15 0);

        &:last-child {
          margin: 0;
        }

        @include breakpoint(small only) {
          @include xy-cell(12, $gutters:0);
        }

        @include breakpoint(medium only) {
          @include xy-cell(6, $gutters:0);
          margin-bottom: rem-calc(5);
        }

        @include breakpoint(large) {
          margin: rem-calc(0 0 25 0);

          &:last-child {
            margin: 0;
          }
        }
      }

      &__link {
        display: inline-block;
        padding-right: rem-calc(14);

        &:hover, &:focus {

          span {
            @include link-underline($s-white);
          }
        }

        #{$using-keyboard} & {

          &:focus {
            @include link-underline($s-black);
          }
        }

        &::after {
          content: "";
          background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat center right;
          position: relative;
          width: rem-calc(14);
          height: rem-calc(11);
          display: inline-block;
          margin-right: rem-calc(-14);
        }

        span {
          position: relative;
        }
      }

      &__outer {

        @include breakpoint(medium down) {
          height: auto;
        }
      }

      &__background {

        @include breakpoint(large) {
          width: 50%;
        }
      }

      &__heading {
        max-width: none;

        @include breakpoint(small down) {
          line-height: rem-calc(42);
          min-height: rem-calc(42);
        }
      }
    }
  }

  &--center {

    #{$c-hero} {

      &__outer {

        @include breakpoint(medium down) {
          height: auto;
        }

        @include breakpoint(large) {
          width: 100%;
        }
      }

      &__wrapper {

        picture {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--login {
    margin-bottom: 0;
    align-items: baseline;
    min-height: rem-calc(350);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem-calc(15 0);
    margin-top: rem-calc(15);

    @include breakpoint(large) {
      height: rem-calc(300);
      margin-top: 0;
    }

    #{$c-hero} {

      &__intro {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include breakpoint(small only) {
          min-height: rem-calc(350);
        }
      }

      &__content {
        display: block;
      }

      &__list {
        padding: 0;
        justify-content: center;
        flex-wrap: wrap;
        display: flex;

        @include breakpoint(large) {
          flex-direction: row;
        }
      }

      &__item {
        margin: rem-calc(0 0 15 0);
        flex-grow: 1;

        @include breakpoint(medium only) {
          min-width: rem-calc(400);
        }

        @include breakpoint(large) {
          margin: rem-calc(0 5);
        }

        &:first-child {

          @include breakpoint(large) {
            margin: rem-calc(0 5 0 0);
          }
        }

        &:last-child {

          @include breakpoint(medium down) {
            margin: 0;
          }

          @include breakpoint(large) {
            margin: rem-calc(0 0 0 5);
          }
        }

        &:nth-child(1) {

          #{$c-hero}__link {
            background: $s-teal-dark;
            color: $s-white;

            &:hover, &:focus {
              color: $s-teal-dark;
              background: $s-teal-lighter;
            }
          }
        }

        &:nth-child(2) {

          #{$c-hero}__link {
            background: $s-blue;
            color: $s-white;

            &:hover, &:focus {
              color: $s-blue;
              background: $s-teal-lighter;
            }
          }
        }

        &:nth-child(3) {

          #{$c-hero}__link {
            background: $s-pink;
            color: $s-white;

            &:hover, &:focus {
              color: $s-pink;
              background: $s-pink-light;
            }
          }
        }

        &:nth-child(4) {

          #{$c-hero}__link {
            background: $s-pink-dark;
            color: $s-white;

            &:hover, &:focus {
              color: $s-pink-dark;
              background: $s-pink-light;
            }
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {

          #{$c-hero}__link {

            #{$using-keyboard} & {

              &:focus {
                background: $s-yellow;
              }
            }
          }
        }
      }

      &__link {
        @extend .c-button;
        @extend .c-button--alt;
        display: inline-block;
        line-height: rem-calc(40);
        width: 100%;
      }

      &__login {
        margin-top: rem-calc(15);
        width: 100%;
        padding: rem-calc(0 30);

        @include breakpoint(medium) {
          width: auto;
        }
      }

      &__heading {
        text-shadow: rem-calc(0 0 5) rgba(0, 0, 0, 0.6);
        color: $s-white;
        background: none;
        height: auto;
        padding: 0;
        text-align: center;
        font-size: rem-calc(28);
        line-height: rem-calc(42);
        margin: 0;
      }

      &__label {
        @include text-shadow();
        font-family: $s-font-family-alt;
        color: $s-white;
        text-transform: uppercase;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(1);
        margin: rem-calc(0 0 15 0);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(medium) {
          margin: rem-calc(0 0 30 0);
        }

        &::before {
          margin: rem-calc(0 20 0 0);
          flex: 1 0 rem-calc(20);
        }

        &::after {
          margin: rem-calc(0 0 0 20);
          flex: 1 0 rem-calc(20);
        }

        &::before, &::after {
          content: '';
          border-top: rem-calc(2) solid $s-white;
          position: relative;
          top: 0;
        }
      }
    }
  }
}
