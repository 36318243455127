.c-editor-links {
    margin: rem-calc(30 0);

    @include breakpoint(medium) {
        margin: rem-calc(50 0);
    }

    &__list {
        margin: 0;
        border: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(5 20);
        border-radius: rem-calc(4);

        @include breakpoint(medium) {
            padding: rem-calc(5 30);
        }
    }

    &__item {
        @include xy-grid();
        @include xy-gutters($negative: true);

        list-style-type: none;
        padding: rem-calc(15 0);
        display: flex;
        align-items: center;
        position: relative;

        &:not(:last-child) {

            &::after {
                content: '';
                height: rem-calc(1);
                border-bottom: rem-calc(2) solid $s-gray-light;
                position: absolute;
                left: rem-calc(15);
                right: rem-calc(15);
                bottom: 0;
            }
        }
    }

    &__link {
        font-weight: $s-font-bold;
        position: relative;
        margin-left: rem-calc(15);
        display: inline-block;

        @include breakpoint(medium) {
            font-size: rem-calc(15);
        }

        &:hover, &:focus {
            color: $s-blue;

            span {
                @include link-underline($s-blue);
            }
        }

        &[href="http://www.sollentuna.se"] {
            margin-right: rem-calc(18);

            span {

                &::after {
                    content: "";
                    background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                    display: inline-block;
                    width: rem-calc(12);
                    height: rem-calc(11);
                    position: absolute;
                    bottom: rem-calc(7);
                    margin-left: rem-calc(5);
                }
            }
        }

        &[href^="http://"]:not([href*="www.sollentuna.se"]) {
            margin-right: rem-calc(22);
            display: inline;
            position: relative;

            span {

                &::after {
                    @include external-link-editor();
                    bottom: rem-calc(2);
                }
            }
        }
    }
}