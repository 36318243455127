.c-editor-files {
    $using-keyboard: '.using-keyboard';
    margin: rem-calc(30 0);
    @include breakpoint(large) {
        margin: rem-calc(35 0);
    }
    &__heading {
        font-family: $s-font-family-alt;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(1);
        text-transform: uppercase;
        margin-bottom: rem-calc(10);
        display: block;
    }
    &__list {
        margin: 0 !important;
        border: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(5 15);
        border-radius: rem-calc(4);
        @include breakpoint(medium) {
            padding: rem-calc(5 20);
        }
        @include breakpoint(large) {
            padding: rem-calc(5 30);
        }
    }
    &__item {
        @include xy-grid();
        @include xy-gutters($negative: true);
        list-style-type: none;
        padding: rem-calc(15 10);
        position: relative;
        @include breakpoint(medium) {
            padding: rem-calc(15 0);
        }
        &:not(:last-child) {
            &::after {
                content: '';
                height: rem-calc(1);
                border-bottom: rem-calc(2) solid $s-gray-light;
                position: absolute;
                left: rem-calc(15);
                right: rem-calc(15);
                bottom: 0;
            }
        }
    }
    &__col {
        display: flex;
        &--title {
            @include xy-cell(8, $gutters: 10);
            @include breakpoint(medium) {
                @include xy-cell(8);
            }
        }
        &--size {
            @include xy-cell(2, $gutters: 10);
            color: $s-gray-dark;
            font-family: $s-font-family-alt;
            font-size: rem-calc(12);
            font-weight: $s-font-bold;
            position: relative;
            top: rem-calc(3);
            @include breakpoint(medium) {
                @include xy-cell(2);
            }
        }
        &--download {
            position: relative;
            @include xy-cell(2, $gutters: 10);
            justify-content: flex-end;
            @include breakpoint(medium) {
                @include xy-cell(2);
            }
        }
    }
    &__link {
        font-weight: $s-font-bold;
        position: relative;
        margin-left: rem-calc(20);
        word-break: break-word;
        @include breakpoint(large) {
            font-size: rem-calc(15);
        }
        &--title {
            &[href$=".pdf"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c1";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".doc"],
            &[href$=".docx"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c2";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".odt"],
            &[href$=".rtf"],
            &[href$=".txt"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f15c";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".pps"],
            &[href$=".ppt"],
            &[href$=".pptx"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c4";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".ai"],
            &[href$=".eps"],
            &[href$=".ps"],
            &[href$=".svg"],
            &[href$=".gif"],
            &[href$=".jpg"],
            &[href$=".png"],
            &[href$=".psd"],
            &[href$=".tif"],
            &[href$=".tiff"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c5";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".xlsx"],
            &[href$=".xlsm"],
            &[href$=".xlsb"],
            &[href$=".xltx"],
            &[href$=".xltm"],
            &[href$=".xls"],
            &[href$=".xlt"],
            &[href$=".xls"],
            &[href$=".xml"],
            &[href$=".xml"],
            &[href$=".xlam"],
            &[href$=".xla"],
            &[href$=".xlw"],
            &[href$=".xlr"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c3";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &[href$=".mp3"],
            &[href$=".wav"],
            &[href$=".aiff"],
            &[href$=".aiff"],
            &[href$=".aac"],
            &[href$=".ogg"],
            &[href$=".wma"],
            &[href$=".flac"] {
                margin-left: rem-calc(25);
                &::before {
                    content: "\f1c7";
                    font-family: $s-font-icon;
                    font-size: rem-calc(18);
                    font-weight: 500;
                    color: $s-black;
                    position: absolute;
                    top: rem-calc(-3);
                    left: rem-calc(-25);
                }
            }
            &:hover,
            &:focus {
                color: $s-blue;
                position: relative;
                span {
                    @include link-underline($s-blue);
                }
            }
            span {
                position: relative;
                word-break: break-word;
            }
            #{$using-keyboard} & {
                &:focus {
                    background: none;
                    span {
                        background: $s-yellow;
                    }
                }
            }
            #{$using-keyboard} & {
                &:focus {
                    background: none;
                    span {
                        background: $s-yellow;
                    }
                }
            }
        }
        &--download {
            width: rem-calc(24);
            height: rem-calc(24);
            background-color: $s-teal-light;
            border-radius: rem-calc(15);
            justify-content: center;
            transition: all .3s ease 0s;
            position: absolute;
            @include breakpoint(medium down) {
                margin-left: 0;
            }
            &::before {
                content: '';
                background: url(/static/assets/images/icon-arrow-down-blue.svg) no-repeat center;
                width: rem-calc(12);
                height: rem-calc(12);
                position: absolute;
                top: rem-calc(6);
                left: rem-calc(6);
            }
            #{$using-keyboard} & {
                &:focus {
                    &::before {
                        background: url(/static/assets/images/icon-arrow-down-black.svg) no-repeat center;
                    }
                }
            }
            &:hover {
                background-color: $s-blue;
                &::before {
                    background: url(/static/assets/images/icon-arrow-down-white.svg) no-repeat center;
                }
            }
        }
    }
}