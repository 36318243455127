.c-nav-portals {
    $using-keyboard: '.using-keyboard';
    $crisis-web: '.crisis-web';

    @include breakpoint(large) {
        display: block;
        height: rem-calc(42);
    }

    &__list {
        list-style-type: none;
        margin: 0;
        border-bottom: rem-calc(1) solid $s-brand-tint;
        padding: rem-calc(25 15);

        @include breakpoint(large) {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            border: none;
        }
    }

    &__item {
        margin-bottom: rem-calc(10);
        display: flex;
        align-items: center;

        @include breakpoint(large) {
            height: rem-calc(42);
            display: flex;
            align-items: center;
            padding: rem-calc(0 30);
            margin: 0;

            &:first-child {
                text-align: right;
                justify-content: flex-end;
                background: $s-teal-lighter-portal;

                #{$crisis-web} & {
                    background: $s-brand-tint;
                    justify-content: center;
                }
            }
    
            &:nth-child(2) {
                background: $s-teal-light-portal;
            }
    
            &:nth-child(3) {
                background: $s-teal-portal;
            }
    
            &:nth-child(4) {
                background: $s-teal-portal;
            }
    
            &:first-child,
            &:last-child {
                flex: 1;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        color: $s-white;
        font-family: $s-font-family-alt;
        font-size: rem-calc(15);
        position: relative !important;
        padding-left: rem-calc(20);

        &::before {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat;
            display: block;
            width: rem-calc(12);
            height: rem-calc(11);
            position: absolute;
            left: 0;
            top: rem-calc(7);
        }

        &[href^="https://"]:not([href*="www.sollentuna.se"]),
        &[href^="http://"]:not([href*="www.sollentuna.se"]) {
            padding-right: rem-calc(22);
    
            &::after {
                content: "\00a0\00a0\f35d";
                font-family: $s-font-icon;
                font-weight: $s-font-bold;
                font-size: rem-calc(14);
                top: 0;
                right: 0;
                position: absolute;
            }
        }

        @include breakpoint(large) {
            font-weight: $s-font-bold;
            font-size: rem-calc(15);
            position: relative;
            color: $s-blue;
            font-family: $s-font-family;
            padding: rem-calc(0 24 0 0);

            &::before {
                content: none;
            }

            &::after {
                content: "\f061";
                font-family: $s-font-icon;
                font-weight: 400;
                display: inline-block;
                font-size: rem-calc(18);
                position: absolute;
                right: 0;
                top: rem-calc(-2);
            }
        }

        &:hover, &:focus {
            color: $s-white;

            span {
                @include link-underline($s-white);
            }

            @include breakpoint(large) {
                color: $s-blue;

                span {
                    @include link-underline($s-blue);
                }
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }

        &--active {

            @include breakpoint(large) {

                span {
                    @include link-underline($s-blue);
                }
            }
        }

        span {
            position: relative;
        }
    }
}
