@mixin link-underline($border-color) {
    border-bottom: rem-calc(2) solid $border-color;
    padding-bottom: rem-calc(1);
}

@mixin text-shadow() {
    text-shadow: rem-calc(0 0 5) rgba(0, 0, 0, 0.7);
}


/* External Links in Body Text */

@mixin external-link-editor() {
    content: "\00a0\00a0\f35d";
    font-family: $s-font-icon;
    font-weight: $s-font-bold;
    font-size: rem-calc(13);
    position: relative;
    color: $s-blue;
}


/* External Links in Single Use */

@mixin external-link-single($bottom) {
    content: "\00a0\00a0\f35d";
    font-family: $s-font-icon;
    font-weight: $s-font-bold;
    font-size: rem-calc(13);
    position: absolute;
    bottom: rem-calc($bottom);
    color: $s-blue;
}


/* External Links in Lists */

@mixin external-link-item() {
    content: "\f35d";
    font-family: $s-font-icon;
    font-weight: $s-font-bold;
    font-size: rem-calc(13);
    position: relative;
    display: inline-block;
    margin-right: rem-calc(-16);
    width: rem-calc(16);
    text-align: right;
    bottom: rem-calc(1);
    color: $s-black;
}


/* External Links in Lists */

@mixin download-link-item() {
    content: "\f019";
    font-family: $s-font-icon;
    font-weight: $s-font-bold;
    font-size: rem-calc(13);
    position: relative;
    display: inline-block;
    margin-right: rem-calc(-16);
    width: rem-calc(16);
    text-align: right;
    bottom: rem-calc(1);
    color: $s-black;
}

@mixin icon-services($size, $font-size) {
    content: "\f044";
    font-family: $s-font-icon;
    background: $s-orange-light;
    color: $s-orange-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    font-weight: 400;
    line-height: rem-calc(38);
}

@mixin icon-event($size, $font-size) {
    content: "\f073";
    font-family: $s-font-icon;
    font-weight: $s-font-regular;
    background: $s-pink-dark;
    color: $s-white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
}

@mixin icon-doc($size, $font-size) {
    content: "\f0c6";
    font-family: $s-font-icon;
    font-weight: $s-font-semibold;
    background: $s-blue;
    color: $s-white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
}

@mixin icon-page($size, $font-size) {
    content: "\f15c";
    font-family: $s-font-icon;
    font-weight: $s-font-regular;
    background: $s-teal-dark;
    color: $s-white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
}

@mixin icon-news($size, $font-size) {
    content: "\f1ea";
    font-family: $s-font-icon;
    font-weight: $s-font-regular;
    background: $s-pink;
    color: $s-white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
}

@mixin icon-meeting($size, $font-size) {
    content: "\00a0\00a0\f073";
    font-family: $s-font-icon;
    font-weight: $s-font-regular;
    background: $s-pink-light;
    color: $s-pink;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
    padding-right: rem-calc(10);
}

@mixin icon-folder($size, $font-size) {
    content: "\00a0\00a0\f07b";
    font-family: $s-font-icon;
    font-weight: $s-font-regular;
    background: $s-orange-light;
    color: $s-orange-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem-calc($size);
    height: rem-calc($size);
    width: rem-calc($size);
    font-size: rem-calc($font-size);
    line-height: rem-calc(38);
    padding-right: rem-calc(10);
}

@mixin tag-date() {
    font-family: $s-font-family-alt;
    color: $s-gray-dark;
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
    display: inline-flex;
    &::before {
        content: "\f017";
        font-family: $s-font-icon;
        margin-right: rem-calc(3);
    }
}

@mixin tag-category() {
    font-family: $s-font-family-alt;
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
}

@mixin gradient-bg() {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#F9F9F9), to(transparent));
    background-image: -webkit-linear-gradient(top, #F9F9F9, hsla(0, 0, 100%, 0));
    background-image: -moz-linear-gradient(top, #F9F9F9, hsla(0, 0, 100%, 0));
    background-image: -o-linear-gradient(top, #F9F9F9, hsla(0, 0, 100%, 0));
    background-image: linear-gradient(to bottom, #F9F9F9, hsla(0, 0, 100%, 0));
    background-repeat: no-repeat;
    background-size: 100% rem-calc(75);
    padding-top: rem-calc(30);
    @include breakpoint(large) {
        background: none;
        padding-top: 0;
    }
}

@mixin link-arrow() {
    content: "";
    background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
    width: rem-calc(12);
    height: rem-calc(11);
    position: absolute;
}

@mixin tag($font-color) {
    color: $font-color;
    font-family: $s-font-family-alt;
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: rem-calc(1);
}

@mixin editor-ul-list() {
    margin: rem-calc(0 0 20 0);
    padding: 0;
    li {
        list-style-type: none;
        position: relative;
        padding-left: rem-calc(25);
        &::before {
            content: "\f111";
            font-family: $s-font-icon;
            font-size: rem-calc(7);
            font-weight: $s-font-bold;
            color: $s-blue;
            position: absolute;
            top: rem-calc(7);
            left: 0;
        }
        a {
            @include link-underline($s-blue);
            margin-right: rem-calc(25);
            display: inline;
            position: relative;
            &[href^="http://"]:not([href*="www.sollentuna.se"]),
            &[href^="https://"]:not([href*="www.sollentuna.se"]) {
                &::after {
                    @include external-link-editor();
                    margin-right: rem-calc(-20);
                }
            }
        }
    }
}

@mixin editor-ol-list() {
    margin: rem-calc(0 0 20 0);
    padding: 0;
    list-style: none;
    counter-reset: item;
    li {
        list-style-type: none;
        position: relative;
        padding-left: rem-calc(25);
        counter-increment: item;
        &::before {
            content: counter(item)'.';
            position: absolute;
            left: 0;
            font-weight: $s-font-bold;
        }
        a {
            @include link-underline($s-blue);
            margin-right: rem-calc(25);
            display: inline;
            position: relative;
            &[href^="http://"]:not([href*="www.sollentuna.se"]),
            &[href^="https://"]:not([href*="www.sollentuna.se"]) {
                &::after {
                    @include external-link-editor();
                    margin-right: rem-calc(-20);
                }
            }
        }
    }
}

@mixin editor-img() {
    display: block;
    margin-bottom: rem-calc(20);
    @include breakpoint(medium) {
        margin: rem-calc(5 0 25 0);
    }
}

@mixin print{
    @media print {
        @content;
    }
}
