.p-building-permit {
    &__article {
        @extend .c-page-grid--two-cols__main;
        .c-block-grid {
            margin: 0;
            padding-bottom: rem-calc(15);
            width: 100%;
        }
    }
    &__container {
        @extend .c-page-grid--two-cols__article;
    }
    &__inner {
        @extend .c-page-grid--two-cols__content;
        position: relative;
    }
    &__head {
        @extend .c-editor__head;
        h1 {
            max-width: calc(100% - 120px);
        }
    }
    &__preamble {
        @extend .preamble;
    }
    &__category {
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        font-size: rem-calc(12);
        margin-bottom: rem-calc(5);
        font-weight: 700;
    }
    &__permit {
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        line-height: rem-calc(12);
        padding: rem-calc(10);
        background: $s-blue;
        color: $s-white;
        font-weight: 700;
        margin: rem-calc(10 0 20);
        display: inline-block;
    }
    &__info {
        background: $s-teal-light;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem-calc(20);
        padding-top: rem-calc(10);
    }
    &__info-isyCase{
        background: $s-blue-light;
        display: flex;
        flex-wrap: wrap;
        margin-top: rem-calc(10);
        padding-top: rem-calc(10);
        width: 100%;
        margin-top: auto;
    }
    &__info-item {
        width: 100%;
        padding: rem-calc(0 10 10);
        display: flex;
        text-align: left;
        @include breakpoint(medium) {
            width: 50%;
        }
    }
    &__icon {
        margin-right: rem-calc(10);
        color: $s-blue;
    }
    &__label {
        font-weight: bold;
        margin-right: rem-calc(3);
    }
}
