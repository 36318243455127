$c-block-grid-full: ".c-block-grid--full";
$c-block-grid-half: ".c-block-grid--half";
$c-block-grid-third: ".c-block-grid--third";
$c-block-grid-two-thirds: ".c-block-grid--two-thirds";
.c-instagram-feed {
    @include xy-grid();
    @include xy-gutters($negative: true);
    &__item {
        padding-bottom: rem-calc(15);
        @include breakpoint(medium) {
            padding-bottom: rem-calc(30);
        }
        @include xy-cell(12);
        @include breakpoint(medium) {
            @include xy-cell(6);
        }
        @include breakpoint(large) {
            @include xy-cell(4);
        }
        #{$c-block-grid-half} & {
            @include breakpoint(medium) {
                @include xy-cell(6);
            }
            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }
        #{$c-block-grid-third} & {
            @include breakpoint(medium) {
                @include xy-cell(6);
            }
            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }
        #{$c-block-grid-two-thirds} & {
            @include breakpoint(medium) {
                @include xy-cell(6);
            }
            @include breakpoint(large) {
                @include xy-cell(6);
            }
        }
    }
    &__media-wrapper {
        @include breakpoint(medium) {
            position: relative;
            overflow: hidden;
        }
        @include breakpoint(large) {
            max-height: rem-calc(280);
        }
        @include breakpoint(xlarge) {
            max-height: rem-calc(360);
        }
        #{$c-block-grid-third} & {
            @include breakpoint(large) {
                max-height: rem-calc(200);
            }
            @include breakpoint(xlarge) {
                max-height: rem-calc(220);
            }
        }
    }
    &__caption {
        word-break: break-word;
        color: $s-black;
        margin-top: rem-calc(10);
    }
    &__image {
        object-fit: cover;
        .c-instagram-feed__link:focus & {
            opacity: .6;
        }
    }
    &__video {
        max-width: 100%;
        .c-instagram-feed__link:focus & {
            opacity: .6;
        }
    }
    &__link {
        display: block;
        &:focus {
            .using-keyboard & {
                border: rem-calc(2) solid $s-black;
            }
        }
    }
}