.c-isyCase-list {
    $c-isyCase-list: '.c-isyCase-list';
    $c-block-grid-half: '.c-block-grid--half';
    $c-editor: '.c-editor';
    $p-landing: '.p-landing';
    $using-keyboard: '.using-keyboard';
    margin-bottom: rem-calc(15);

    &__heading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(5);
    }

    &__link {
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        span {
            display: inline;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__figure {
        // margin: rem-calc(0 0 15 0);
        flex: 0 0 auto;
        
        @include breakpoint(medium) {
            // margin: rem-calc(0 30 0 0);
        }
    }

    &__preamble {
        margin: 0;
    }


    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap:rem-calc(10); 
        margin-bottom: rem-calc(40);

        @media screen and (min-width: 992px) {
            flex-direction: row;
            // gap:1.875rem;
            gap:rem-calc(30); 
        }

        &:last-child {
            margin-bottom: rem-calc(48);
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        width: 100%;

        // @media screen and (max-width: 992px) {
        //     width: 100%;
        // }

        &:last-child {
            justify-content: flex-end;
        }
    }

    
    /* Different layout in Editor */
    #{$c-editor} & {
        margin: rem-calc(30 0);

        @include breakpoint(medium) {
            margin: rem-calc(50 0);
        }

        &__figure {
            // margin: rem-calc(0 0 15 0);
            flex: 0 0 auto;
            
            @include breakpoint(medium) {
                // margin: rem-calc(0 30 0 0);
            }
        }

        &__inner {
    
            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            margin-bottom: rem-calc(40);
            gap:rem-calc(10); 
    
            @media screen and (min-width: 1200px) {
                flex-direction: row;
                gap:rem-calc(30); 
            }
    
            &:last-child {
            margin-bottom: rem-calc(48);
            }
        }

        &--has-images {
            
            @include breakpoint(xlarge) {
                margin-left: calc(-33.33333% + 1.875rem);
                margin-right: calc(-33.33333% + 1.875rem);
            }

            #{$c-isyCase-list}__figure {
                
                @include breakpoint(xlarge) {
                    padding: rem-calc(0 15 0 0);
                }
            }

            #{$c-isyCase-list}__description {
                
                @include breakpoint(xlarge) {
                    padding: rem-calc(0 0 0 15);
                }
            }
        }
    }
}

$isycaselistblock : '.isycaselistblock';
.c-block-grid    {
    &.isycaselistblock {
        width: 100%;
        margin:0;
    }
}