.c-cookie {
    z-index: 99;
    background: $s-gray-dark;
    width: 100%;
    transition: transform .3s ease;

    &--hidden {
        transform: translateY(-100%);
        transition: transform .3s ease;
    }

    &__outer {
        @include xy-grid-container;
    }

    &__inner {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
    }

    &__content {
        @include xy-cell(12);
        @include flex;
        @include flex-align($x:center,$y:middle);
        @include flex-direction($direction:column);
        justify-content: space-evenly;
        min-height: rem-calc(70);
        padding: rem-calc(15 0);
        position: relative;

        @include breakpoint(medium) {
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            @include xy-cell(12);
            @include flex-direction($direction:row);
        }
    }

    &__message {
        margin: rem-calc(0 0 0 0);
        padding: rem-calc(0 20 0 30);
        font-size: rem-calc(16);
        color: $s-white;
        position: relative;

        &::before {
            content: "\f564";
            font-family: $s-font-icon;
            font-weight: $s-font-bold;
            margin-right: rem-calc(10);
            background-color: #ffffff6e;
            background-position: center;
            width: 50px;
            height: 50px;
            padding: 0.5rem;
            border-radius: 50%;
        }

        @include breakpoint(large) {
            margin: 0;
        }
    }

    &__link {
        font-weight: $s-font-bold;
        padding-bottom: 0;
        color: $s-white;

        span {
            @include link-underline($s-white);
            padding-bottom: 0;
        }

        &::before {
            content: "\00a0";
        }

        &:hover,
        &:focus {
            color: $s-white;
        }
    }

    &__button-container {
        display: flex;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--small;
        background: $s-white;
        border-color: $s-white;
        color: black;
        padding: rem-calc(20 20);
        margin-top: rem-calc(20);
        width: rem-calc(125);
        font-size: rem-calc(16);

        @include breakpoint(large) {
            margin-top: 0;
            width: auto;
        }

        &:hover {
            background: #ffffff7d;
            color: black;
        }
    }

    &__button-decline {
        @extend .c-button;
        @extend .c-button--small;
        border-color: $s-white;
        color: $s-white;
        padding: rem-calc(20 20);
        margin-top: rem-calc(20);
        margin-left: rem-calc(15);
        width: rem-calc(125);
        font-size: rem-calc(16);

        @include breakpoint(large) {
            margin-top: 0;
            width: auto;
        }

        &:hover {
            background: $s-white;
            color: black;
        }
    }
}
