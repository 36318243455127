.c-contact-card {
  $c-contact-card: '.c-contact-card';
  $c-editor: '.c-editor';
  $c-block-grid-half: '.c-block-grid--half';
  $iframe: '.c-editor__iframe';

  margin-bottom: rem-calc(15);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(60);
  }

  &__heading,
  &__subheading {
    font-size: rem-calc(28);
    line-height: rem-calc(42);
    margin: rem-calc(0 0 10 0);
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
      padding: rem-calc(0 15);
    }
  }

  &__left {
    background: $s-teal-light;
    width: 100%;
    padding: rem-calc(20 15);

    @include breakpoint(medium) {
      padding: rem-calc(20 30);
    }

    @include breakpoint(large) {
      padding: rem-calc(30 50);
    }

    @include breakpoint(large) {
      width: 40%;
      flex-grow: 1;
      width: auto;
    }
  }

  &__right {
    width: 100%;
    background: $s-gray-silver;
    position: relative;
    border-top: rem-calc(2) solid $s-white;
    display: flex;
    justify-content: center;

    @include breakpoint(large) {
      width: 60%;
      border-top: 0;
      border-left: rem-calc(2) solid $s-white;
    }
  }

  &__tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: rem-calc(24);
    display: flex;
    z-index: 9;

    &--shadow {

      #{$c-contact-card}__button {
        box-shadow: rem-calc(0 1 5 1) rgba(0,0,0,0.4);
      }
    }
  }

  &__button {
    background: #D8E9F1;
    color: $s-blue;
    padding: rem-calc(0 15);
    border-radius: rem-calc(30);
    height: rem-calc(40);
    font-family: $s-font-family-alt;
    font-size: rem-calc(15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: rem-calc(0 5);
    min-width: rem-calc(124);
    cursor: pointer;
    line-height: 0;
  }

  &__tab {

    &--current {

      #{$c-contact-card}__button {
        background: $s-blue;
        color: $s-white;

        &:hover {
          cursor: text;
        }
      }
    }

    &:first-child {

      #{$c-contact-card}__button {

        span {

          &::before {
            content: "\f279";
            font-family: $s-font-icon;
            font-weight: $s-font-regular;
            margin-right: rem-calc(4);
          }
        }
      }
    }

    &:last-child {

      #{$c-contact-card}__button {

        span {

          &::before {
            content: "\f017";
            font-family: $s-font-icon;
            font-weight: $s-font-regular;
            margin-right: rem-calc(2);
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    z-index: -9;
    opacity: 0;

    #{$c-contact-card}__right--tabs & {
      position: absolute;
    }

    &--times {
      padding: rem-calc(20 15);

      @include breakpoint(medium) {
        padding: rem-calc(20 30);
      }

      @include breakpoint(large) {
        padding: rem-calc(30 50);
      }

      @include breakpoint(xlarge) {
        padding: rem-calc(30 70);
      }

      #{$c-contact-card}__right--tabs & {
        padding-bottom: rem-calc(64);
      }
    }

    &--current {
      z-index: 1;
      opacity: 1;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: rem-calc(0 0 20 0);
  }

  &__item {
    border-bottom: rem-calc(2) solid $s-gray-light;
    font-size: rem-calc(12);
    display: flex;
    padding: rem-calc(10 0);

    &:last-child {
      border: 0;
    }
  }

  &__weekday {
    font-family: $s-font-family-alt;
    font-size: rem-calc(15);
    width: 33.33333333%;
  }

  &__duration {
    width: 33.33333333%;
  }

  &__time {
    font-family: $s-font-family-alt;
    font-size: rem-calc(15);

    &:last-child {

      &::before {
        content: "\00a0\—\00a0";
      }
    }
  }

  &__map {
    height: 100%;
    min-height: rem-calc(300);
    min-width: rem-calc(1);
    width: 100%;

    @include breakpoint(large) {
      height: 100%;
    }

    * {
      padding: 0 !important;
    }

    #{$iframe} {
      position: static;
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  &__text {
    font-size: rem-calc(15);
    font-weight: $s-font-regular;
    padding: rem-calc(15 5 3 25) !important;
  }
  /* Different layout in Editor */
  #{$c-editor} & {

    @include breakpoint(large) {
      margin: rem-calc(30 0);
    }

    &__inner {

      @include breakpoint(large) {
        flex-direction: column;
        padding: 0;
      }
    }

    &__right {
      @include breakpoint(large) {
        width: 100%;
      }
    }

    &__heading {
      &.active {
        &::before {
          @include breakpoint(large) {
            left: rem-calc(50) !important;
          }
        }
      }
    }
  }

  #{$c-editor}__link {
    background: $s-white;

    #{$using-keyboard} & {

      &:focus {
        background: $s-white;
      }
    }
  }
  /* Different layout in Block - Half Width */
  #{$c-block-grid-half} & {

    &__inner {
      flex-direction: column;
    }

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
      border-left: 0;
      border-top: rem-calc(2) solid $s-white;
    }

    &__content {

      &--times {

        @include breakpoint(large) {
          padding: rem-calc(20 50);
        }
      }
    }
  }
}
