.c-control-plan-review {
    &__block {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: rem-calc(20);
        background: $s-gray;
        @include breakpoint(medium) {
            padding: rem-calc(40 50);
        }
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }
    &__content {
        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include print {
            display: none;
        }
    }
    &__edit-button {
        color: $s-blue;
        cursor: pointer;
        white-space: nowrap;
        padding: rem-calc(10 0);
        i {
            margin-left: rem-calc(10);
        }
    }
    &__edit-button-text {
        padding-bottom: rem-calc(1);
        border-bottom: rem-calc(2) solid $s-blue;
    }
    &__divider {
        display: block;
        margin-bottom: rem-calc(30);
    }
}