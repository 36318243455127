.c-control-plan-item {
    .c-control-plan__review & {
        margin-bottom: rem-calc(30);
    }
    &__heading {
        font-size: rem-calc(20);
        border-bottom: rem-calc(2) solid $s-gray-light;
        margin-top: rem-calc(20);
        &:first-of-type {
            margin-top: 0;
        }
        @include print {
            margin-top: rem-calc(20) !important;
            margin-bottom: rem-calc(10) !important;
        }
    }
    &__table {
        width: 100%;
        @include print {
            font-size: rem-calc(13);
        }
    }
    &__row {
        @include xy-grid();
        @include xy-gutters($negative: true);
        margin: rem-calc(5 0);
        align-items: flex-start;
        border-radius: rem-calc(2);
        &:hover {
            .c-control-plan__review & {
                background: $s-gray-light;
            }
        }
    }
    &__label {
        width: 50%;
        font-weight: bold;
        padding-right: rem-calc(10);
        @include breakpoint(medium) {
            @include xy-cell(6);
            padding-right: 0;
        }
        @include print {
            width: auto !important;
            margin-bottom: rem-calc(5) !important;
            margin-right: rem-calc(5) !important;
            &:after {
                content: ":";
            }
        }
    }
    &__value {
        width: 50%;
        padding-left: rem-calc(15);
        word-break: break-word;
        line-height: normal;
        @include breakpoint(medium) {
            @include xy-cell(6);
            padding-left: 0;
        }
        &--error {
            color: $s-orange;
            @include print {
                text-indent: rem-calc(-99999);
                border-bottom: 1px dotted $s-gray-light;
                flex-grow: 1;
                overflow: hidden;
                margin: rem-calc(0 15 0 0) !important;
            }
        }
        &--full-width {
            width: 100%;
            @include breakpoint(medium) {
                @include xy-cell(12);
            }
        }
        @include print {
            flex-grow: 1;
        }
    }
    &__buttons {
        @include xy-grid();
        @include xy-gutters($negative: true);
        margin-bottom: rem-calc(30);
    }
    &__button {
        @extend .c-button;
        min-width: 0;
        width: 100%;
        i {
            margin-left: rem-calc(10);
        }
    }
    &__button-wrapper {
        @include xy-cell(6);
    }
}