/* Different layout on Landing Page, Block - Half width */

.c-news {
    $c-block-grid: '.c-block-grid';
    $c-block-grid-half: '.c-block-grid--half';
    $c-block-grid-third: '.c-block-grid--third';
    $c-block-grid-two-thirds: '.c-block-grid--two-thirds';
    $using-keyboard: '.using-keyboard';
    $p-landing: '.p-landing__inner .p-landing__block-area';
    height: 100%;

    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        padding-bottom: rem-calc(60);
    }
    
    &__outer {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
        height: 100%;
    }

    &__inner {
        background: $s-gray;
        @include xy-cell(12, $gutters:0);
        padding: rem-calc(20 15 30 15);

        @include breakpoint(medium) {
            padding: rem-calc(15 30 30 30);
            @include xy-cell(12);
        }

        @include breakpoint(large) {
            padding: rem-calc(30 50 40 50);
            display: flex;
            flex-direction: column;
            @include xy-cell(8);
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: rem-calc(0 0 20 0);
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;
        margin-bottom: rem-calc(20);

        &:last-child {
            border: 0;
            margin-bottom: 0;
        }
    }

    &__time {
        @include tag-date();
    }

    &__category {
        @include tag-category();
        display: inline-flex;

        &:first-of-type {

            &::before {
                content: "\00a0|\00a0\00a0";
                color: $s-gray-dark;
            }
        }

        &:last-of-type {

            &::after {
                content: "";
            }
        }

        &::after {
            content: ",";
        }
    }

    &__heading {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(30);
        width: 100%;
    }

    &__subheading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(5);
        max-width: rem-calc(610);
    }

    &__button {
        @extend .c-button;
        @extend .c-button--right;
        align-self: center;
        max-width: 100%;
    }

    &__preamble {
        margin-bottom: rem-calc(15);
        max-width: rem-calc(610);
    }

    &__info {
        margin-bottom: rem-calc(5);
    }

    &__link {
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        span {
            display: inline;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    /* Different layout on Landing Page */
    #{$p-landing} & {

        @include breakpoint(small down) {
            padding-bottom: rem-calc(25);
        }

        @include breakpoint(large) {
            padding-bottom: rem-calc(50);
        }

        &__heading {
            margin-bottom: rem-calc(15);
        }

        &__item {
            background: $s-gray;
            border: 0;
            border-radius: rem-calc(3);
            padding: rem-calc(15 15 5 15);
            
            @include breakpoint(medium) {
                padding: rem-calc(20 30 10 30);
            }

            @include breakpoint(large) {
                padding: rem-calc(20 0 10 0);
                display: flex;
            }
        }

        &__category {
            
            @include breakpoint(large) {
                display: inline-block;
                margin: rem-calc(6 0 4 0);
                
                &::before {
                    content: none;
                }
            }
        }

        &__button {
            margin: 0 auto;
            
            @include breakpoint(medium) {
                max-width: rem-calc(300);
            }

            @include breakpoint(large) {
                @include xy-cell(4);
                @include xy-cell-offset($n:4,$gutters:0);
            }
        }

        &__time {
            flex-grow: 1;
            
            @include breakpoint(large) {
                display: block;
                margin: rem-calc(5 0 3 0);
            }
        }

        &__outer {
            display: block;
        }

        &__inner {
            padding: 0;
            background: none;
            display: block;
            @include xy-cell(12);

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__info {
            
            @include breakpoint(large) {
                @include xy-cell(4,$gutters:0);
                padding-right: rem-calc(30);
                padding-left: rem-calc(40);
            }
        }

        &__content {
            
            @include breakpoint(large) {
                @include xy-cell(8,$gutters:0);
                padding-right: rem-calc(40);
            }
        }

        &__list {

            @include breakpoint(large) {
                margin: rem-calc(0 0 40 0);
            }
        }
    }

    /* Different layout on Landing Page + Block - Half Width */
    #{$p-landing} #{$c-block-grid-half} & {

        &__item {
            
            @include breakpoint(large) {
                flex-direction: column;
                padding: rem-calc(20 30 10 30);
            }
        }

        &__info {

            @include breakpoint(large) {
                width: 100%;
                padding: 0;
            }
        }

        &__content {

            @include breakpoint(large) {
                width: 100%;
            }
        }

        &__time {

            @include breakpoint(large) {
                display: inline-flex;
            }
        }

        &__category {

            @include breakpoint(large) {
                display: inline-flex;
                margin: 0;

                &:first-of-type {

                    &::before {
                        content: "\00a0|\00a0\00a0";
                        color: $s-gray-dark;
                    }
                }
            }
        }

        &__button {

            @include breakpoint(large) {
                max-width: none;
                @include xy-cell(12, $gutters:0);
            }
        }

        &__list {

            @include breakpoint(large) {
                margin: rem-calc(0 0 30 0);
            }
        }
    }

    /* Different layout in Block - Half Width */
    #{$c-block-grid-half} &, #{$c-block-grid-third} &, #{$c-block-grid-two-thirds} & {

        &__inner {

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__button {
            max-width: 100%;
            width: 100%;
        }
    }

    &__image{
        margin-bottom: rem-calc(5);
        @include breakpoint(medium){
            margin-bottom: rem-calc(10);
        }
    }
}