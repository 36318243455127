.p-events-list {
  @include gradient-bg();

  @include breakpoint(large) {
    padding-top: rem-calc(60);
  }

  &__outer {
    @extend .c-page-grid;
    max-width: rem-calc(844);
  }

  &__inner {
    @extend .c-page-grid__outer;
  }

  &__content {
    @include xy-cell(12);

    @include breakpoint(medium) {
      @include xy-cell(12);
    }

    @include breakpoint(large) {
      @include xy-cell(12);
    }
  }
}
