.c-page-grid {
  @include xy-grid-container;

  &__outer {
    @include xy-grid();
    @include xy-gutters($negative: true);

    @include breakpoint(medium only) {
      @include flex-align(center);
    }
  }

  &--center {

    &__outer {
      @include xy-grid();
      @include xy-gutters($negative: true);
      @include flex-align(center);
    }

    &__inner {
      @include xy-cell(12);

      @include breakpoint(large) {
        @include xy-cell(9);
      }
    }

    &__article {
      @include xy-grid();
      @include xy-gutters($negative: true);
      @include flex-align(center);
    }

    &__content {
      @include xy-cell(12);

      @include breakpoint(medium) {
        @include xy-cell(10);
      }

      @include breakpoint(large) {
        @include xy-cell(8);
      }
    }
  }

  &--two-cols {

    &__side {
      @include xy-cell(12);

      @include breakpoint(large) {
        @include xy-cell(3);
      }
    }

    &__main {
      @include xy-cell(12);

      @include breakpoint(large) {
        @include xy-cell(9);
      }
    }

    &__article {
      @include xy-grid();
      @include xy-gutters($negative: true);
      @include flex-align(center);
    }

    &__content {
      @include xy-cell(12);

      @include breakpoint(medium) {
        @include xy-cell(10);
      }

      @include breakpoint(large) {
        @include xy-cell(8);
      }
    }
  }
}
