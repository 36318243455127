.c-translate {
    $c-translate: '.c-translate';
    $c-nav-support: '.c-nav-support';
    $using-keyboard: '.using-keyboard';
    height: rem-calc(30);
    margin-bottom: rem-calc(15);

    @include breakpoint(1200) {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: rem-calc(15);
        margin: 0;
    }

    &__button {
        position: relative;
        padding-left: rem-calc(36);

        &:hover, &:focus {
            cursor: pointer;

            #{$c-translate}__title {
                @include link-underline($s-black);
            }
        }

        &:focus {
            outline: 0;
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                #{$c-translate}__icon {
                    background: $s-yellow;
                    
                    &::before {
                        color: $s-black;
                    }
                }
            }
        }
    }

    &__icon {
        background: $s-teal-light;
        height: rem-calc(30);
        width: rem-calc(30);
        border-radius: rem-calc(30);
        position: absolute;
        top: rem-calc(-7);
        left: 0;
        font-style: normal;

        &::before {
            content: "\f7a2";
            font-family: $s-font-icon;
            font-weight: 600;
            font-size: rem-calc(16);
            color: $s-blue;
            position: relative;
            top: rem-calc(7);
            left: 0;
        }
    }

    &__title {
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;
        font-size: rem-calc(15);
        position: relative;
    }

    #{$c-nav-support}__expand {
        width: rem-calc(240);
        border-radius: rem-calc(2);
        top: rem-calc(42);

        &::before {
            content: "";
            border-bottom: rem-calc(9) solid darken($s-gray, 2%);
            border-left: rem-calc(9) solid transparent;
            border-right: rem-calc(9) solid transparent;
            border-top: rem-calc(9) solid transparent;
            display: block;
            margin-left: rem-calc(-9);
            margin-top: rem-calc(-18);
            position: absolute;
            top: 0;
            right: rem-calc(30);
            height: 0;
            width: 0;
        }

        @include breakpoint(large) {
            top: rem-calc(40);
        }
    }
}