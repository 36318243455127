.c-warning-message {
    $big: '.c-warning-message--big';

    background: $s-red-light;
    padding: rem-calc(30 0 20 0);

    @include breakpoint(medium) {
        padding: rem-calc(50 0 30 0);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(2);
    }

    &__outer {
        @include xy-grid-container;
    }

    &__inner {
        @include xy-grid();
        @include xy-gutters($negative: true);

        @include breakpoint(medium) {
            @include flex-align(center);
        }
    }

    &__top {
        @include xy-cell(12);
        display: flex;
        align-items: start;
        position: relative;

        @include breakpoint(medium) {
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            @include xy-cell(12);
        }

        &::before {
            content: "\f0a1";
            font-family: $s-font-icon;
            font-weight: $s-font-semibold;
            font-size: rem-calc(18);
            background: $s-white;
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem-calc(40);
            width: rem-calc(40);
            border-radius: rem-calc(40);
            flex: 0 0 auto ;
            color: $s-red-dark;
            margin-right: rem-calc(20);
            position: absolute;
            left: 0;

            @include breakpoint(medium) {
                top: rem-calc(-6);
                height: rem-calc(50);
                width: rem-calc(50);
                border-radius: rem-calc(50);
                font-size: rem-calc(22);
                left: rem-calc(-60);
            }

            @include breakpoint(large) {
                height: rem-calc(60);
                width: rem-calc(60);
                border-radius: rem-calc(60);
                left: 0;
            }

            #{$big} & {
                display: none;

                @include breakpoint(large) {
                    display: flex;
                }
            }
        }
    }

    &__heading {
        font-size: rem-calc(30);
        line-height: rem-calc(45);
        color: $s-red-dark;
        padding-left: rem-calc(50);

        @include breakpoint(medium) {
            padding-left: 0;
            margin-bottom: rem-calc(10);
        }

        @include breakpoint(large) {
            padding-left: rem-calc(80);
        }

        #{$big} & {
            font-size: rem-calc(38);
            line-height: rem-calc(42);
            margin-bottom: rem-calc(15);
            padding-left: 0;
            max-width: rem-calc(1000);

            @include breakpoint(large) {
                font-size: rem-calc(50);
                line-height: rem-calc(55);
                padding-left: rem-calc(90);
            }
        }
    }

    &__info {
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            @include xy-cell(12);
            
            padding-left: rem-calc(80);
        }

        #{$big} & {

            @include breakpoint(large) {
                padding-left: rem-calc(90);
            }
            
            p {
                max-width: rem-calc(700);

                @include breakpoint(large) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(30);
                }
            }
        }
    }
}