/* Different layout on Landing Page */

.c-events-calendar {
    $p-landing: '.p-landing__inner .p-landing__block-area';
    $p-start: '.p-start__block-area';
    $using-keyboard: '.using-keyboard';
    $c-block-grid-half: '.c-block-grid--half';
    
    &__outer {
        @extend .c-block-grid--full-bleed;
        margin-bottom: rem-calc(15);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(30);
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(60);
        }
    }

    &__inner {
        @extend .c-block-grid--full-bleed__outer;
        border-top: rem-calc(2) solid $s-gray-light;
        border-bottom: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(15 0 30 0);

        @include breakpoint(large) {
            padding: rem-calc(40 0 70 0);
        }

        #{$p-start} &{
            padding: 0;
            border: none;
        }
    }

    &__content {
        @extend .c-block-grid--full-bleed__inner;
    }
    
    &__list {
        list-style-type: none;
        margin-bottom: rem-calc(30);
        padding: 0;
        @include xy-grid();
        @include xy-gutters($negative: true);

        @include breakpoint(large) {
            margin-bottom: rem-calc(40);
        }
    }

    &__item {
        @include xy-cell(12);
        margin-bottom: rem-calc(30);

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint(medium) {
            @include xy-cell(4);
            margin-bottom: 0;
        }
    }

    &__wrapper {
        @include xy-grid();
        @include xy-gutters($negative: true);
    }

    &__link {
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        span {
            display: inline;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__info {
        @include xy-cell(12);
    }

    &__image {
        @include xy-cell(12);
        margin-bottom: rem-calc(10);

        @include breakpoint(large) {
            margin-bottom: rem-calc(10);
        }
    }

    &__date {
        font-family: $s-font-family-alt;
        color: $s-gray-dark;
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        display: inline-flex;
        
        &:first-of-type {

            &::before {
                content: "\f017";
                font-family: $s-font-icon;
                margin-right: rem-calc(3);
            }
        }

        &:nth-child(2) {

            &::before {
                content: "—";
                font-family: $s-font-icon;
                margin-right: rem-calc(3);
                color: $s-black;
                position: relative;
                top: rem-calc(-1);
            }
        }
    }

    &__category {
        @include tag-category();

        &::before {
            content: "\00a0\|\00a0";
        }
    }

    &__preamble {
        display: block;

        a {
            border-bottom: rem-calc(2) solid $s-blue;
        }
    }

    &__heading {
        margin: rem-calc(5 0 15 0);
        font-size: rem-calc(24);

        @include breakpoint(large) {
            margin: rem-calc(5 0 25 0);
            font-size: rem-calc(30);
        }
    }

    &__subheading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-top: rem-calc(3);
        margin-bottom: rem-calc(5);
        word-break: break-word;
    }

    &__footer {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
    }

    &__button {
        @extend .c-button;
        @extend .c-button--right;
        min-width: auto;
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(4);
        }
    }

    /* Different layout on Landing Page */
    #{$p-landing} &, #{$c-block-grid-half} & {

        &__outer {
            margin: rem-calc(0 0 15 0);
            padding: 0;

            @include breakpoint(medium) {
                margin: rem-calc(0 0 30 0);
            }

            @include breakpoint(large) {
                margin: rem-calc(0 0 60 0);
            }
        }

        &__inner {
            margin: 0;
            border: 0;
            padding: 0;
        }

        &__content {
            margin: 0;
            width: 100%;
        }

        &__list {
            @include xy-grid();
            @include xy-gutters($negative: true);

            @include breakpoint(large) {
                margin-bottom: rem-calc(20);
            }
        }

        &__item {
            margin-bottom: rem-calc(20);

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        &__wrapper {

            @include breakpoint(large) {
                @include xy-cell(12,$gutters:0);
            }
        }

        &__image {
            @include xy-cell(12);

            @include breakpoint(large) {
                padding-right: rem-calc(30);
                @include xy-cell(4,$gutters:0);
            }
        }

        &__info {
            @include xy-cell(12);

            @include breakpoint(large) {
                @include xy-cell(8,$gutters:0);
            }
        }

        &__footer {

            @include breakpoint(large) {
                @include xy-cell-offset($n:4,$gutters:0);
                @include flex-align(left);
            }
        }

        &__button {
            width: 100%;
            @include xy-cell(12);
            margin: 0;

            @include breakpoint(medium) {
                max-width: rem-calc(300);
            }
        }
    }
}