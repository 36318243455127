.g-navigation {
    background: $s-brand;
    display: none;

    @include breakpoint(large) {
        display: block;
    }

    &__outer {
        @include xy-grid-container;
    }

    &__list {
        @include xy-grid();
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding: rem-calc(22 0);
    }

    &__item {
        flex-grow: 1;
    }

    &__link {
        color: $s-white;
        position: relative;
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;

        &:hover, &:focus {
            color: $s-white;
            @include link-underline($s-white);
        }

        &--active {
            @include link-underline($s-white);
        }
    }
}