.bulletinboard-view {
    border-top: rem-calc(2) solid $s-gray-light;
    .bulletinboard-content {
        font-family: $s-font-family;
        padding: 0;
        .bbl-filter-area {
            display: flex;
            flex-direction: column;
            padding: rem-calc(20 0 5 0);
            .bbl-col-6 {
                width: 100%;
            }
            .chosen-container-active {
                .chosen-choices {
                    border: rem-calc(2) solid $s-black !important;
                }
            }
            .chosen-choices {
                @extend .c-form__input;
                color: $s-black;
                font-size: rem-calc(16);
                input {
                    padding: rem-calc(8) !important;
                    &::placeholder {
                        color: $s-gray-dark;
                    }
                }
                .search-choice {
                    background-color: $s-gray-light;
                    background-image: none;
                    border-color: $s-gray-border;
                }
            }
            .chosen-drop {
                border-color: $s-gray-border;
            }
            .chosen-results {
                font-size: rem-calc(16);
                li {
                    &.highlighted {
                        background: $s-blue !important;
                    }
                }
            }
            .bbl-input-group {
                display: flex;
                position: relative;
                .bbl-group-addon {
                    background: transparent;
                    border: none;
                    position: absolute;
                    right: 0;
                    z-index: 9;
                    .bbl-search {
                        background-image: none;
                        &:before {
                            content: "\f002";
                            font-family: $s-font-icon;
                            font-weight: $s-font-semibold;
                            margin-right: rem-calc(3);
                            position: relative;
                            top: rem-calc(5);
                            right: rem-calc(15);
                        }
                    }
                }
                .bbl-form-control {
                    @extend .c-form__input;
                    color: $s-gray-dark;
                    font-size: rem-calc(16) !important;
                    padding: rem-calc(8) !important;
                    &::placeholder {
                        color: $s-gray-dark;
                    }
                    &:focus {
                        border: rem-calc(2) solid $s-black !important;
                    }
                }
            }
        }
        .bbl-list {
            list-style: none;
            margin: 0;
            padding: 0;
            @include xy-cell(12, $gutters: 0);
        }
        .bbl-hidden {
            display: flex !important;
            justify-content: center;
        }
        .bbl-pagination {
            li {
                display: inline-block !important;
                margin-right: rem-calc(5);
                &:not(.disabled) {
                    a {
                        &:hover {
                            background: $s-blue;
                            color: $s-white;
                            border-color: $s-blue;
                        }
                    }
                }
                &.active {
                    a {
                        background: $s-blue;
                        color: $s-white;
                        border-color: $s-blue;
                    }
                }
                a {
                    color: $s-blue;
                    border-color: $s-gray-light;
                    font-size: rem-calc(15);
                    height: rem-calc(40);
                    font-weight: $s-font-bold;
                    font-family: $s-font-family;
                    border-radius: rem-calc(2);
                    line-height: rem-calc(10);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: rem-calc(0 15);
                    transition: all 0.3s ease 0s;
                }
            }
        }
    }
}

.c-bulletin-item {
    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;
        margin-bottom: rem-calc(10);
        &:last-child {
            border: 0;
            margin-bottom: 0;
        }
    }
    &__time {
        @include tag-date();
    }
    &__category {
        @include tag-category();
        display: inline-flex;
        color: $s-gray-dark;
        &::before {
            content: "\00a0|\00a0\00a0";
            color: $s-gray-dark;
        }
        &:last-of-type {
            &::after {
                content: "";
            }
        }
        &::after {
            content: ",";
        }
    }
    &__heading {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(30);
        width: 100%;
    }
    &__subheading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(5);
        max-width: rem-calc(610);
    }
    &__button {
        @extend .c-button;
        @extend .c-button--right;
        align-self: center;
    }
    &__preamble {
        margin-bottom: rem-calc(15);
        max-width: rem-calc(610);
    }
    &__info {
        margin-bottom: rem-calc(5);
    }
    &__divider {
        margin: rem-calc(0 10);
    }
    &__heading {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(5);
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);
                font-weight: bold;
        position: relative;
        padding-left: rem-calc(20);
        margin-top: rem-calc(5);
    }
    &__link {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(5);
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);
        font-weight:bold;
        position: relative;
        padding-left: rem-calc(20);
        margin-top: rem-calc(5);
        padding-top:rem-calc(5);
        &:hover,
        &:focus {
            color: $s-black;
            span {
                @include link-underline($s-black);
            }
        }
        span {
            display: inline;
        }
        // &::after {
        //     @include download-link-item();
        //     margin-left: rem-calc(5);
        // }
        #{$using-keyboard} & {
            &:focus {
                background: none;
                span {
                    background: $s-yellow;
                }
            }
        }
    }
    &__icon {
        position: absolute;
        top: rem-calc(5);
        left: 0;
        color: $s-black;
        &.fa-file{
            margin-top:rem-calc(5);
        }
    }
    &__content {
        margin-bottom: rem-calc(10);
    }
}

.bbl-item-info-title {
    font-weight: bold;
    color: $s-black !important;
}
.c-bulletin-item__text {
    color: $s-black !important;
    * {
        font-size: rem-calc(12) !important;
    }
}

.c-bulletin-item__short-text > div {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px !important;
    * {
        margin-top: 0 !important;
    }
}
.bbl-item-content-title {
    margin-right: rem-calc(5);
    font-weight: bold;
    &:after {
        content: ":";
    }
}
.bbl-subitem-col {
    display: none;
}

.bulletinboard-content {
    border-top: 0;
}