.p-bulletin-board{
    &__container {
        @extend .c-page-grid--two-cols__article;
    }

    &__inner {
        @extend .c-page-grid--two-cols__content;
        position: relative;

    }
    &__article {
        @extend .c-page-grid--two-cols__main;
    }
}