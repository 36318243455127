.c-page-header {
    margin-bottom: rem-calc(20);

    &__date {
        @include tag-date();
    }

    &__category {
        @include tag-category();
        display: inline-block;

        &::before {
            content: "\00a0|\00a0";
            color: $s-gray-dark;
        }
    }
}