.c-instructions {
    $c-instructions: '.c-instructions';
    $c-editor-files: '.c-editor-files';
    $c-block-grid: '.c-block-grid';
    $c-editor: '.c-editor';
    $using-keyboard: '.using-keyboard';
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }

    &__outer {
        background: $s-teal-light;
        @include xy-grid();
        @include flex-align(center);
        padding: rem-calc(20 0 30 0);
        margin: rem-calc(0 -15);

        @include breakpoint(medium) {
            padding: rem-calc(20 0 10 0);
            margin: 0;
        }

        @include breakpoint(large) {
            padding: rem-calc(40 0 20 0);
        }
    }

    &__inner {
        @include xy-cell(12);
        position: relative;

        @include breakpoint(medium) {
            @include xy-cell(10, $gutters:0);
        }

        @include breakpoint(large) {
            @include xy-cell(8, $gutters:0);
            display: flex;
        }
    }

    &__intro {

        @include breakpoint(medium) {
            padding-right: rem-calc(180);
        }
    }

    &__editor {
        max-width: rem-calc(610);
        margin-bottom: rem-calc(20);

        @include breakpoint(large) {
            margin-bottom: rem-calc(40);
        }

        > ul {
            @include editor-ul-list();
        }

        > ol {
            @include editor-ol-list();
        }

        #{$c-editor}__link {
            background: $s-white;

            #{$using-keyboard} & {

                &:focus {
                    background: $s-white;
                }
            }
        }
    }

    &__heading {
        font-size: rem-calc(28);
        margin-bottom: rem-calc(15);
    }

    &__subheading {
        font-size: rem-calc(24);
        line-height: rem-calc(36);
    }

    &__preamble {
        max-width: rem-calc(610);
    }

    &__list {
        list-style-type: none;
        margin: rem-calc(0 -15);
        padding: 0;
        @include breakpoint(medium) {
            margin: 0;
        }
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;

        &:last-child {
            border: 0;
        }

        &--hidden {
            visibility:hidden;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
            margin: 0;
            opacity: 0;
            height: 0;
        }
    
        &--visible {
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        &--expanded {
            border: 0;
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        position: relative;
        padding-left: rem-calc(30);
        display: inline-block;

        &:hover, &:focus {

            span {
                color: $s-black;
                @include link-underline($s-black);
            }
        }

        &::before {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
            width: rem-calc(12);
            height: rem-calc(11);
            transition: opacity .3s ease 0s;
            top: rem-calc(7);
            left: rem-calc(5);
            position: absolute;
        }

        &[href^="https://"]:not([href*="www.sollentuna.se"]),
        &[href^="http://"]:not([href*="www.sollentuna.se"]) {
            
            &::before {
                content: "\f35d\00a0\00a0";
                background: none;
                font-family: $s-font-icon;
                font-weight: $s-font-bold;
                font-size: rem-calc(13);
                top: rem-calc(3);
                position: absolute;
                color: $s-blue;
            }

            #{$using-keyboard} & {

                &:focus {

                    &::before {
                        color: $s-black;
                    }
                }
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }

        #{$c-instructions}__item--instructions & {

            &::before {
                content: "+";
                background: $s-teal-light;
                height: rem-calc(24);
                width: rem-calc(24);
                border-radius: rem-calc(24);
                color: $s-blue;
                font-size: rem-calc(20);
                font-family: $s-font-family-alt;
                top: rem-calc(1);
                left: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            #{$using-keyboard} & {

                &:focus {

                    &::before {
                        background: $s-black;
                        color: $s-yellow;
                    }
                }
            }
        }

        #{$c-instructions}__item--expanded & {

            &::before {
                content: "−";
                background: $s-blue;
                color: $s-white;
            }
        }
    }

    &__content {
        @include xy-cell(8, $gutters:0);
    }

    &__read-more {
        @extend .c-button;
        @extend .c-button--alt;
        background: $s-teal;
        margin-bottom: rem-calc(30);
        display: inline-flex;
        width: auto;

        @include breakpoint(medium) {
            margin-bottom: rem-calc(40);
        }

        @include breakpoint(large) {
            min-width: auto;
            margin-top: rem-calc(-10);
        }
        
        span {
            font-weight: $s-font-bold;
            top: rem-calc(1);
        }

        &[href^="http://"]:not([href*="www.sollentuna.se"]),
        &[href^="https://"]:not([href*="www.sollentuna.se"]) {
            padding: rem-calc(14 50 14 35);

            span {

                &::after {
                    @include external-link-single(0);
                    transition: all .3s ease 0s;
                }
            }

            &:hover {

                span {

                    &::after {
                        color: $s-white;
                    }
                }
            }

            #{$using-keyboard} & {

                &:focus {

                    span {

                        &::after {
                            background: none;
                            color: $s-black;
                        }
                    }
                }
            }
        }
    }

    &__back-to-top {
        margin-right: rem-calc(10);
        font-size: rem-calc(15);
        line-height: rem-calc(18);
        position: relative;
        display: flex;
        align-items: center;
        
        @include breakpoint(medium) {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: rem-calc(-12);
        }

        span {
            @include link-underline($s-blue);
            font-weight: $s-font-bold;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-up-blue.svg) no-repeat;
            display: inline-block;
            width: rem-calc(16);
            height: rem-calc(21);
            margin-left: rem-calc(7);
            background-position-y: rem-calc(2);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                &::after {
                    background: url(/static/assets/images/icon-arrow-up-black.svg) no-repeat;
                    background-position-y: rem-calc(2);
                }

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__wrapper {
        @include xy-grid();
        @include flex-align(center);
        background: lighten($s-gray, 2%);
        padding: rem-calc(20 0 20 0);        

        @include breakpoint(large) {
            padding: rem-calc(50 0 60 0);
        }
    }

    &__content {
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(10, $gutters:0);
        }
    
        @include breakpoint(large) {
            @include xy-cell(8, $gutters:0);
        }
    }

    &__title {
        @include xy-grid();
        @include flex-align(center);
        padding: rem-calc(18 0);

        #{$c-instructions}__content {
            position: relative;
        }
    }

    &__extend {
        display: flex;
        justify-content: center;
        margin-top: rem-calc(5);

        @include breakpoint(medium) {
            margin-top: rem-calc(30);
        }
    }

    &__area {
        display: none;
    }

    &__button {

        &--expanded {
            @extend .c-button--minus;
        }
    }

    &__close {
        position: relative;
        display: block;
        padding: rem-calc(4 0 4 30);

        &:hover, &:focus {
            cursor: pointer;
            outline: none;

            span {
                @include link-underline($s-black);
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;

                    &::before {
                        background: $s-black;
                        color: $s-yellow;
                    }
                }
            }
        }

        span {
            font-weight: $s-font-bold;

            &::before {
                content: "−";
                background: $s-blue;
                height: rem-calc(24);
                width: rem-calc(24);
                border-radius: rem-calc(24);
                color: $s-white;
                font-size: rem-calc(20);
                font-family: $s-font-family-alt;
                top: rem-calc(1);
                left: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    #{$c-block-grid} {
        margin: 0;
        width: 100%;
    }

    #{$c-editor-files} {
        margin: rem-calc(0 0 40 0);

        &__list {
            max-width: rem-calc(610);
            background: $s-white;
            border: 0;
        }
    }
}
