.g-tagnavigation {
    // Only display for mobile
    @include breakpoint(medium) {
        display: none;
    }

    margin-bottom: rem-calc(20);

    &__title {
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-weight: $s-font-semibold;
    }

    &__heading {
        font-size: rem-calc(18);
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;
        display: block;
        margin-bottom: rem-calc(8);
    }

    &__list {
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin: rem-calc(0 8 8 0);
        padding: rem-calc(8 16);

        background: $s-teal-light;
        border-radius: 500px;
        font-family: $s-font-family-alt;
        font-size: rem-calc(12);
        font-weight: $s-font-bold;
    }

    &__link {
    }

}
