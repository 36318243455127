.c-media {
  $c-media: '.c-media';
  $c-editor: '.c-editor';
  $c-block-grid-half: '.c-block-grid--half';
  $p-standard: '.p-standard';
  $p-building-permit: '.p-building-permit';
  max-width: 100%;
  margin-bottom: rem-calc(15);
  @include xy-grid();
  @include xy-gutters($negative: true);
  @include flex-align(center);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(30);
  @include flex-align(left);
  }

  &--on-page {
    margin-bottom: rem-calc(25);

    @include breakpoint(large) {
      margin-bottom: rem-calc(30);
    }

    #{$c-media}__outer {
      @include xy-cell(12);

      @include breakpoint(large) {
        @include xy-cell(12);
      }
    }

    #{$c-media}__image {
      margin: rem-calc(0 -15);

      @include breakpoint(large) {
        margin: 0;
      }
    }

    #{$c-media}__figcaption {
      margin-top: rem-calc(10);
    }

    #{$c-media}__text {
      @include xy-cell(12);

      @include breakpoint(medium) {
        @include xy-cell(10);
      }

      @include breakpoint(large) {
        @include xy-cell(8);
      }
    }

    &#{$c-media}--embed {
      width: 100%;

      #{$c-media}__outer {

        @include breakpoint(medium) {
          @include xy-cell(10);
        }

        @include breakpoint(large) {
          @include xy-cell(8);
        }
      }
    }
  }

  #{$c-editor} & {

    &__outer {
      @include xy-cell(12);
    }

    &__image {

      img {
        margin-top: 0;
      }
    }

    &__figcaption {
      margin-top: rem-calc(-15);
    }
  }

  &__video {
    @include responsive-embed(16 by 9);
    margin-bottom: 0;
  }

  &__figcaption {
    margin-top: rem-calc(10);
    font-size: rem-calc(14);
    text-align: left;
    @include xy-grid();
    @include xy-gutters($negative: true);
    @include flex-align(center);

    p {
      margin: 0;
    }
  }

  &__text {
    @include xy-cell(12);
  }

  &__outer {
    @include xy-cell(12);

    @include breakpoint(large) {
      @include xy-cell(8);
    }
  }
  /* Different layout in Block - Half Width */
  #{$c-block-grid-half} & {

    #{$c-media}__outer {

      @include breakpoint(large) {
        @include xy-cell(12);
      }
    }
  }
  /* Different layout in Standard Page - Editor */
  #{$p-standard} &, #{$p-building-permit} & {

    &--on-page {
      margin-top: rem-calc(-30);

      @include breakpoint(large) {
        margin-top: 0;
      }

      &#{$c-media}--embed {
        margin-top: rem-calc(-15);

        @include breakpoint(large) {
          margin-top: 0;
        }
      }
    }
  }
}

.p-standard__container--no-h2 {
  .c-media {
    @include flex-align(center);
  }
}
