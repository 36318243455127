.c-events {
    $c-events: '.c-events';
    $using-keyboard: '.using-keyboard';
    @include xy-grid();
    @include xy-gutters($negative: true);
    @include flex-align(center);

    &__outer {
        @include xy-cell(12);
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &--date {

            #{$c-events}__panel {
                padding: rem-calc(0 15);
                width: 100%;
                display: flex;
                background: $s-blue;
                color: $s-white;
                border-top-left-radius: rem-calc(4);
                border-top-right-radius: rem-calc(4);
                height: rem-calc(50);
                align-items: center;
                font-weight: $s-font-bold;

                @include breakpoint(large) {
                    padding: rem-calc(0 50);
                }

                &::before {
                    content: "\f073";
                    font-family: $s-font-icon;
                    margin-right: rem-calc(10);
                    font-weight: $s-font-regular;
                }
            }
        }

        &--events {
            background: $s-white;
            border-bottom-left-radius: rem-calc(4);
            border-bottom-right-radius: rem-calc(4);
            padding-top: rem-calc(15);

            #{$c-events}__item {
            }
        }
    }

    &__inner {
        @include xy-grid();
        @include xy-gutters($negative: true);
        margin-bottom: rem-calc(8);
    }

    &__section {
        @include xy-cell(12,$gutters:0);

        padding: rem-calc(16);
        @include breakpoint(medium) {
            @include xy-cell(12);
            padding: rem-calc(32);
        }


        &--panel {
            background: $s-teal-light;
            border-top-left-radius: rem-calc(4);
            border-top-right-radius: rem-calc(4);
            padding: 0;
            height: rem-calc(60);
            margin-bottom: rem-calc(2);

            #{$c-events}__wrapper {
                height: 100%;
                align-items: center;
            }

            #{$c-events}__content {
                display: inherit;
            }

            &:hover {
                cursor: pointer;

                #{$c-events}__button time {
                    text-decoration: underline;
                    text-underline-offset: rem-calc(4);
                    text-decoration-thickness: rem-calc(1.5);
                }
            }

            #{$using-keyboard} & {

                &:focus {
                    background: $s-teal-light;

                    #{$c-events}__button {

                        time {
                            color: $s-black;
                            background: $s-yellow;
                            @include link-underline($s-black);
                        }

                        &::before {
                            color: $s-yellow;
                            background: $s-black;
                        }
                    }
                }
            }
        }

        &--all {
            background: $s-gray-silver;
            margin-bottom: rem-calc(2);
        }
    }

    &__group {
        display: none;
        @include xy-cell(12,$gutters:0);
    }

    &__wrapper {
        @include xy-grid();
        @include xy-gutters($negative: true);
    }

    &__content {
        @include xy-cell(12);
        width: 100%;
        height: 100%;


        &--all {

            #{$c-events}__list--events {
              padding: rem-calc(32);
                
                #{$c-events}__item {
                    border-bottom: rem-calc(2) solid $s-gray-light;

                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
       
    }
    &__subheading {
        font-size: rem-calc(24);
        line-height: rem-calc(30);
    }

    &__link {
        color: $s-black;
        padding-right: rem-calc(14);
        display: inline-block;

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
                padding-bottom: 0;
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
            width: rem-calc(14);
            height: rem-calc(11);
            position: relative;
            margin-right: rem-calc(-14);
            display: inline-block;
        }
    }

    &__button {
        font-size: rem-calc(16);
        font-family: $s-font-family;
        position: relative;
        padding: rem-calc(10 24);
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-radius: rem-calc(4);

        span {
            position: relative;
            display: inline;
        }

        &::before {
            content: ""; /* This is necessary for the pseudo-element to be generated */
            background: url(/static/assets/images/plus-slim.svg) no-repeat;
            background-size: contain;
            height: 1.25rem;
            width: 1.125rem;
            display: inline-block;
            top: 50%;
            right: 0;

            @include breakpoint(medium) {
                left: rem-calc(20)
            }
        }

        &--expanded {
            background-color: $s-blue;
            &::before {
                content: "";
                background: url('/static/assets/images/minus-white.svg') no-repeat;
                transform: unset;
                height: rem-calc(3);
            }
            time{
                color: white
            }
        }
    }

    &__info {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        @include breakpoint(medium) {
            width: 50%;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        margin-bottom: rem-calc(12);
        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    &__time {
        @include tag-date();
        flex: 0 0 auto;
        color: unset;
        font-family: $s-font-family;
        &::after {
            content: "\00a0|\00a0";
        }
    }

    &__location {
        @include tag($s-black);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__tag {
        background: $s-teal;
        border-radius: rem-calc(2);
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        font-family: $s-font-family-alt;
        text-transform: uppercase;
        height: rem-calc(26);
        padding: rem-calc(0 10);
        flex: 0 0 auto;
        margin: rem-calc(20 0 0 0);
        width: rem-calc(200);
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(medium) {
            margin-top: 0;
            margin: rem-calc(0 0 0 30);
            width: auto;
            position: relative;
            top: rem-calc(-5);
        }
        i{
          margin-right: rem-calc(4);
        }
    }

    &__label {
        margin-bottom: rem-calc(30);
        font-size: rem-calc(26);
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: unset;
    }
    &__panel{
        font-family: $s-font-family;
        font-weight: 400!important;
    }
    &__item{
        margin-bottom: rem-calc(20);
        &:last-child{
            margin-bottom: unset;
        }
    }
}
