.c-breadcrumbs {
    @extend .c-page-grid;
    padding: rem-calc(20 0 15 0);
    overflow: auto;
    scroll-snap-align: none;
    direction: rtl;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }

    &__list {
        min-width: max-content;
        margin: 0;
        padding-bottom: rem-calc(2);
    }

    &__item {
        float: left;
        list-style: none;
        font-size: rem-calc(14);
        position: relative;
        padding-right: rem-calc(16);

        &:not(:last-child) {

            &::after {
                content: "/";
                position: absolute;
                top: 0;
                right: 0;
                margin-right: rem-calc(6);
            }
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        border-bottom: 2px solid $s-black;

        @include breakpoint(large) {
            border-bottom: unset;
        }

        &:hover, &:focus {
            color: $s-black;
            @include link-underline($s-black);
        }

        span {
            position: relative;
        }
    }
}