.c-dynamic-form {
    &__label {
        font-weight: bold;
        font-size: rem-calc(16);
        line-height: rem-calc(19);
        display: inline-block;
        margin-right: 0 !important;
    }
    &__textinput {
        border: rem-calc(2) solid $s-gray-medium;
        margin-top: rem-calc(5);
    }
    &__textarea {
        @extend .c-dynamic-form__textinput;
        min-height: rem-calc(120);
    }
    &__group {
        position: relative;
    }
    &__submit {
        @extend .c-button;
        margin: 0 auto;
    }
    &__button {
        @extend .c-button;
        &--add{
            margin: 0 auto;
            i{
                margin-left: rem-calc(10);
            }
        }
    }
    &__file-wrapper {
        position: relative;
    }
    &__file-input {
        width: rem-calc(0.1);
        height: rem-calc(0.1);
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    &__file-label {
        @extend .c-button;
        display: inline-flex;
        margin: rem-calc(5 0 20);
        min-width: 0;
        padding: rem-calc(0 30);
    }
    &__message{
        margin:rem-calc(20) auto 0;
        text-align: center;
        max-width: rem-calc(400);
        display: none;
        &--postback {
            display: block;
        }
        &--success{
            padding:rem-calc(20);
            background: $white;
            max-width:100%;
            border: 1px solid $s-blue;
            .c-dynamic-form__message-text{
                max-width: 25rem;
                display: block;
                margin: 0 auto;
                font-weight: 600;
            }
        }
        &--error{
            padding:rem-calc(20);           
            max-width:100%;
            border: 1px solid $s-orange;
            background: lighten($s-orange, 90%);//$s-orange-light;
            text-align: left;
            margin-bottom: rem-calc(20);
            .c-dynamic-form__message-text{
                display: block;
                margin: 0 auto;
                font-weight: 600;
                a{
                    color: $black;                    
                }
                .fas{
                    color:$s-orange;
                    padding-right: rem-calc(5);
                }
            }
        }
    }
    &__icon{
        width: 100%;
        display: block;
        font-size: rem-calc(30);
        &--success{
            color: $s-blue;//$s-teal;
        }
        &--error{
            color: $s-orange;
        }
    }
    &__select{
        background: $white;
        border: rem-calc(2) solid $s-gray-medium;
        margin-top: rem-calc(5);
        padding: rem-calc(0 15);
        &::-ms-expand {
            display: none;
       }
       &:focus{
            border: rem-calc(2) solid $s-gray-medium;
            background: $white;
            box-shadow: none;
       }
    }
    &__select-parent{
        position:relative;
        i {
            position: absolute;
            top: 0;
            right: 0;
            padding: rem-calc(10 15);
            font-size: rem-calc(20);
            margin-top: rem-calc(5);
            pointer-events: none;
        }
    }
    
}
form{
    .validationerror{
        border:1px solid $s-orange;
    }
}
//tabbable custom file input
.using-keyboard .c-dynamic-form__file-input:focus + .c-dynamic-form__file-label,
.using-keyboard .c-dynamic-form__file-input.has-focus + .c-dynamic-form__file-label {
    outline: none;
    background: $s-yellow;
    color: $s-black !important;
    border-color: $s-black;
}
