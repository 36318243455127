.c-nav-quicklinks {
  $c-nav-quicklinks: '.c-nav-quicklinks';
  $c-block-grid: '.c-block-grid';
  $using-keyboard: '.using-keyboard';
  background: $s-blue;
  width: 100%;

  @include breakpoint(large) {
    display: flex;
    height: 100%;
    width: auto;
    justify-content: flex-end;
    align-items: flex-end;
    background: none;
  }

  &__list {
    list-style-type: none;
    display: flex;
    margin: 0;
    height: rem-calc(50);
    padding: rem-calc(8 15);

    @include breakpoint(small down) {
      padding: rem-calc(8 5);
    }

    @include breakpoint(large) {
      padding: unset;
      border: 0;
      height: unset;
      align-items: center;
    }
  }

  &__item {
    @include breakpoint(medium down) {
      margin: 0 !important;
      width: 33%;

      &:first-child {
        margin-right: rem-calc(6) !important;
      }
    }

    @media (max-width: rem-calc(420px)) {
      width: unset;
    }

    @include breakpoint(small down) {
      min-width: 0;
    }

    @include breakpoint(large) {
      width: 100%;
      margin: 0;
    }
  }

  &__link {
    color: $s-white;
    font-family: $s-font-family;
    font-size: rem-calc(15);
    background: $s-teal-quicklinks;
    padding: rem-calc(5 10);
    border-radius: rem-calc(35);
    display: block;
    position: relative;
    display: flex;
    justify-content: center;
    font-size: rem-calc(11);
    font-style: normal;
    font-weight: 500;
    line-height: rem-calc(24);
    letter-spacing: 0.2px;

    @include breakpoint(large) {
      color: $s-black;
      background: none;
      font-size: rem-calc(16);
      border-radius: 0;
      padding: 0;
      text-align: inherit;
      flex-direction: column;
      align-items: center;
      font-size: rem-calc(12);
      font-style: normal;
      font-weight: 600;
      line-height: rem-calc(24);
      letter-spacing: 0.4px;
      width: rem-calc(86);
    }

    &:hover {
      @include breakpoint(medium down) {
        background-color: $s-blue-dark;
        color: $s-white;
        text-decoration: underline;
      }

      @include breakpoint(large) {
        text-decoration: underline;
        color: black;
      }
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;
        @include link-underline($s-black);

        #{$c-nav-quicklinks}__icon {
          color: $s-black;
          background: $s-yellow;
        }
      }
    }

    &--active {

      &::after {
        @include link-underline($s-black);
      }
    }

    &--listen {
      font-family: $s-font-family-alt;
      font-size: rem-calc(15);
      position: relative;

      @include breakpoint(large) {
        display: none;
        padding-left: rem-calc(24);
      }

      &::before {
        content: "";
        font-family: $s-font-icon;
        font-weight: $s-font-semibold;
        font-size: rem-calc(16);
        margin-right: rem-calc(5);

        @include breakpoint(large) {
          position: absolute;
          top: rem-calc(-3);
          left: 0;
          font-size: rem-calc(19);
          margin-right: 0;
        }
      }
    }
  }

  &__icon {
    line-height: unset;
    margin-right: rem-calc(4);

    &:before {
      font-style: normal;
      font-family: "Font Awesome 6 Pro";
    }

    @include breakpoint(large) {
      font-size: rem-calc(15);
      background: $s-blue;
      color: $s-white;
      border-radius: rem-calc(28);
      line-height: rem-calc(28);
      display: flex;
      padding: rem-calc(12);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: rem-calc(40);
      margin-bottom: 6px;
      &:before {
        font-size: rem-calc(15);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: "Font Awesome 6 Pro";
      }
    }
  }
}
