.g-search {
  $g-search: '.g-search';
  $using-keyboard: '.using-keyboard';
  width: 100%;

  .rekai-dropdown-menu {
    border: $s-brand !important;
    outline: $s-brand !important;
  }

  .rekai-suggestions {
    background-color: $s-brand;

    .rekai-suggestion {
      border-bottom: rem-calc(1) solid #E8E8E8;

      &:hover {
        background-color: $s-brand !important;
      }

      p {
        color: $s-white;
        font-size: 16px;
        hyphens: auto
      }

      &:last-child {
        border-bottom: unset;
      }
    }
  }

  @include breakpoint(large) {
    .rekai-suggestions {
      background-color: unset;

      .rekai-suggestion {
        &:hover {
          background-color: $s-white !important;
        }

        p {
          color: unset;
        }
      }
    }
  }

  &--mobile {
    display: none;
    top: rem-calc(58);
    position: absolute;
    z-index: 10;
    background: $s-brand;
    padding: rem-calc(7 15 2 15);

    #{$g-search}__clear {
      height: rem-calc(32);
      width: rem-calc(32);
      top: 0;
      right: rem-calc(70);

      &::after {
        top: rem-calc(3);
        left: rem-calc(7);
        color: $s-white;
      }

      &:hover {

        &::after {
          color: $s-white;
        }
      }
    }
  }

  &__form {
    position: relative;

    @include breakpoint(large) {
      width: 100%;
      padding: 0;
      background: none;
    }
  }

  &__label {
    @include element-invisible;
  }

  &__input {
    border-radius: rem-calc(38);
    box-shadow: none;
    margin: 0;
    padding: rem-calc(10 20);
    background: none;
    border: unset;
    border: 1px solid #000;
    font-size: rem-calc(12);
    font-family: $s-font-family;
    color: #0A0A0A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @include breakpoint(large) {
      padding: rem-calc(16 8 16 32);
      height: rem-calc(56);
    }

    @include breakpoint(medium down) {
      border: 1px solid #949494;
    }

    &::placeholder {
      font-family: $s-font-family;
      color: #0A0A0A;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      @include breakpoint(medium down) {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      }
    }

    &:-ms-input-placeholder {
      //color: $s-gray-dark;
    }

    &::-ms-input-placeholder {
      //color: $s-gray-dark;
    }

    &::-ms-clear {
      // display: none;
    }

    &:focus {
      box-shadow: none;

      @include breakpoint(large) {
        // background: none !important;
      }
    }



    #{$using-keyboard} & {

      &:focus {
        outline: rem-calc(3) solid $s-yellow !important;
        background: none;

        @include breakpoint(medium down) {
          color: $s-white !important;
        }
      }
    }
  }

  [type='text']:focus {
    border: 1px solid #000;

    @include breakpoint(medium down) {
      border: 1px solid #949494;
    }
  }

  &__autocomplete {
    display: none;
    position: absolute;
    background: $s-white;
    color: $s-black;
    z-index: 9999;
    width: 100%;
    padding-bottom: rem-calc(7);
    margin-top: rem-calc(4.5);
    border-radius: 0 0 20px 20px;

    @include breakpoint(large) {
      position: absolute;
      padding-bottom: 0;
    }

    ul {
      margin: 0;
      list-style: none;

      li {
        cursor: pointer;
        padding: rem-calc(12 24);
        border-bottom: rem-calc(1) solid $s-gray-light;


        &:last-child {
          border: 0;
        }

        span {
          font-family: $s-font-family;
          color: #0A0A0A;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          @include breakpoint(medium down) {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: rem-calc(20);
    font-family: $s-font-family;
    height: rem-calc(39);
    padding: rem-calc(0 0 0 10);
    color: $s-white;

    @include breakpoint(large) {
      border: 1px solid $s-teal-light;
      border-radius: rem-calc(70);
      background-color: $s-blue;
      margin: rem-calc(8 8 8 0);
      padding: rem-calc(8 20 8 16);
      height: rem-calc(40);
      right: rem-calc(0);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::before {
      content: "";
      background: url('/static/assets/images/new-search-icon.svg') no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: rem-calc(8);
      width: rem-calc(16);
      height: rem-calc(16);

      @include breakpoint(large) {
        color: $s-white;
      }

      @include breakpoint(medium down) {
        background: url('/static/assets/images/search-icon-blue.svg') no-repeat;
        width: rem-calc(18);
        height: rem-calc(19);
        margin-right: 0;
      }
    }

    span {
      position: relative;
      display: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      @include breakpoint(large) {
        display: inline-block;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  &__clear {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    cursor: pointer;
    top: rem-calc(4);
    right: rem-calc(60);
    width: rem-calc(30);
    height: rem-calc(30);

    &::after {
      content: "\f057";
      font-family: $s-font-icon;
      font-weight: 600;
      font-size: rem-calc(18);
      color: $s-gray-dark;
      top: rem-calc(2);
      left: rem-calc(6);
      position: absolute;
      transition: all .3s ease 0s;
    }

    &:hover {
      cursor: pointer;

      &::after {
        color: $s-black;
      }
    }
  }
}
