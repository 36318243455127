.c-feedback {
    $c-feedback: '.c-feedback';
    $c-button: '.c-button';
    $using-keyboard: '.using-keyboard';
    border-top: rem-calc(2) solid $s-gray-light;
    padding-top: rem-calc(5);

    &__label {
        font-weight: $s-font-bold;
        line-height: 1.6;
        margin-right: rem-calc(10);
    }

    &__answers {
        display: inline-block;
        margin-top: rem-calc(10);

        @include breakpoint(medium) {
            margin-top: rem-calc(5);
        }

        #{$c-button} {
            margin-right: rem-calc(5);
            display: inline-block;

            span {
                top: 0;
            }

            #{$using-keyboard} & {

                &:focus {

                    span {

                        &::after {
                            background: none !important;
                        }
                    }
                }
            }
        }
    }

    &__message,
    &__form {
        background: $s-teal-light;
        position: relative;
        padding: rem-calc(0 50);

        #{$c-feedback}__outer {
            visibility: hidden;
            opacity: 0;
            height: 0;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
        }

        &--visible {
            padding: rem-calc(15);
            margin-top: rem-calc(15);
            
            #{$c-feedback}__outer {
                visibility: visible;
                opacity: 1;
                height: auto;
            }

            @include breakpoint(large) {
                padding: rem-calc(50);
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    &__close {
        background: $s-blue;
        color: $s-white;
        width: rem-calc(24);
        height: rem-calc(24);
        border-radius: rem-calc(24);
        position: absolute;
        top: rem-calc(-12);
        right: rem-calc(-12);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(large) {
            top: rem-calc(-14);
            right: rem-calc(-14);
            width: rem-calc(28);
            height: rem-calc(28);
            border-radius: rem-calc(28);
        }

        &::before {
            content: "\f00d";
            font-size: rem-calc(16);
            font-family: $s-font-icon;
            font-weight: $s-font-semibold;
            display: inline-block;
            height: rem-calc(12);
            width: rem-calc(12);
            line-height: rem-calc(12);
            position: relative;
        }
    }

    &__text {
        font-weight: $s-font-bold;
        display: block;
        margin-bottom: rem-calc(15);
    }

    &__label {
        @extend .c-form__label;
        padding-bottom: rem-calc(10);
        font-weight: $s-font-regular !important;
    }

    &__required {
        @extend .c-form__required;
    }

    &__textarea {
        @extend .c-form__textarea;
    }

    &__submit {
        margin-top: rem-calc(15);
    }

    &__error {
        @extend .c-form__error;
        color: #cf4007;
    }
}