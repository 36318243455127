.c-control-plan-print {
    display: none;
    @include print {
        display: block;
    }
    &__header {
        display: flex;
        margin-top: rem-calc(20) !important;
    }
    &__form {
        page-break-inside: avoid;
    }
    &__heading {
        page-break-inside: avoid;
    }
    &__content {
        page-break-inside: avoid;
    }
    &__footer {
        margin-top: rem-calc(40) !important;
        page-break-inside: avoid;
    }
    &__sign {
        @include xy-grid();
        @include xy-gutters($negative: true);
    }
    &__sign-date {
        @include xy-cell(3);
    }
    &__sign-signature {
        @include xy-cell(4);
        flex-grow: 1;
        padding: rem-calc(0 15) !important;
    }
    &__sign-name-clarification {
        @include xy-cell(4);
    }
    &__sign-label {
        font-weight: bold;
    }
    &__sign-field {
        width: 100%;
        height: rem-calc(20);
        border-bottom: 1px solid;
        display: block;
        margin-top: rem-calc(40) !important;
    }
    &__logo-img {
        width: rem-calc(200);
    }
    &__preamble {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(20) !important;
        &:before {
            content: "";
            width: 100%;
            height: rem-calc(30);
            float: left;
        }
    }
    &__intro {
        padding-left: rem-calc(30) !important;
    }
    &__label {
        font-weight: bold;
    }
    &__date {
        font-size: rem-calc(12);
    }
    &__heading {
        font-size: rem-calc(20);
        @include print {
            margin-top: rem-calc(30) !important;
        }
    }
    &__table {
        font-size: rem-calc(12);
        border-collapse: collapse;
        line-height: normal;
        th,
        td {
            border: rem-calc(1) solid $s-black;
            padding: rem-calc(5) !important;
            vertical-align: top;
        }
        th {
            font-weight: bold;
        }
    }
    .c-editor {
        h2 {
            font-size: rem-calc(20);
            line-height: normal;
        }
        h3 {
            font-size: rem-calc(18);
            line-height: normal;
        }
    }
}