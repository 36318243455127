.c-icon-list-item {
    $c-icon-list-item: '.c-icon-list-item';
    $crisis-web: '.crisis-web';

    border-top: rem-calc(2) solid $s-gray-light;
    padding: rem-calc(20 0 25 0);
    position: relative;

    &:first-child {
        border: 0;
    }

    &__outer {
        @include xy-grid();
        position: relative;

        &::before {
            @include icon-page($size: 30, $font-size: 16);
            position: absolute;
            top: 0;
            right: 0;

            @include breakpoint(medium) {
                @include icon-page($size: 40, $font-size: 18);
            }
    
            @include breakpoint(large) {
                top: rem-calc(-5);
                left: rem-calc(-64);
            }
        }
    }

    &--news {

        #{$c-icon-list-item}__outer::before {
            @include icon-news($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-news($size: 40, $font-size: 18);
            }

            #{$crisis-web} & {
                content: none !important;
            }
        }
    }

    &--doc {

        #{$c-icon-list-item}__outer::before {
            @include icon-doc($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-doc($size: 40, $font-size: 18);
            }
        }
    }

    &--event {

        #{$c-icon-list-item}__outer::before {
            @include icon-event($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-event($size: 40, $font-size: 18);
            }
        }
    }

    &--services {

        #{$c-icon-list-item}__outer::before {
            @include icon-services($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-services($size: 40, $font-size: 18);
            }
        }
    }

    &__link {
        color: $s-black;
        display: inline-block;
        padding-right: rem-calc(14);
        
        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            width: rem-calc(14);
            height: rem-calc(11);
            position: relative;
            margin-right: rem-calc(-14);
            display: inline-block;
        }

        span {
            display: inline;
        }
    }

    &__heading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        padding-right: rem-calc(40);

        @include breakpoint(medium) {
            padding: 0;
        }
    }

    &__preamble {
        max-width: rem-calc(610);
        display: inline-block;
    }

    &__figure {
        margin: rem-calc(20 0 10 0);
    }

    &__intro {
        @include xy-cell(12,$gutters:0);

        @include breakpoint(large) {
            @include xy-cell(8,$gutters:0);
        }
    }

    &__info {
        @include xy-cell(12,$gutters:0);
        display: flex;
        flex-direction: column;
        margin: rem-calc(10 0 0 0);

        @include breakpoint(large) {
            margin: 0;
            text-align: right;
            @include xy-cell(4,$gutters:0);
            margin-top: rem-calc(8);
        }
    }

    &__date {
        @include tag-date();
        display: block;
        margin-bottom: rem-calc(5);
    }

    &__category {
        @include tag-category();
    }

    &__event {

        span {
            display: block;
            font-weight: $s-font-bold;
        }
    }

    &__filetype {

        &::after {
            content: "|";
            margin: rem-calc(0 3);
        }

        &::before {
            margin-right: rem-calc(2);
        }

        &--pdf {

            &::before {
                content: "\f1c1";
                font-family: $s-font-icon;
            }
        }

        &--doc {

            &::before {
                content: "\f1c2";
                font-family: $s-font-icon;
            }
        }

        &--txt {

            &::before {
                content: "\f15c";
                font-family: $s-font-icon;
            }
        }

        &--ppt {

            &::before {
                content: "\f1c4";
                font-family: $s-font-icon;
            }
        }

        &--jpg {

            &::before {
                content: "\f1c5";
                font-family: $s-font-icon;
            }
        }
    }
}