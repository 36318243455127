.c-control-points {
    &__heading {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(15);
    }
    &__add-button {
        @extend .c-button;
        margin: 0 auto;
        i {
            margin-left: rem-calc(10);
        }
    }
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;

        &:last-child {
            border: 0;
        }

        &--hidden {
            visibility: hidden;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
            margin: 0;
            opacity: 0;
            height: 0;
        }

        &--visible {
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        &--expanded {
            border: 0;
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        position: relative;
        display: inline-flex;
        align-items: flex-start;
        cursor: pointer;
        padding-left: rem-calc(35);
        padding-right: rem-calc(10);
        span {
            @include link-underline(transparent);
        }

        &:hover,
        &:focus {
            span{
                @include link-underline($s-black);
            }
            
        }

        &::before {
            content: "+";
            background: $s-teal-light;
            height: rem-calc(24);
            width: rem-calc(24);
            border-radius: rem-calc(24);
            color: $s-blue;
            font-size: rem-calc(20);
            font-family: $s-font-family-alt;
            top: rem-calc(-3);
            left: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #{$using-keyboard} & {
            &:focus {
                &::before {
                    background: $s-black;
                    color: $s-yellow;
                }
            }
        }

        .c-control-points__item--expanded & {
            &::before {
                content: "−";
                background: $s-blue;
                color: $s-white;
            }
        }
    }

    &__title {
        @include xy-grid();
        @include flex-align(center);
        padding: rem-calc(18 0);
    }

    &__area {
        display: none;
    }

    &__button {
        &--expanded {
            @extend .c-button--minus;
        }
    }

    &__block {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: rem-calc(20);
        background: $s-gray;
        @include breakpoint(medium) {
            padding: rem-calc(40 50);
        }
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }
    &__content {
        width: 100%;
    }
}
