.g-navigation-side {
    $using-keyboard: '.using-keyboard';
    $sidenav: '.g-navigation-side';
    $item: "#{$sidenav}__item";
    $link: "#{$sidenav}__link";
    $button: "#{$sidenav}__button";
    
    display: none;

    @include breakpoint(large) {
        display: block;
    }

    @include breakpoint(xlarge) {
        padding-right: rem-calc(35);
    }

    &__title {
        display: block;
        font-family: $s-font-family-alt;
        font-size: rem-calc(16);
        background: $s-brand;
        color: $s-white;
        padding: rem-calc(0 15);
        height: rem-calc(45);
        display: flex;
        align-items: center;
    }

    &__toplevel {
        color: $s-white;

        #{$using-keyboard} & {
            background: $s-brand !important;

            &:focus {

                span {
                    background: $s-yellow;
                    @include link-underline($s-black);
                }
            }
        }

        span {
            display: inline;
        }

        &:hover {
            color: $s-white;

            span {
                @include link-underline($s-white);
            }
        }
    }

    &__list {
        margin: 0;
        position: relative;

        &--level1 {
            background: transparent;

            > #{$item} {
                border-bottom: rem-calc(1) solid $s-gray-light;

                &:last-child {
                    border-bottom: none;
                }

                &--expanded {
                    border-bottom: none !important;
                }
            }

            #{$link} {
                padding-top: rem-calc(12);
                padding-bottom: rem-calc(12);
                padding-left: rem-calc(15);
            }

            #{$button} {
                top: rem-calc(10);

                &:hover {
                    background: $s-teal-light;
                }

                #{$using-keyboard} & {
        
                    &:focus {
                        
                        &::after {
                            color: $s-black;
                        }
                    }
                }
            }
        }

        &--level2 {
            background: $s-teal-light;
            padding: rem-calc(10 0 10 25);

            #{$link} {
                padding-left: 0;
            }

            #{$button} {

                &:hover {
                    background: tint($s-teal, 90) 100%;
                }
            }
        }

        &--level3 {
            padding-left: rem-calc(15);
        }

        &--level4 {
            padding-left: rem-calc(15);

            &::before {
                content: '';
                width: rem-calc(2);                
                background: tint($s-teal, 80) 100%;
                position: absolute;
                top: rem-calc(5);
                bottom: 0;
                left: 0;
                border-radius: rem-calc(1);
            }
        }

        &--level5 {
            padding-left: rem-calc(15);
        }
    
        &--level2,
        &--level3,
        &--level4,
        &--level5 {
            display: none;

            #{$link} {
                padding-top: rem-calc(7);
                padding-bottom: rem-calc(7);
            }

            #{$button} {
                top: rem-calc(5);
            }
        }
    }

    &__item {
        list-style-type: none;
        position: relative;

        &--expanded {

            > #{$button} {

                &::after {
                    content: '−';
                }
            }
        }
    }

    &__link {
        color: $s-black;
        display: block;
        padding-right: rem-calc(60);

        &:hover,
        &:focus {
            color: $s-black;
        }

        &--active {

            span {
                font-weight: $s-font-bold;
                @include link-underline($s-black);
            }
        }
        
        span {
            position: relative;
            align-items: center;

            &:hover, 
            &:focus {
                @include link-underline($s-black);
            }
        }

        #{$using-keyboard} & {
        
            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                    @include link-underline($s-black);
                }
            }
        }
    }

    &__button {
        height: rem-calc(30);
        width: rem-calc(30);
        border-radius: rem-calc(30);
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(5);
        cursor: pointer;
        
        &::after {
            content: '+';
            font-family: $s-font-family-alt;
            font-size: rem-calc(20);
            font-weight: $s-font-bold;
            color: $s-blue;
        }
    }
}