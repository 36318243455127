.p-news-archive {
    @include gradient-bg();

    @include breakpoint(large) {
        padding-top: rem-calc(60);
    }

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
        @include flex-align(center);
    }

    &__content {
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }
}