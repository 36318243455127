.c-form-block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: rem-calc(20);
    background: $s-gray;

    @include breakpoint(medium){
        padding: rem-calc(40 50);
    }
    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }

    &__heading {
        font-size: rem-calc(18);
        line-height: rem-calc(28);
        @include breakpoint(medium) {
            font-size: rem-calc(24);
            line-height: rem-calc(40);
        }
    }
    &__content{
        width: 100%;
    }
    h3 {
        font-size: rem-calc(16);
        line-height: rem-calc(26);
        margin-bottom: 0;
        @include breakpoint(medium) {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
        }
    }
    h4 {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        margin-bottom: 0;
    }
    .c-editor__link, .c-editor-files__list{
        background: $s-white;
    }
}
