.c-accessibility-content {
    $using-keyboard: '.using-keyboard';
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 99999;

    a {
        font-weight: $s-font-bold;
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;

        &:focus {
            width: 100%;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            position: fixed;
            top: rem-calc(1);
            left: 0;
            padding: rem-calc(20);
            background: $s-blue;
            max-width: none;
            color: $s-white;
        }

        #{$using-keyboard} & {

            &:focus {
                
                span {
                    background: $s-yellow;
                    @include link-underline($s-black);
                }
            }
        }
    }
}