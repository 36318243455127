.c-article-row {
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }
    
    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }
    
    &__outer {
        @include xy-grid();

        @include breakpoint(medium) {
            @include xy-gutters($negative: true);
        }
    }

    &__inner {
        margin-bottom: rem-calc(30);

        &:last-child {

            @include breakpoint(small down) {
                margin: 0;
            }
        }

        @include breakpoint(medium) {
            @include xy-cell(4);
        }
    }

    &__image {
        margin-bottom: rem-calc(10);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(5);
        }
    }

    &__link {
        position: relative;
        font-weight: $s-font-bold;
        font-size: rem-calc(15);
        display: inline;
        @include link-underline($s-blue);
    }

    &__heading {

        @include breakpoint(medium only) {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(5);
        }
    }
}