.c-cols-quicklinks {
  background: lighten($s-gray, 2%);
  $using-keyboard: '.using-keyboard';

  @include breakpoint(large) {
    background: linear-gradient(90deg, lighten($s-gray, 2%) 50%, darken($s-gray, 2%) 50%);
  }

  &__outer {
    @include xy-grid-container;
  }

  &__inner {
    @include xy-grid();
    @include xy-gutters($negative: true);
  }

  .quick-navigation {
    width: 100%;

    @include breakpoint(medium) {
      display: none;
    }
  }

  &__col {
    @include xy-cell(12, $gutters:0);
    padding: rem-calc(10 0 0 0);
    position: relative;

    @include breakpoint(medium) {
      @include xy-cell(4, $gutters:0);
      padding: rem-calc(20 20 30 20);
    }

    @include breakpoint(large) {
      @include xy-cell(4, $gutters:0);
      padding: rem-calc(40 50 50 50);
    }

    &:first-of-type {
      justify-content: flex-end;
      background: lighten($s-gray, 2%);
    }

    &:nth-of-type(2) {
      background: $s-gray;
    }

    &:last-of-type {
      background: darken($s-gray, 2%);
    }

    &.rek-ai {
      @include xy-cell(12, $gutters:0);
      padding: rem-calc(10 0 0 0);
      position: relative;

      @include breakpoint(medium) {
        @include xy-cell(4, $gutters:0);
        padding: rem-calc(20 20 30 20);
      }

      @include breakpoint(large) {
        @include xy-cell(4, $gutters:0);
        padding: rem-calc(32 15 32 15);
      }

      &:first-of-type {
        justify-content: flex-end;
        background: lighten($s-gray, 2%);
        width: 100%;
        padding: rem-calc(10 15 15 15);

        @include breakpoint(medium) {
          padding: rem-calc(20 20 30 20);
        }

        @include breakpoint(large) {
          width: 50%;
          padding: rem-calc(32 15 32 15);

          .rek-prediction__item.rekai-pill a {
            color: $s-white;
          }

          .rek-prediction__item.rekai-pill {
            background-color: $s-blue;
            margin-bottom: rem-calc(8);
            border-radius: rem-calc(100);
          }
        }

        ul {
          margin: 0;

          li {
            transition: all .3s ease 0s;
            border-style: none;
          }
        }

        .rek-prediction__item.rekai-pill a {
          color: $s-white;
          text-decoration: none;
          font-family: $s-font-family-alt;
          font-size: rem-calc(15);
          font-style: normal;
          font-weight: $s-font-bold;
          line-height: rem-calc(24);
          padding: rem-calc(8 25);


          &:hover, &:focus {
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 1.5px;
          }
        }

        .rek-prediction__item.rekai-pill {
          background-color: $s-blue;
          margin-bottom: rem-calc(8);
          border-radius: rem-calc(100);


          &:hover, &:focus {
            background-color: $s-blue-dark;
          }
        }
      }

      &:nth-of-type(3) {
        background: $s-gray;
        width: 100%;


        @include breakpoint(medium) {
          width: 50%;
        }

        @include breakpoint(large) {
          width: 25%;
          padding: rem-calc(32 32 32 15);
        }
      }

      &:last-of-type {
        background: darken($s-gray, 2%);
        width: 100%;


        @include breakpoint(medium) {
          width: 50%;
        }

        @include breakpoint(large) {
          width: 25%;
        }
      }
    }
  }

  &__list {
    list-style-type: none;
    margin: rem-calc(0 0 25 0);
    padding: rem-calc(0 0 0 15);

    @include breakpoint(medium) {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  &__item {
    margin-bottom: rem-calc(15);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
      display: block;
      width: rem-calc(12);
      height: rem-calc(11);
      position: absolute;
      left: 0;
      top: rem-calc(7);
    }
  }

  &__heading {
    font-size: rem-calc(14);
    letter-spacing: rem-calc(1);
    height: rem-calc(50);
    line-height: rem-calc(50);
    margin: 0;
    display: block;
    padding-left: rem-calc(15);

    @include breakpoint(medium) {
      padding: 0;
      height: auto;
      line-height: rem-calc(48);
    }
  }

  &__link {
    color: $s-black;
    position: relative;
    margin-left: rem-calc(20);
    display: inline-block;
    padding-right: rem-calc(20);

    &:hover, &:focus {
      color: $s-black;

      span {
        @include link-underline($s-black);
      }
    }

    &[href^="https://"]:not([href*="www.sollentuna.se"]),
    &[href^="http://"]:not([href*="www.sollentuna.se"]) {

      &::after {
        @include external-link-item();
      }
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;

        span {
          background: $s-yellow;
        }
      }
    }
  }
}
