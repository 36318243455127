.c-anchor-link-box {
  @at-root .p-standard__container {

    @include breakpoint(large) {
      display: block;
      position: relative;
    }

    .c-editor {

      h2 {
        &.active {

          @include breakpoint(large) {
            padding-left: rem-calc(30);
          }

          &::before {
            @include breakpoint(large) {
              content: "\f0a9";
              color: $s-brand;
              font-family: $s-font-icon;
              font-size: rem-calc(20);
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }

    &.p-standard__container--no-h2 {
      display: flex;
    }
  }

  background: $s-white;
  z-index: 11;

  &.hide-for-large {
    display: block;
    margin-bottom: rem-calc(20);

    @include breakpoint(large) {
      display: none;
    }
  }

  &.show-for-large {
    display: none;

    @include breakpoint(large) {
      display: block;
    }
  }

  @include breakpoint(large) {
    display: block;
    float: right;
    width: rem-calc(245);
    left: unset;
    right: 0;
    margin-right: rem-calc(6);
  }

  @include breakpoint(xlarge) {
    margin-right: rem-calc(24);
  }

  @include breakpoint(xxlarge) {
    margin-right: rem-calc(30);
  }

  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    @extend .c-page-grid--two-cols__main;

    @include breakpoint(large) {
      position: sticky;
    }
  }

  &__content {
    background: $s-brand;
    color: $s-white;
    padding: rem-calc(16.5 20);
    position: relative;

    @include breakpoint(large) {
      color: $s-black;
      background: $s-white;
      padding: rem-calc(24 0 8 0);
    }

    &:hover {
      cursor: pointer;

      @include breakpoint(large) {
        cursor: default;
      }
    }
  }

  &__heading {
    font-weight: $s-font-bold;
    font-size: rem-calc(16);

    .active & {

      &::after {
        content: "\f056";

        @include breakpoint(large) {
          content: "";
        }
      }
    }

    &::after {
      content: "\f055";
      position: absolute;
      color: $s-teal-light;
      right: rem-calc(20);
      font-size: rem-calc(26);
      top: 50%;
      transform: translateY(-50%);
      width: rem-calc(20);
      font-family: $s-font-icon;

      @include breakpoint(large) {
        content: "";
      }
    }
  }

  &__link {
    display: none;

    &-item {
      display: block;
      background: $s-teal-light;
      padding: rem-calc(16.5 20);
      margin-bottom: rem-calc(4);
      max-width: 100%;
      width: 100%;

      @include breakpoint(large) {
        padding: rem-calc(11.5 16);
        font-size: rem-calc(14);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: rem-calc(4);
      }

      &:hover {
        text-decoration: underline;
      }

      &.active {
        border-left: 4px solid $s-brand;
        font-weight: $s-font-bold;
        background: #E7F3FB;

        @include breakpoint(large) {
          background: $s-teal-light;
        }
      }
    }
  }
}
