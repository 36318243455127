.c-search-filter {
  $using-keyboard: '.using-keyboard';
  $c-search-filter: '.c-search-filter';
  margin-bottom: rem-calc(15);

  @function tint($color, $percentage) {
    @return mix($s-white, $color, $percentage);
  }

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }

  &__outer {

    &--filter {
      display: none;
      margin-top: rem-calc(15);

      @include breakpoint(medium) {
        margin-top: rem-calc(50);
      }

      @include breakpoint(large) {
        margin-top: rem-calc(60);
      }
    }

    &--first {
      background: $s-teal-light;

      #{$c-search-filter}__total {

        @include breakpoint(medium down) {
          display: none;
        }
      }
    }

    &--second {
      background: tint($s-teal, 90) 100%;
      @include breakpoint(large) {
        display: none;
      }
    }
  }

  &__inner {
    @include xy-grid-container;
  }

  &__content {
    @include xy-grid();
    @include xy-gutters($negative: true);
    @include flex-align(center);
  }

  &__settings {
    @include xy-cell(12);
    height: rem-calc(40);
    display: flex;
    align-items: center;

    @include breakpoint(medium) {
      @include xy-cell(10);
      height: rem-calc(40);
    }

    @include breakpoint(large) {
      @include xy-cell(8);
      height: rem-calc(70);
    }
  }

  &__total {
    font-weight: $s-font-bold;
    font-size: rem-calc(15);
    margin-right: rem-calc(30);

    @include breakpoint(small only) {
      flex-grow: 1;
    }
  }

  &__button {
    font-weight: $s-font-bold;
    font-size: rem-calc(15);
    color: $s-blue;

    &:hover {
      cursor: pointer;
    }

    &:focus {

      @include breakpoint(medium down) {
        outline: none;
      }
    }

    span {

      @include breakpoint(large) {
        @include link-underline($s-blue);
      }
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;

        span {
          background: $s-yellow;
        }
      }
    }

    &--expand {

      &::before {
        content: "\f0b0";
        font-family: $s-font-icon;
        font-size: rem-calc(14);
      }

      &::after {
        content: "+";
        font-size: rem-calc(20);
        font-family: $s-font-family-alt;
        width: rem-calc(15);
        display: inline-block;
        text-align: right;
        top: rem-calc(1);
        position: relative;
      }

      @include breakpoint(small only) {
        text-align: left;
      }
    }

    &--expanded {

      &::after {
        content: "−";
        font-size: rem-calc(20);
        font-family: $s-font-family-alt;
      }
    }

    &--reset {
      position: relative;
      text-align: right;
      display: none;

      @include breakpoint(large) {
        position: relative;
        top: rem-calc(2);
      }

      &::before {
        content: "\f057";
        font-family: $s-font-icon;
        font-size: rem-calc(14);
      }
    }
  }

  &__wrapper {
    @include xy-cell(12);

    @include breakpoint(medium) {
      @include xy-cell(12);
    }

    @include breakpoint(large) {
      @include xy-cell(12);
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: rem-calc(10);
    margin-top: rem-calc(10);
    display: flex;
    flex-flow: row wrap;

    @include breakpoint(medium) {
      margin-bottom: rem-calc(60);
      margin-top: 0;
      justify-content: center;
    }

    @include breakpoint(large) {
      margin-bottom: rem-calc(30);
    }
  }

  &__link {
    color: $s-black;
    font-weight: $s-font-bold;
    position: relative;
    padding-left: rem-calc(45);

    &--selected {

      span {
        @include link-underline($s-black);
      }
    }

    &:hover {

      span {
        color: $s-black;
        @include link-underline($s-black);
      }
    }

    &::before {
      margin-right: rem-calc(5);
      position: absolute;
      top: rem-calc(-7);
      left: 0;
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;

        span {
          @include link-underline($s-black);
        }

        &::before {
          background: $s-yellow !important;
          color: $s-black !important;
        }
      }
    }
  }

  &__item {
    width: 50%;
    margin-bottom: rem-calc(30);
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      &:nth-child(even) {
        padding: rem-calc(0 0 0 10);
      }

      &:nth-child(odd) {
        padding: rem-calc(0 10 0 0);
      }
    }

    @include breakpoint(medium) {
      width: auto;
      padding: rem-calc(0 20);
      margin-bottom: 0;
    }

    @include breakpoint(large) {
      padding: rem-calc(0 25);
    }

    &:nth-child(1) {

      #{$c-search-filter}__link {
        &::before {
          @include icon-page($size: 40, $font-size: 18);
        }

        &--selected,
        &:hover {

          &::before {
            background-color: $s-teal-lighter;
            color: $s-teal-dark;
          }
        }
      }
    }

    &:nth-child(2) {

      #{$c-search-filter}__link {

        &::before {
          @include icon-doc($size: 40, $font-size: 18);
        }

        &--selected,
        &:hover {

          &::before {
            background-color: $s-teal-lighter;
            color: $s-blue;
          }
        }
      }
    }

    &:nth-child(3) {

      #{$c-search-filter}__link {
        &::before {
          @include icon-news($size: 40, $font-size: 18);
        }

        &--selected,
        &:hover {

          &::before {
            background-color: $s-pink-light;
            color: $s-pink;
          }
        }
      }
    }

    &:nth-child(4) {

      #{$c-search-filter}__link {

        &--selected,
        &:hover {

          &::before {
            background-color: $s-pink-light;
            color: $s-pink-dark;
          }
        }

        &::before {
          @include icon-event($size: 40, $font-size: 18);
        }
      }
    }
  }

  &__left {
    flex: 1;
  }
}
