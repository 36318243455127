.c-block-grid {
    @include xy-cell(12);
    @include breakpoint(medium only) {
        @include xy-cell(10);
    }
    &--half {
        @include xy-cell(12);
        @include breakpoint(medium) {
            @include xy-cell(10);
        }
        @include breakpoint(large) {
            @include xy-cell(12);
        }
       @include breakpoint(xlarge) {
            @include xy-cell(6);
        }
    }
    &--third {
        @include xy-cell(12);
        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }
    &--two-thirds {
        @include xy-cell(12);
        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }
    &--full-bleed {
        margin: 0;
        width: 100%;
        @include breakpoint(medium) {
            @include xy-grid-container($padding: 10);
        }
        &__outer {
            @include xy-grid();
            @include xy-gutters($negative: true);
            @include flex-align(center);
            @include breakpoint(medium) {
                margin-left: calc(-50vw + 50%);
                margin-right: calc(-50vw + 50%);
            }
        }
        &__inner {
            @include xy-cell(12);
            max-width: rem-calc(1250);
            @include breakpoint(medium only) {
                @include xy-cell(10);
            }
        }
    }
    &--full-bleed {
        margin: 0;
        width: 100%;
        @include breakpoint(medium) {
            @include xy-grid-container($padding: 10);
        }
        &__outer {
            @include xy-grid();
            @include xy-gutters($negative: true);
            @include flex-align(center);
            @include breakpoint(medium) {
                margin-left: calc(-50vw + 50%);
                margin-right: calc(-50vw + 50%);
            }
        }
        &__inner {
            @include xy-cell(12);
            max-width: rem-calc(1250);
            @include breakpoint(medium only) {
                @include xy-cell(10);
            }
        }
    }
    &--margin {
        @include breakpoint(small down) {
            margin-bottom: rem-calc(15);
        }
    }
    &--selector {
        &:first-of-type {
            @include breakpoint(small down) {
                margin-top: rem-calc(-15);
            }
        }
        &:last-of-type {
            @include breakpoint(small down) {
                margin-bottom: rem-calc(-15);
            }
        }
    }
}
