/* Different layout on Landing Page, Editor */

.c-contact-people {
    $c-editor: '.c-editor';
    $c-block-grid-half: '.c-block-grid--half';
    $p-landing: '.p-landing';
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }

    &__outer {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
    }

    &__inner {
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(8);
        }

        #{$c-block-grid-half} & {

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }

        /* Different styles on Landing Page + Editor component */
        #{$p-landing} &,
        #{$c-editor} & {

            @include breakpoint(large) {
                @include xy-cell(12);
            }
        }
    }

    &__heading {
        margin-bottom: rem-calc(10);

        @include breakpoint(large) {
            margin-bottom: rem-calc(20);
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        margin-bottom: rem-calc(10);
        display: flex;
        flex-direction: column;
        background: $s-gray-silver;
        padding: rem-calc(15 15 20 15);
        border-radius: rem-calc(3);

        @include breakpoint(large) {
            padding: rem-calc(20 30 25 30);
        }

        &:last-child {
            margin: 0;
        }
    }

    &__name {
        font-weight: $s-font-semibold;
    }

    &__phone, &__email {
        font-weight: $s-font-semibold;
        display: inline-block;

        &:hover, &:focus {

            span {
                @include link-underline($s-blue);
                padding-bottom: 0;
            }
        }
    }

    &__email {
        word-break: break-word;
    }

    #{$c-editor} & {

        @include breakpoint(large) {
            margin-bottom: rem-calc(30);
        }
    }
}