.p-event {
    $c-media: '.c-media';
    @extend .c-page-grid;
    @include gradient-bg();

    &__outer {
        @extend .c-page-grid--center__outer;
        // max-width: rem-calc(844);
    }

    &__article {
        @extend .c-page-grid--center__inner;

        @include breakpoint(large) {
            overflow: hidden;
        }
    }

    &__inner {
        @extend .c-page-grid--center__article;
    }

    &__content {
        @extend .c-page-grid--center__content;
    }

    #{$c-media} {
        margin-top: rem-calc(-30);

        @include breakpoint(large) {
            margin-top: 0;
        }
    }
}
