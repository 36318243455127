.g-header {
    $g-header: '.g-header';
    $g-navigation: '.g-navigation';
    $g-navigation-mobile: '.g-navigation-mobile';
    $g-search: '.g-search';
    $g-topbar: '.g-topbar';
    $c-nav-quicklinks: '.c-nav-quicklinks';
    $c-nav-support: '.c-nav-support';
    $using-keyboard: '.using-keyboard';

    @function tint($color, $percentage) {
        @return mix($s-white, $color, $percentage);
    }

    &--subsite {

        #{$g-header} {

            &__outer {

                @include breakpoint(medium down) {
                    min-height: rem-calc(100);
                    height: 100%;
                }
            }

            &__col {

                @include breakpoint(large) {
                    @include xy-cell(6);
                }

                &--logo {

                    #{$g-header}__title {
                        display: none;

                        @include breakpoint(large) {
                            display: flex;
                        }

                        a {

                            &:hover, &:focus {

                                span {
                                    border-bottom: rem-calc(2) solid $s-black;
                                    padding-bottom: rem-calc(1);
                                }
                            }
                        }
                    }
                }
    
                &--search {
                    
                    #{$g-search} {
                        max-width: rem-calc(400);
                    }
                }
            }
        }

        #{$g-topbar} {

            &__content {
                background: $s-teal-light;
            }
        }

        #{$c-nav-support} {

            @include breakpoint(large) {
                background: $s-teal-light;
                display: flex;
                justify-content: flex-end
            }

            &__list {
                top: 0;
            }
        }

        #{$g-navigation}__list {
            flex-wrap: nowrap;
        }

        #{$g-navigation}__item {
            flex-grow: 0;

            @include breakpoint(large) {
                margin-right: rem-calc(25);
            }

            @include breakpoint(xlarge) {
                margin-right: rem-calc(50);
            }

            &:last-child {
                margin: 0
            }
        }

        #{$g-search} {

            @include breakpoint(medium down) {
                top: rem-calc(58);
                z-index: 10;
            }
        }

        #{$g-navigation-mobile} {
            top: rem-calc(58);

            #{$c-nav-support} {
                display: none;
            }
        }

        #{$c-nav-quicklinks}__link {

            &:hover, &:focus {
                background: darken($s-brand-tint, 4%);
            }
        }
    }

    &--crisis {

        #{$g-header} {
            
            &__outer {
                height: rem-calc(100);

                @include breakpoint(large) {
                    height: rem-calc(130);
                }
            }

            &__col {
                
                &--logo {

                    #{$g-header}__title {
                        display: none;

                        @include breakpoint(large) {
                            display: flex;
                        }

                        a {

                            &:hover, &:focus {

                                span {
                                    border-bottom: rem-calc(2) solid $s-black;
                                    padding-bottom: rem-calc(1);
                                }
                            }
                        }
                    }
                }
            }

            &__icon {
                background: $s-brand-tint;
                color: $s-brand;
            }
        }

        #{$g-search} {

            @include breakpoint(medium down) {
                position: absolute;
                top: rem-calc(58);
                z-index: 10;
            }
        }

        #{$g-navigation-mobile} {
            top: rem-calc(58);

            #{$c-nav-support} {
                display: none;
            }

            &__list {

                &--level3 {
                    background: $s-brand-tint !important;

                    #{$g-navigation-mobile}__button {
                        background: tint($s-brand, 90) !important;
    
                        &::before {
                            border-color: tint($s-brand, 90) !important;
                        }
    
                        &--collapsed {
                            background: $s-brand-tint !important;
                        }
                    }
                }

                &--level4 {
                    background: tint($s-brand, 90) !important;

                    #{$g-navigation-mobile}__button {
                        background: tint($s-brand, 85) !important;
    
                        &--collapsed {
                            background: tint($s-brand, 90) !important;
                        }
    
                        &::before {
                            border-color: tint($s-brand, 85) !important;
                        }
                    }
                }

                &--level5 {
                    background: tint($s-brand, 85) !important;
                }

                #{$g-navigation-mobile}__link {
                    color: $s-brand !important;

                    &--active {

                        span {
                            border-color: $s-brand !important;
                        }
                    }
                }
            }

            &__button {
                color: $s-brand !important;
            }
        }

        #{$c-nav-quicklinks}__link {

            &:hover, &:focus {
                background: darken($s-brand-tint, 4%);
            }
        }

        #{$g-navigation} {

            &__list {
                flex-grow: unset;
                flex-wrap: nowrap;
            }

            &__item {

                @include breakpoint(large) {
                    flex-grow: 0;
                    padding-right: rem-calc(60);
                    margin-right: rem-calc(25);
                }
    
                @include breakpoint(xlarge) {
                    margin-right: rem-calc(50);
                }
    
                &:last-child {
                    margin: 0
                }
            }
        }
    }

    &__outer {
        @include breakpoint(large) {
            @include xy-grid-container;
            height: rem-calc(90);
            padding-top: rem-calc(12);
            padding-bottom: rem-calc(12);
        }
    }

    &__inner {
        @include xy-grid();
        align-content: center;
        height: 100%;
        @include breakpoint(xxlarge){
            justify-content: flex-end;
        }
    }

    &__col {
        display: flex;
        align-items: center;

        @include breakpoint(large) {
        }

        &--logo {
            padding-left: rem-calc(15);

            @include breakpoint(medium down) {
                width: rem-calc(120);
            }

            @include breakpoint(large) {
                padding-left: 0;
            }
            @include breakpoint(xxlarge) {
                margin-right: auto;
            }
        }

        &--links {
              
            @include breakpoint(medium down) {
                @include xy-cell(12, $gutters:0);
            }

            @include breakpoint(large) {
                justify-content: flex-end;
                display: block;
                margin-left: auto;
            }
            @include breakpoint(xxlarge) {
                margin-left: 0;
            }
        }
        &--mobileShortcuts{
            display: none;
            @include breakpoint(medium down){
                display: block;
            }
        }
        &--desktopShortcuts{
            display: none;
            @include breakpoint(large){
                display: block;
            }
        }

        &--search {
            @include breakpoint(medium down) {
                flex-grow: 1;
                width: 120px;
                max-width: 35%;
                margin-right: rem-calc(8);
                margin-left: auto;
            }

            @include breakpoint(medium) {
                max-width: 225px;
            }

            @include breakpoint(large) {
                display: flex;
                max-width: unset;
                width: rem-calc(465);
                margin-left: 44px;
            }
            @include breakpoint(xxlarge) {
                margin-right: rem-calc(67);
                margin-left: 0;
            }
        }

        &--mobile-nav {
            justify-content: flex-end;
            
            @include breakpoint(large) {
                display: none;
            }
            @include breakpoint(medium down) {
                border-top: 1px solid var(--sollentuna-ffffff, #FFF);
            }
        }

        &--title {
            background: $s-gray-silver;
            @include xy-cell(12, $gutters:0);

            @include breakpoint(large) {
                display: none;
            }
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem-calc(11 15 10 15);
        min-height: rem-calc(58);
        width: rem-calc(65);
        color: $s-white;

        &:focus {
            outline: 0;
        }

        @include breakpoint(large) {
            display: none;
        }

        &--menu {
            background: $s-blue;

            &::before {
                content: "";
                background: url('/static/assets/images/menu-white.svg') no-repeat;
                background-size: contain;
                display: inline-block;
                width: rem-calc(30);
                height: rem-calc(30);
                font-size: rem-calc(22);
                font-family: $s-font-icon;
                font-weight: $s-font-semibold;
            }

            &#{$g-header}__icon--expanded {

                &::before {
                    content: "\f00d";
                    background: unset;
                    font-size: rem-calc(22);
                    font-family: $s-font-icon;
                    font-weight: $s-font-semibold;
                }
            }

            span {
                font-size: rem-calc(11);
                font-family: $s-font-family-alt;
            }
        }

        &--search {
            background: $s-teal;
            background: tint($s-teal, 90) 100%;
            padding: rem-calc(13 15 10 15);

            &::before {
                content: "\f002";
                font-size: rem-calc(20);
                font-family: $s-font-icon;
                font-weight: $s-font-semibold;
                margin-bottom: rem-calc(3);
            }

            span {
                font-size: rem-calc(12);
                font-family: $s-font-family-alt;
            }
        }

        &--expanded {
            background: $s-brand;
            color: $s-white;
        }
    }

    &__logotype {
        background: url(/static/assets/images/sk-liggande-logo-svart.svg) no-repeat left center;
        height: rem-calc(55);
        width: 100%;
        display: block;
        background-size: contain;

        @include breakpoint(large) {
            height: rem-calc(79);
            background-size: auto;
            width: rem-calc(84);
            background: url(/static/assets/images/sk-staende-logo-svart.svg) no-repeat left center;
        }

        &:focus {

            @include breakpoint(large) {
                background: url(/static/assets/images/sk-staende-logo-svart.svg) no-repeat left center !important;
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: url(/static/assets/images/sk-staende-logo-svart.svg) no-repeat left center;
                outline: rem-calc(3) solid $s-yellow !important;
            }
        }
    }

    &__title {
        height: 100%;
        display: flex;
        align-items: center;
        min-height: rem-calc(42);
        padding-left: rem-calc(15);
        padding: rem-calc(5 0 5 15);

        @include breakpoint(large) {
            border-left: rem-calc(1) solid $s-gray-light;
            padding: rem-calc(0 0 0 30);
            margin-left: rem-calc(30);
        }

        a {
            font-family: $s-font-family-alt;
            font-weight: $s-font-bold;
            font-size: rem-calc(16);
            color: $s-black;

            @include breakpoint(large) {
                font-size: rem-calc(28);
            }
        }
    }
}

#__ba_panel {
    display: none;
}
