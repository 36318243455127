.c-facts {
    $c-editor: '.c-editor';
    $using-keyboard: '.using-keyboard';
    @include xy-grid;
    @include xy-gutters($negative: true);
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(20);
    position: relative;
    overflow-x: hidden;

    @include breakpoint(large) {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(40);
    }

    @include breakpoint(640px) {
        overflow-x: visible;
    }

    &__outer {
        background: $s-teal-light;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100%;
        right: -100%;
        z-index: -1;
    }

    &__inner {
        @include xy-cell(12);
        padding: rem-calc(20 0 10 0);
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            padding: rem-calc(40 26 20 26);
        }
    }

    ul,
    ol {
        margin: rem-calc(0 0 25 0);
        padding: 0;
    }

    ul {

        li {
    
            &::before {
                content: "\f111";
                font-family: $s-font-icon;
                font-size: rem-calc(7);
                font-weight: $s-font-bold;
                color: $s-blue;
                position: absolute;
                top: rem-calc(7);
                left: 0;
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: item;

        li {
            counter-increment: item;

            &::before {
                content: counter(item)'.';
                position: absolute;
                left: 0;
                font-weight: $s-font-bold;
            }
        }
    }

    li {
        list-style-type: none;
        position: relative;
        padding-left: rem-calc(25);
    }

    #{$c-editor}__link {
        background: $s-white;

        #{$using-keyboard} & {

            &:focus {
                background: $s-white;
            }
        }
    }
}