.g-quicknavigation {
    padding: rem-calc(15);

    // Only display for mobile
    @include breakpoint(medium) {
        display: none;
    }

    &__title {
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-weight: $s-font-semibold;
    }

    &__menu {

    }

    &__list {
        list-style: none;
        margin: 0;
    }

    &__item {
        margin-bottom: rem-calc(8);
        padding: rem-calc(8 16);

        background: $s-teal-light;
        border-radius: rem-calc(5);
        font-weight: $s-font-bold;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: rem-calc(16);
        color: $s-black;
        font-family: $s-font-family-alt;

        i {
            width: rem-calc(20);
            text-align: center;

            &.fa-no-icon {
                display: none;
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            margin-left: auto;
        }
    }

}
