.p-news {
    $c-media: '.c-media';
    @extend .c-page-grid;
    @include gradient-bg();

    &__outer {
        @extend .c-page-grid--center__outer;
    }

    &__article {
        @extend .c-page-grid--center__inner;
    }

    &__inner {
        @extend .c-page-grid--center__article;
    }

    &__content {
        @extend .c-page-grid--center__content;
    }

    #{$c-media} {
        margin-top: rem-calc(-30);

        @include breakpoint(large) {
            margin-top: 0;
        }
    }
}