.g-footer {
    $g-footer: '.g-footer';
    $using-keyboard: '.using-keyboard';
    $crisis-web: '.crisis-web';

    background: $s-gray;
    padding: rem-calc(30 0 30 0);
    margin: rem-calc(50 0 0 0);

    @include breakpoint(medium) {
        padding: rem-calc(60 0);
        margin: rem-calc(60 0 0 0);
    }

    @include breakpoint(large) {
        padding: rem-calc(70 0);
        margin: rem-calc(100 0 0 0);
    }

    &--crisis {
        background: $s-brand-tint;
    }

    #{$crisis-web} & {
        background: $s-brand-tint;
    }

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
    }

    &__col {
        @include xy-cell(12);
        margin-bottom: rem-calc(15);

        @include breakpoint(medium) {
            @include xy-cell(10);
            margin-bottom: rem-calc(30);
        }
        
        @include breakpoint(large) {
            @include xy-cell(4);
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        h3, h4, h5 {
            font-family: $s-font-family-alt;
            margin-bottom: rem-calc(5);
        }

        h3 {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
        }

        h4 {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
        }

        h5 {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
        }

        p {
            
            a {
                @include link-underline($s-blue);
                position: relative;
                display: inline;
            }
        }

        &--find {

            #{$g-footer}__heading {

                &::before {
                    content: "\f277\00a0";
                    font-family: $s-font-icon;
                }
            }
        }

        &--about {

            #{$g-footer}__heading {
    
                &::before {
                    content: "\f02e\00a0";
                    font-family: $s-font-icon;
                    font-weight: rem-calc(600);
                }
            }
        }
    }

    &__heading {
        font-size: rem-calc(13);
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        margin-bottom: 0;

        @include breakpoint(medium) {
            margin-bottom: rem-calc(10);
        }
    }

    &__contact {
        position: relative;
        padding-left: rem-calc(40);
        margin-bottom: rem-calc(15);
        margin-top: rem-calc(5);

        @include breakpoint(large) {
            margin-top: rem-calc(20);
            padding-left: rem-calc(60);
            margin-bottom: rem-calc(30);
        }

        &::before {
            content: "\f3cd";
            width: rem-calc(30);
            height: rem-calc(30);
            border-radius: rem-calc(50);
            font-family: $s-font-icon;
            font-weight: 900;
            background: $s-white;
            display: block;
            font-size: rem-calc(18);
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(large) {
                width: rem-calc(50);
                height: rem-calc(50);
                font-size: rem-calc(32);
                margin-top: rem-calc(-25);
                top: 50%;
            }
        }
    }

    &__tel {
        font-size: rem-calc(20);
        color: $s-black;

        &:hover, &:focus {
            color: $s-black;
            position: relative;
            @include link-underline($s-black);
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-bottom: rem-calc(10);
        position: relative;

        &::before {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
            display: block;
            width: rem-calc(12);
            height: rem-calc(11);
            position: absolute;
            left: 0;
            top: rem-calc(7);
        }
    }

    &__link {
        color: $s-black;
        position: relative;
        margin-left: rem-calc(18);
        display: inline-block;
        padding-right: rem-calc(16);

        &:hover, &:focus {
            
            span {
                color: $s-black;
                @include link-underline($s-black);
            }
        }

        &[href^="https://"]:not([href*="www.sollentuna.se"]),
        &[href^="http://"]:not([href*="www.sollentuna.se"]) {

            &::after {
                @include external-link-item();
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }
}