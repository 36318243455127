.c-links {
    margin: rem-calc(50 0);

    &__list {
        margin: 0;
        padding: rem-calc(5 30);
    }

    &__item {
        list-style-type: none;
        padding: rem-calc(15 0);

        &:last-child {
            border-bottom: none;
        }
    }

    &__link {

        &:hover {
            color: $s-blue;
            position: relative;

            &::after {
                @include link-underline($s-blue);
            }
        }
    }
}