.p-portal {

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
    }

    &__block-area {
        @extend .c-page-grid__outer;
        padding: rem-calc(0 0 20 0);
    }
}