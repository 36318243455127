.c-tooltip {
    display: inline-block;
    &__text {
        position: absolute;
        bottom: calc(100% + 5px);
        // left: 0;
        background: $s-black;
        color: #fff;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        padding: rem-calc(10);
        display: none;
    }
    &__button {
        position: relative;
        cursor: pointer;
        color: $s-blue;
        font-size: rem-calc(18);
        &:after{
            content: "";
            width: 200%;
            height: 200%;
            position: absolute;
            top: -50%;
            left: -50%;
        }
        &:hover,
        &:focus {
            &:before {
                content: "";
                border-top: 10px solid $s-black;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
            + .c-tooltip__text {
                display: block;
            }
        }
    }
}
