.c-faq {
    $c-faq: '.c-faq';
    $c-block-grid: '.c-block-grid';
    $c-block-grid-half: '.c-block-grid--half';
    $c-editor: '.c-editor';
    $p-landing: '.p-landing__inner .p-landing__block-area';
    $using-keyboard: '.using-keyboard';
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }
        h2, h3, h4, h5, p, blockquote{
            max-width: unset !important;
        }
    &__outer {
        padding: rem-calc(0 0 30 0)!important;

        @include breakpoint(medium) {
            padding: rem-calc(0 0 10 0)!important;
            margin: 0;
        }

        @include breakpoint(large) {
            padding: rem-calc(0 0 20 0)!important;
        }
    }

    &__inner {

        @include breakpoint(medium) {
        }

        @include breakpoint(large) {
            display: flex;
        }
    }

    &__editor {
        
        img {
            @include editor-img();
        }

        > ul {
            @include editor-ul-list();
        }

        > ol {
            @include editor-ol-list();
        }
        &__highlight:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__heading {
        font-size: rem-calc(24);
    }

    &__preamble {
        margin-bottom: unset !important;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        @include breakpoint(medium) {
            margin: 0;
        }
    }

    &__item {
        border: rem-calc(2) solid $s-gray-light;
        margin-bottom: rem-calc(16);
        border-radius: 4px;
        &--hidden {
            visibility:hidden;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
            margin: 0;
            opacity: 0;
            height: 0;
            display: none;
        }
    
        &--visible {
            display: block;
            opacity: 1;
            visibility: visible;
            height: auto;
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        &--expanded {
            border: rem-calc(2) solid $s-gray-light;
        }

        h2, h3, h4, h5, p, blockquote{
            color: $s-blue;
            margin-bottom: rem-calc(10);
            max-width: unset !important;
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        position: relative;
        display: inline-block;
        display: flex;
        justify-content: space-between;
        max-width: unset;
        margin: rem-calc(20 24 20 24);

        span{
          max-width: 90%;
        }

        &:hover, &:focus {

            span {
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 1.5px
            }
        }

        &[href^="https://"]:not([href*="www.sollentuna.se"]),
        &[href^="http://"]:not([href*="www.sollentuna.se"]) {
            
            &::after {
                content: "\f35d\00a0\00a0";
                background: none;
                font-family: $s-font-icon;
                font-weight: $s-font-bold;
                font-size: rem-calc(13);
                line-height: rem-calc(14);
                top: rem-calc(6);
                position: absolute;
                bottom: rem-calc(2);
                color: $s-blue;
            }

            #{$using-keyboard} & {

                &::before{
                    color: $s-black;
                }
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }

        #{$c-faq}__item--question & {

            &::after {
                content: "";
                background: url('/static/assets/images/plus-slim.svg') no-repeat;
                background-size: contain; 
                height: rem-calc(20);
                width: rem-calc(18);
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }

            #{$using-keyboard} & {

                &:focus {

                    &::before {
                        background: $s-black;
                        color: $s-yellow;
                    }
                }
            }
        }

        #{$c-faq}__item--expanded & {

            &::after {
                content: "";
                background: url('/static/assets/images/minus.svg') no-repeat;
                transform: unset;
            }
        }
    }

    &__question {
        padding: rem-calc(16 20 38 20);

        @include breakpoint(medium) {
            padding: rem-calc(32 40 38 40);
        }
    }

    &__content {
        .c-editor__link{
            background: white;
            padding: rem-calc(10 30) !important;
            max-width: unset;
            span{
                margin-right: 12px;
            }
        }
    }

    &__title {
        #{$c-faq}__content {
            position: relative;
        }
    }

    &__extend {
        display: flex;
        justify-content: center;
        margin-top: rem-calc(15);
    }

    &__question {
        display: none;
        background-color: #E7F3FB;
    }
}
