.c-control-plan {
    &__pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem-calc(30);
        flex-wrap:wrap;
    }
    &__button {
        @extend .c-button;
        min-width: rem-calc(1);
        padding: rem-calc(0 20);
        width: auto;
        cursor: pointer;
        margin-bottom: rem-calc(5);

        &--prev {
            margin-right: auto;
            i {
                margin-right: rem-calc(10);
            }
        }
        &--next {
            margin-left: auto;
            i {
                margin-left: rem-calc(10);
            }
        }
    }
    &__subheading {
        margin-top: rem-calc(20);
    }
    
    &__save {
        padding: rem-calc(20);
        background: $s-teal-light;
        margin-top: rem-calc(60);
        @include breakpoint(medium) {
            padding: rem-calc(40 50);
        }
    }
    &__save-button {
        @extend .c-button;
        padding: rem-calc(0 40);
        min-width: 0;
        display: inline-flex;
        i {
            margin-left: rem-calc(10);
        }
    }
    &__review-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__edit-button {
        color: $s-blue;
        cursor: pointer;
        white-space: nowrap;
        padding: rem-calc(10 0);
        i {
            margin-left: rem-calc(10);
        }
    }
    &__edit-button-text {
        padding-bottom: rem-calc(1);
        border-bottom: rem-calc(2) solid $s-blue;
    }
    &__divider {
        display: block;
        margin-bottom: rem-calc(30);
    }
    &__block {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: rem-calc(20);
        background: $s-gray;
        @include breakpoint(medium) {
            padding: rem-calc(40 50);
        }
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }
    &__content {
        width: 100%;
    }
}
