.c-button {
    $using-keyboard: '.using-keyboard';
    $crisis-web: '.crisis-web';

    color: $s-blue;
    border: rem-calc(2) solid $s-blue;
    font-size: rem-calc(15);
    height: rem-calc(40);
    font-weight: $s-font-bold;
    font-family: $s-font-family-alt;
    border-radius: rem-calc(2);
    width: 100%;
    cursor: pointer;
    line-height: rem-calc(10);
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium) {
        width: auto;
        min-width: rem-calc(300);
        transition: all .3s ease 0s;
    }

    &:hover {
        background: $s-blue;
        color: $s-white;
    }

    &:focus {
        outline: 0;
    }

    span {
        position: relative;
        top: rem-calc(-1);
    }
    
    &:focus {
        outline: 0;
    }

    &--alt {
        background: $s-teal-light;
        border-radius: rem-calc(40);
        border: 0;
        height: rem-calc(40);
        min-width: auto;
        padding: rem-calc(0 25);

        span {
            top: 0;
        }
    }

    &--positive,
    &--negative {
        background: $s-teal-light;
        border: 0;
        width: auto;
        padding: rem-calc(0 20 0 25);

        #{$crisis-web} & {
            background: $s-brand-tint;

            &:hover {
                background: $s-brand;
            }
        }

        &:disabled {
            background: $s-gray-light;
            color: lighten($s-black, 30%);
            cursor: default;
        }
    }

    &--positive {
        
        span {

            &::after {
                content: "\f164";
                font-family: $s-font-icon;
                font-size: rem-calc(15);
                font-weight: 500;
                position: relative;
                margin-left: rem-calc(5);
            }
        }
    }

    &--negative {

        span {

            &::after {
                content: "\f165";
                font-family: $s-font-icon;
                font-size: rem-calc(15);
                font-weight: 500;
                position: relative;
                top: rem-calc(2);
                margin-left: rem-calc(5);
            }
        }
    }

    &--plus {

        span {

            &::after {
                content: "+";
                font-family: $s-font-family-alt;
                font-size: rem-calc(20);
                margin-left: rem-calc(7);
                position: relative;
                top: rem-calc(1);
            }
        }

        #{$using-keyboard} & {

            &:focus {

                span {

                    &::after {
                        background: none !important;
                    }
                }
            }
        }
    }

    &--minus {

        span {

            &::after {
                content: "−";
                font-family: $s-font-family-alt;
                font-size: rem-calc(20);
                margin-left: rem-calc(7);
                position: relative;
                top: rem-calc(1);
            }
        }
    }

    &--right {

        span {

            &::after {
                content: "";
                background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                display: inline-block;
                width: rem-calc(12);
                height: rem-calc(11);
                font-size: rem-calc(20);
                margin-left: rem-calc(3);
                position: relative;

                @include breakpoint(medium) {
                    transition: all .3s ease 0s;
                }
            }
        }

        &:hover {
            background: $s-blue;

            span {

                &::after {
                    background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat;
                }
            }
        }

        #{$using-keyboard} & {

            &:focus {

                span {

                    &::after {
                        background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
                    }
                }
            }
        }
    }

    &--small {
        height: rem-calc(30);
        border-radius: rem-calc(30);
        padding: rem-calc(0 20);
        width: auto;
        min-width: auto;
    }

    #{$using-keyboard} & {

        &:focus {
            
            span::after {
                background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat;
            }
        }
    }
}