.isyCase-container{
    position: relative;

    #mapdiv {
        
        max-width: 70vw !important;
        @media screen and (max-width: 1023px) {
            width: 90vw !important;
            max-width: 100% !important;
        }

        @include breakpoint(381px down)
        {
            height: rem-calc(320) !important;
        }
    }

    .isyCase-button {
        cursor: pointer;
        position: absolute;
        top: 7%;
        right: 4%;
        z-index: 2;
        background-color: #FFFFFF;
        width: rem-calc(45);
        height: rem-calc(45);
    
        @include breakpoint(381px down)
        {
            top: 10%;
        }
    
        span {
    
            &::after {
                content: "\f0b2";
                display: block;
                font-family: $s-font-icon;
                font-size: rem-calc(30);
                font-weight: 700;
                color: black;
            }
        }

        &__hidden{
            display: none;
        }
    }
}

.isyCase-fullscreen {
    background: rgba(0, 0, 0, 0.3) ;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    #mapdiv{
        max-width: 100vw !important;
        position: fixed;
        opacity: 100%;
        z-index: 1;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
        width: 90% !important;
        height: 90% !important;
    }

    .isyCase-button-close {
        cursor: pointer;
        position: absolute;
        top: 10%;
        right: 10%;
        z-index: 2;
        background-color: #FFFFFF;
        width: rem-calc(45);
        height: rem-calc(45);
    
        @include breakpoint(small down)
        {
            right: 12%;
            top: 7%;
        }
    
        span {
    
            &::after {
                content: "\f00d";
                font-family: $s-font-icon;
                font-size: rem-calc(30);
                font-weight: 700;
                color: black;
            }
        }
    }
}

.isyCase-container-list{
    position: relative;

    .isyCase-button-list {
        cursor: pointer;
        position: absolute;
        top: 20%;
        left: 3%;
        z-index: 2;
        background-color: #FFFFFF;
        width: rem-calc(35);
        height: rem-calc(35);
    
        @include breakpoint(381px down)
        {
            top: rem-calc(32);
        }
    
        span {
    
            &::after {
                content: "\f0b2";
                display: block;
                font-family: $s-font-icon;
                font-size: rem-calc(20);
                font-weight: 700;
                color: black;
            }
        }

        &__hidden{
            display: none;
        }
    }

    >div {
        @media screen and (max-width: 360px) {
          width: rem-calc(280) !important;
        }
    }
}

.isyCase-fullscreen-list { 
    background: rgba(0, 0, 0, 0.3) ;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    >div {
        position: fixed;
        opacity: 100%;
        z-index: 1;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
        width: 90% !important;
        height: 90% !important;
    }

    .isyCase-button-close-list {
        cursor: pointer;
        position: absolute;
        top: 10%;
        right: 10%;
        z-index: 2;
        background-color: #FFFFFF;
        width: rem-calc(45);
        height: rem-calc(45);
    
        @include breakpoint(small down)
        {
            right: 12%;
            top: 7%;
        }
    
        span {
    
            &::after {
                content: "\f00d";
                font-family: $s-font-icon;
                font-size: rem-calc(30);
                font-weight: 700;
                color: black;
            }
        }
    }
}