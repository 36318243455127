.c-list-item {
    $c-list-item: '.c-list-item';
    border-bottom: rem-calc(2) solid $s-gray-light;
    padding: rem-calc(20 0 25 0);
    position: relative;

    &__outer {
        @include xy-grid();
        position: relative;

        &::before {
            content: "";
            width: rem-calc(40);
            height: rem-calc(40);
            border-radius: rem-calc(40);
            position: absolute;
            top: 0;
            left: 0;
    
            @include breakpoint(medium) {
                top: rem-calc(-5);
                left: rem-calc(-64);
            }
        }
    }

    &:last-child {
        border: 0;
    }

    &--news {

        #{$c-list-item}__outer::before {

            @include icon-news($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-news($size: 40, $font-size: 18);
            }
        }

    }

    &--page {

        #{$c-list-item}__outer::before {
            @include icon-page($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-page($size: 40, $font-size: 18);
            }
        }
    }

    &--doc {

        #{$c-list-item}__outer::before {
            @include icon-doc($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-doc($size: 40, $font-size: 18);
            }
        }
    }

    &--event {

        #{$c-list-item}__outer::before {
            @include icon-event($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-event($size: 40, $font-size: 18);
            }
        }
    }

    &--web {

        #{$c-list-item}__outer::before {
            @include icon-services($size: 30, $font-size: 16);

            @include breakpoint(medium) {
                @include icon-services($size: 40, $font-size: 18);
            }
        }
    }

    &__link {
        color: $s-black;
        position: relative;
        
        &:hover, &:focus {
            color: $s-black;
            border-bottom: rem-calc(2) solid $s-black;
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
            background-position-y: rem-calc(10);
            width: rem-calc(16);
            height: rem-calc(21);
            position: absolute;
        }
    }

    &__heading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        padding-left: rem-calc(40);

        @include breakpoint(medium) {
            padding: 0;
        }
    }

    &__intro {
        @include xy-cell(12,$gutters:0);

        @include breakpoint(large) {
            @include xy-cell(8,$gutters:0);
        }
    }

    &__info {
        @include xy-cell(12,$gutters:0);
        display: flex;
        flex-direction: column;
        margin: rem-calc(10 0 0 0);

        @include breakpoint(large) {
            margin: 0;
            text-align: right;
            @include xy-cell(4,$gutters:0);
        }
    }

    &__date {
        @include tag-date();
        margin-bottom: rem-calc(5);
    }

    &__category {
        @include tag-category();
    }

    &__doc-list {
        list-style-type: none;
        display: flex;
        margin: rem-calc(20 0 15 0);
        padding: 0;

        @include breakpoint(medium) {
            margin: rem-calc(10 0 0 0);
        }
    }

    &__doc-link {
        font-size: rem-calc(15);
        font-weight: $s-font-bold;
        font-family: $s-font-family-alt;
        position: relative;
        padding-left: rem-calc(35);

        &::before {
            content: '';
            background: url(/static/assets/images/icon-arrow-down-blue.svg) no-repeat center $s-teal-light;
            background-size: rem-calc(12);
            border-radius: rem-calc(28);
            width: rem-calc(28);
            height: rem-calc(28);
            position: absolute;
            top: rem-calc(-3);
            left: 0;
        }

        span {
            position: relative;
        }

        &:hover {
            color: $s-blue;

            &::before {
                background: url(/static/assets/images/icon-arrow-down-white.svg) no-repeat center $s-blue;
            }

            span {

                &::after {
                    @include link-underline($s-blue);
                }
            }
        }
    }

    &__doc-item {
        margin: rem-calc(0 30 0 0);
    }
}

.c-troman__list {
    margin-bottom: rem-calc(30);
    .title {
        font-weight: bold;
        margin-bottom: 0;
    }

    &--item {
        // margin: rem-calc(8 0);
        padding: rem-calc(15);
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        &:nth-child(odd){
            background-color: $s-gray-silver;
        }

        span {
            padding-right: rem-calc(10);
            &:first-child(){
                min-width: 40px;
            }
            &:last-child(){
                padding-left:40px;
                width: 100%;
            }
        }
    }
}
