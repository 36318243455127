.p-services {
  $p-services: '.p-services';
  @include gradient-bg();

  @include breakpoint(large) {
    padding-top: rem-calc(60);
  }

  &__outer {

    &:first-of-type {

      #{$p-services}__block-area {
        margin-top: rem-calc(10);

        @include breakpoint(medium) {
          margin-top: rem-calc(20);
        }

        @include breakpoint(large) {
          margin-top: rem-calc(40);
        }
      }
    }

    &:last-of-type {

      @include breakpoint(medium) {
        border-top: rem-calc(2) solid $s-gray-light;
        padding-top: rem-calc(30);
      }

      @include breakpoint(large) {
        padding-top: rem-calc(60);
      }
    }
  }

  &__inner {
    @extend .c-page-grid;
  }

  &__content {
    @extend .c-page-grid__outer;
    flex-direction: row-reverse;

    @include breakpoint(medium down) {
      flex-direction: column;
    }
  }

  &__block-area {
    @extend .c-page-grid__outer;
    @include flex-align(center);
    padding-top: rem-calc(15);

    @include breakpoint(medium) {
      padding-top: 0;
    }
  }

  .img {
    width: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: rem-calc(170);

    @include breakpoint(medium) {
      height: rem-calc(300);
      
    }

    @include breakpoint(large) {
      height: rem-calc(350);
    }

    @include breakpoint(medium down) {
        width: 100%;
    }
  }

  .c-hero {
    &--login {
      width: 55%;
      margin: unset;
      padding: unset;
      background-color: $s-gray;

      @include breakpoint(medium down) {
        width: 100%;
        min-height: unset;
      }
    }

    &__intro {
      justify-content: unset;
      align-items: unset;
      padding: rem-calc(48 56 56 56);
      text-align: unset;

      @include breakpoint(small down) {
        padding: 0.9375rem;
        min-height: unset;
      }
    }

    &__login {
      margin: 0;
      padding: 0;
    }

    &__heading {
      font-size: rem-calc(28);
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: rem-calc(32);
    }

    &__label {
      font-size: rem-calc(18);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: rem-calc(16);
      text-transform: unset;
    }

    &__heading, &__label {
      text-align: start;
      display: block;
      text-shadow: unset;
      color: black;
    }

    &__label::before, &__label::after {
      display: none;
    }

    &__item {
      width: unset !important;
      flex-grow: unset;
      margin-bottom: 10px !important;

      @include breakpoint(medium down) {
        min-width: unset;
        margin: 0 0.3125rem;

        &:first-child {
          margin: 0 0.3125rem 0 0;
        }
      }

      a {
        width: unset !important;
      }
    }

    &__list {
      justify-content: unset;

      @include breakpoint(medium down) {
        flex-direction: row;
      }
    }

    &__link {
      height: 100%;
      font-size: rem-calc(15);
      font-style: normal;
      font-weight: 700;
      line-height: rem-calc(24);
      padding: rem-calc(8 25);
    }
  }
}


#sirius_message_list_toolbar,
#sirius_message_view_toolbar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem-calc(30);

    .sirius_message_list_toolbar_div,
    .sirius_message_view_toolbar_div {
        margin-right: rem-calc(15);
        margin-bottom: rem-calc(15);
    }

    // Add message to before buttons, and make it span 100% width
    #sirius_message_list_toolbar_msginfo {
        order: -1;
        flex-basis: 100%;
    }
}

#sirius_message_reply_lower_toolbar {
    display: flex;
    flex-wrap: wrap;
}

#sirius_message_list_toolbar_msgindex_decrease,
#sirius_message_list_toolbar_msgindex_increase,
#sirius_message_reply_toolbar_cancel,
#sirius_message_reply_toolbar_return,
#sirius_message_reply_subject_subject,
#sirius_message_reply_add_attachment_submit,
#sirius_message_reply_lower_toolbar_send,
#sirius_message_reply_lower_toolbar_save {
    margin-right: rem-calc(15);
    margin-bottom: rem-calc(15);
}

.sirius_message_list_toolbar_delete_button_submit,
.sirius_message_list_toolbar_read_button_submit,
.sirius_message_list_toolbar_unread_button_submit,
.sirius_message_list_toolbar_undo_button_submit,
.sirius_message_list_toolbar_msgindex_decrease_button_submit,
.sirius_message_list_toolbar_msgindex_increase_button_submit,
.sirius_message_view_toolbar_delete_button_submit,
.sirius_message_view_toolbar_reply_button_submit,
.sirius_message_reply_toolbar_cancel_button_submit,
.sirius_message_reply_add_attachment_another_submit,
.sirius_message_reply_lower_toolbar_send_button_submit,
.sirius_message_reply_lower_toolbar_save_button_submit,
.sirius_settings_save_submit {
    @extend .c-button;
    @extend .c-button--small;
}

input.sirius_message_list_checkbox {
    float: left;
    margin: rem-calc(0 10 0 0);
}

.sirius_message_list_from {
    line-height: 1;
}

#sirius_message_view_toolbar_return_link {
    display: flex;
    padding-left: rem-calc(25);
    margin-right: rem-calc(25);
    position: relative;

    &:before {
        content: "";
        background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat bottom right;
        background-position-x: 0.3125rem;
        position: absolute;
        left: 0;
        transform: rotate(180deg);
        width: 1.25rem;
        height: 0.6875rem;
        bottom: 0.4375rem;
    }
}

#sirius_messages {

    .sirius_message_view_attachment:last-child {
        margin-bottom: 1.25rem;
    }

}

#sirius_messages_folders {
    @include editor-ul-list();
    list-style: none;
}

#sirius_message_folders {
    display: flex;
    list-style-type: none;
    margin-bottom: rem-calc(30);
    margin-left: 0;
}

.sirius_message_folder {
    list-style-type: none;
    position: relative;
    padding-left: 1.5625rem;

    &:first-of-type {
        padding-left: 0;
    }

    a {
        color: $s-black;

        &:hover {
            border-bottom: rem-calc(2) solid $s-black;
        }
    }

    .sirius_message_folder_link_a_selected {
        border-bottom: rem-calc(2) solid $s-black;
        font-weight: bold;
    }
}

#sirius_message_lista {
    list-style: none;
    margin-left: 0;
}

.sirius_message_list_row_li {
    border-bottom: rem-calc(2) solid $s-gray-light;
    margin-bottom: rem-calc(15);
}


.sirius_settings_notify {
    display: flex;
    align-items: center;
}

.sirius_settings_notify_email_checkbox,
.sirius_settings_notify_sms_checkbox,
.sirius_settings_checkbox {
    float: left;
    margin: rem-calc(0 10 0 0);
}

.sirius_message_label {
    font-weight: bold;
}

.sirius_message_list_column {

    &.sirius_message_list_column_attachment {
        display: none;
    }

}
