.c-sitemap {
    $item: '.c-sitemap__item';

    margin-top: rem-calc(15);
    padding-top: rem-calc(15);
    border-top: rem-calc(2) solid $s-gray-light;

    @include breakpoint(large) {
        margin-top: 0;
        padding-top: rem-calc(60);
    }

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
    }

    &__content {
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &--level-1 {

            #{$item} {
                font-size: rem-calc(20);
                line-height: rem-calc(36);
            }
        }

        &--level-2 {
            padding-left: rem-calc(15);

            @include breakpoint(large) {
                padding-left: rem-calc(30);
            }

            #{$item} {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }

        &--level-3 {
            padding-left: rem-calc(15);

            @include breakpoint(large) {
                padding-left: rem-calc(30);
            }

            #{$item} {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }

        &--level-4 {
            padding-left: rem-calc(15);

            @include breakpoint(large) {
                padding-left: rem-calc(30);
            }

            #{$item} {
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }
    }

    &__item {
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;
    }

    &__link {
        color: $s-black;
        display: block;
        padding: rem-calc(12 0);
        border-bottom: rem-calc(1) solid $s-gray-light;

        &:hover, 
        &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);

                padding-bottom: 0;
            }
        }

        &:after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            width: rem-calc(14);
            height: rem-calc(11);
            position: relative;
            display: inline-block;
            margin-left: rem-calc(5);
        }

        span {
            display: inline;
        }
    }
}