.rounded-button {
  border-radius: rem-calc(200);
  min-width: unset;
  width: unset !important;
  padding: 10px 24px 10px 27px;
  max-width: fit-content !important;
  margin: auto;
  line-height: unset;
  height: unset;
  &{
      font-size: 16px;
  }
}
