.p-landing {
    $p-landing: '.p-landing';
    
    &__outer {
        
        &:first-of-type {
            border-bottom: rem-calc(2) solid $s-gray-light;
            margin-bottom: rem-calc(15);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(30);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(50);
            }
        }
    }
    
    &__inner {
        @extend .c-page-grid;
    }

    &__block-area {
        @extend .c-page-grid__outer;
    }

    &__content {
        @extend .c-page-grid__outer;

        #{$p-landing} {

            &__sidenav {
                @extend .c-page-grid--two-cols__side;
            }

            &__block-area {
                @extend .c-page-grid--two-cols__main;
                display: block;
            }

            &__wrapper {
                @extend .c-page-grid__outer;
            }
        }
    }
}