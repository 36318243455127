html {
  overflow-x: hidden;
}

body {
  font-family: $s-font-family;
  background: $s-white;

  button {

    &:focus {
      outline: none;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    top: rem-calc(255);
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.60);
    z-index: 9;
  }
}
