.c-progress-bar{
    &__bar {
        display: block;
        position: relative;
        width: 100%;
        height: rem-calc(18);
        background: $s-gray;
        border-radius: rem-calc(20);
        margin: rem-calc(15 0 10);
        overflow: hidden;
    }
    &__progress {
        width: 0%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: rem-calc(20);
        background: $s-blue;
        transition: all 0.5s ease;
    }
    &__status {
        margin: rem-calc(20 0 0);
        font-size: rem-calc(12);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: rem-calc(1);
    }
}