/* Different layout on Landing Page, Block - Half width */

.c-list-links {
  $c-block-grid-half: '.c-block-grid--half';
  $p-landing: '.p-landing';
  $using-keyboard: '.using-keyboard';
  height: 100%;
  padding-bottom: rem-calc(15);
  @include xy-grid();
  @include xy-gutters($negative: true);
  @include flex-align(center);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    padding-bottom: rem-calc(60);
  }

  &__outer {
    @include xy-cell(12);
    background: lighten($s-gray, 2%);
    padding: rem-calc(20 15 15 15);
    height: 100%;
    @include xy-cell(12);

    @include breakpoint(medium) {
      padding: rem-calc(20 30);
    }

    @include breakpoint(large) {
      @include xy-cell(8);
      padding: rem-calc(30 50 25 50);
      @include xy-cell(8);
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include breakpoint(large) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: rem-calc(10 0 10 18);
    position: relative;
    border-top: rem-calc(2) solid $s-gray-light;

    &:first-child {
      border: 0;
    }

    @include breakpoint(medium) {
      padding: rem-calc(15 0 15 18);
    }

    @include breakpoint(large) {
      width: calc(50% - 15px);

      &:nth-child(odd) {
        margin-right: rem-calc(30);
      }

      &:nth-child(odd) {
        border-top: rem-calc(2) solid $s-gray-light;
        margin-top: rem-calc(-2);
      }

      &:nth-child(even) {
        border-bottom: rem-calc(2) solid $s-gray-light;
        border-top: 0;
        margin-top: rem-calc(2);
      }

      &:first-child {
        border-top: 0;
      }

      &:nth-child(2) {
        border-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &::before {
      content: "";
      background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
      display: block;
      width: rem-calc(12);
      height: rem-calc(11);
      position: absolute;
      left: 0;
      top: rem-calc(18);

      @include breakpoint(medium) {
        top: rem-calc(22);
      }
    }
  }

  &__heading {
    font-size: rem-calc(20);
    line-height: rem-calc(30);

    @include breakpoint(medium) {
      font-size: rem-calc(28);
      line-height: rem-calc(42);
    }
  }

  &__link {
    color: $s-black;
    position: relative;
    display: inline-block;
    padding-right: rem-calc(16);

    &:hover, &:focus {

      span {
        color: $s-black;
        @include link-underline($s-black);
      }
    }

    &[href^="https://"]:not([href*="www.sollentuna.se"]),
    &[href^="http://"]:not([href*="www.sollentuna.se"]) {

      &::after {
        @include external-link-item();
      }
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;

        span {
          background: $s-yellow;
        }
      }
    }
  }
  /* Different layout on Landing Page */
  #{$p-landing} & {

    &__outer {

      @include breakpoint(large) {
        @include xy-cell(12);
        padding: rem-calc(25 40 25 40);
      }
    }
  }
  /* Different layout in Block - Half Width */
  #{$c-block-grid-half} & {

    &__outer {

      @include breakpoint(large) {
        @include xy-cell(12);
      }
    }

    &__item {
      width: 100%;
      border-top: rem-calc(2) solid $s-gray-light;

      &:first-child {
        border: 0;
      }

      &:nth-child(odd) {
        margin-right: 0;
      }

      &:nth-child(2) {
        border-top: rem-calc(2) solid $s-gray-light;
      }
    }
  }

 

}
