$searchpage: ".p-search";
$buildingpermitlisting: ".p-permit-listing__search";
.c-search-form {
    $using-keyboard: ".using-keyboard";
    @include xy-grid-container;
    @extend .c-page-header;
    padding-top: 0;
    #{$buildingpermitlisting} & {
        padding: rem-calc(30 0 0);
    }

    @include breakpoint(medium) {
        padding-top: rem-calc(20);
    }

    @include breakpoint(large) {
        padding-top: rem-calc(80);
        #{$buildingpermitlisting} & {
            padding-top: rem-calc(40);
        }
    }

    &__outer {
        @extend .c-page-grid__outer;
        @include flex-align(center);
        #{$buildingpermitlisting} & {
            @extend .c-page-grid__outer;
            justify-content: flex-start;
        }
    }

    &__inner {
        position: relative;
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(10);
            #{$buildingpermitlisting} & {
                @include xy-cell(12);
            }
        }

        @include breakpoint(large) {
            @include xy-cell(6);
            #{$buildingpermitlisting} & {
                @include xy-cell(12);
                max-width: rem-calc(610);
            }
        }
    }

    &__input {
        @extend .c-form__input;

        @include breakpoint(medium) {
            height: rem-calc(60);
            padding: rem-calc(0 20);
            border-radius: rem-calc(3);
        }

        &:focus {
            background: none !important;
        }

        #{$using-keyboard} & {
            &:focus {
                outline: rem-calc(3) solid $s-yellow !important;
                background: none;
            }
        }
        .p-permit-listing__search & {
            width: calc(100% - 100px);
            margin-bottom: 0;
            border: none;
        }
    }

    &__form {
        position: relative;
        margin-bottom: rem-calc(10);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(30);
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(60);
        }
        #{$buildingpermitlisting} & {
            margin-bottom: 0;
        }
        .p-permit-listing__search & {
            border: rem-calc(2) solid $s-gray-border;
            border-radius: rem-calc(2);
            box-shadow: none;
            margin-bottom: rem-calc(5);
        }
    }

    &__button {
        position: absolute;
        right: 0;
        top: 0;
        font-size: rem-calc(16);
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;
        height: 100%;
        padding: rem-calc(0 10);

        @include breakpoint(medium) {
            font-size: rem-calc(20);
            padding: rem-calc(0 20);
        }

        span {
            position: relative;
        }

        &:hover {
            cursor: pointer;

            span {
                &::after {
                    @include breakpoint(large) {
                        @include link-underline($s-black);
                        bottom: rem-calc(-2);
                    }
                }
            }
        }

        &::before {
            content: "\f002";
            font-family: $s-font-icon;
            font-weight: $s-font-semibold;
            margin-right: rem-calc(3);
            position: relative;
            top: rem-calc(1);
        }
    }

    &__label {
        font-weight: bold;
        #{$searchpage} & {
            @include element-invisible;
        }
        #{$buildingpermitlisting} & {
            position: absolute;
            bottom: 100%;
            left: 0;
            margin-bottom: rem-calc(5);
        }
    }

    &__autocomplete {
        z-index: 9999;
        background: $s-white;
        position: absolute;
        width: 100%;
        top: rem-calc(39);
        padding: rem-calc(0 10);
        border: rem-calc(2) solid $s-gray-light;
        display: none;

        @include breakpoint(medium) {
            top: rem-calc(60);
            padding: rem-calc(0 20);
            border-radius: rem-calc(3);
        }

        ul {
            margin: 0;
            list-style: none;

            li {
                cursor: pointer;
                padding: rem-calc(10 0);
                border-top: rem-calc(2) solid $s-gray-light;

                @include breakpoint(medium) {
                    padding: rem-calc(20 0);
                }

                &:first-child {
                    border: 0;
                }

                span {
                    display: block;
                }
            }
        }
    }
}

.p-permit-result {
    display: none;
    ul {
        list-style-type: none;
        margin-bottom: 0rem;
        margin-left: 0rem;
        li {
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $s-gray-light;
            cursor: pointer;
        }
        li:last-child {
            padding: 0.5rem 1rem;
            border-bottom: none;
        }
    }
}

.p-permit-result-show {
    display: block;
    border-top: none !important;
    margin-top: -7.1px;
}
