.c-form {
    $using-keyboard: '.using-keyboard';

    &__row {
        margin-bottom: rem-calc(20);
    }

    &__required {
        position: relative;
        top: rem-calc(-4);
    }

    &__textarea,
    &__input {
        border: rem-calc(2) solid $s-gray-border;
        border-radius: rem-calc(2);
        box-shadow: none;
        margin-bottom: rem-calc(5);

        &:focus {
            border: rem-calc(2) solid $s-black;
            box-shadow: none;
            background: $s-white !important;
        }

        &:disabled {
            background: $s-gray;
            border-color: $s-gray-light;
            box-shadow: none;
        }
    }

    &__textarea {
        box-shadow: none;
    }

    &__select {
        border: rem-calc(2) solid $s-gray-border;
        border-radius: rem-calc(2);
        box-shadow: none;
        height: rem-calc(40);
        line-height: rem-calc(26);
        padding-top: 0;
        padding-bottom: 0;

        &:focus {
            border: rem-calc(2) solid $s-black;
            box-shadow: none;
        }
    }

    &__label {
        font-weight: $s-font-bold;
        font-size: rem-calc(16);
        margin-bottom: rem-calc(5);
    }

    &__error {
        color: $s-orange;

        &::before {
            content: "\f06a\00a0";
            font-weight: 600;
            font-family: $s-font-icon;
        }
    }
}