.c-shortcut-links {
    background: lighten($s-gray, 2%);
    padding: rem-calc(20 0);
    margin-bottom: 0;
    @include xy-cell(12, $gutters:0);

    @include breakpoint(medium) {
        background: none;
        padding: 0;
        margin-bottom: rem-calc(30);
        padding: rem-calc(30 0 0 0);
        @include xy-cell(12);
    }
    
    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
        padding: 0;
    }

    &__outer {
        @include xy-grid();
        @include flex-align(center);
    }

    &__inner {
        @include xy-cell(12);
        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }

    &__heading {
        font-size: rem-calc(24);

        @include breakpoint(medium) {
            text-align: center;
        }

        @include breakpoint(large) {
            font-size: rem-calc(32);
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include xy-grid();

        @include breakpoint(medium) {
            @include xy-gutters($negative: true);
        }
    }

    &__item {
        margin-bottom: rem-calc(2);
        display: flex;
        align-content: center;
        display: flex;
        align-items: center;
        padding: rem-calc(10 0);
        @include xy-cell(12, $gutters:0);

        @include breakpoint(medium) {
            background: lighten($s-gray, 2%);
            padding: rem-calc(18 20);
            @include xy-cell(6, $gutters:2);
        }
    }

    &__link {
        position: relative;
        padding-left: rem-calc(18);
        color: $s-black;

        &:hover, &:focus {
            color: $s-black;
        }

        &::before {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
            display: block;
            width: rem-calc(12);
            height: rem-calc(11);
            position: absolute;
            left: 0;
            top: rem-calc(7);
        }

        span {
            position: relative;
            
            &:hover, &:focus {

                &::after {
                    @include link-underline($s-black);
                }
            }
        }
    }
}