.c-icon-list {
    
    &__list {
        list-style-type: none;
        margin: rem-calc(0 0 20 0);
        padding: 0;
    }

    &__extend {
        display: flex;
        justify-content: center;
    }
}