.c-latest-jobs {
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }

    &__heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        max-width: 100%;
        margin-bottom: rem-calc(30);

        &::before,
        &::after {
            content: "";
            background: $s-gray-light;
            height: rem-calc(2);
            width: 100%;
            display: block;
        }

        span {
            flex: 0 0 auto;
            padding: rem-calc(0 10);
            @include breakpoint(450px) {
                padding: rem-calc(0 30);
            }
        }
    }

    &__subheading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        @include xy-grid();
        @include xy-gutters($negative: true);
    }

    &__item {
        @include xy-cell(12);
        background: $s-orange-light;
        padding: rem-calc(15 15 20 15);
        margin-bottom: rem-calc(15);

        &:last-of-type {
            margin-bottom: 0;
        }

        @include breakpoint(medium) {
            padding: rem-calc(20 30 30 30);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
            margin-bottom: 0;
        }

        &:nth-child(2) {
            background: $s-teal-light;
        }

        &:nth-child(3) {
            background: $s-pink-light;
        }

        p {
            margin-bottom: 0;
        }
    }

    &__info {
        margin-bottom: rem-calc(5);
    }

    &__link {
        color: $s-black;
        font-family: $s-font-family-alt;
        font-weight: $s-font-bold;
        margin-bottom: rem-calc(20);
        position: relative;
        display: inline;

        &:hover, &:focus {
            color: $s-black;

            span {
                border-bottom: rem-calc(2) solid $s-black;
                position: relative;
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
            background-position-y: rem-calc(10);
            width: rem-calc(16);
            height: rem-calc(21);
            position: absolute;
        }
    }

    &__published {
        @include tag-date();
    }

    &__date {
        @include tag-category();
        display: inline-block;
    }
}