$button-margin: 5;
$extend-button: ".c-permit-filter__extend";
$applied-filters: ".c-permit-filter__applied";
.c-permit-filter {
    &__label {
        @extend label;
        font-weight: bold;
        padding-bottom: rem-calc(5);
        display: block;
        #{$applied-filters} & {
            line-height: rem-calc(50);
            padding-bottom: 0;
            white-space: nowrap;
            width: 100%;
            @include breakpoint(medium) {
                width: auto;
            }
        }
    }
    &__outer {
        //max-height: rem-calc(51);
        overflow: hidden;
        transition: all 0.3s ease 0s;
        overflow-x: auto;
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
        padding: rem-calc(0 15);
        @include breakpoint(medium) {
            padding: 0;
            margin: 0;
            overflow-x: hidden;
        }
        #{$using-keyboard} & {
            overflow: auto;
        }
        &--expanded {
            max-height: rem-calc(300);
            overflow: auto;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__inner {
        margin-left: rem-calc(-$button-margin);
        margin-right: rem-calc(-$button-margin);
        display: flex;
        min-width: min-content;
        flex-wrap: wrap;
        @include breakpoint(medium) {
            flex-wrap: wrap;
            min-width: none;
        }
    }
    &__button {
        @extend .c-button;
        min-width: rem-calc(1);
        padding: rem-calc(0 17);
        border-radius: rem-calc(100);
        white-space: nowrap;
        margin: rem-calc($button-margin);
        width: auto;
        border: unset;
        color:  $s-blue;
        background: $s-teal;
        &--active {
            color: $s-white;
            background: $s-blue;
            &:after {
                #{$applied-filters} & {
                    font-family: $s-font-icon;
                    content: "\f057";
                    margin-left: rem-calc(5);
                }
            }
        }
    }
    &__extend {
        color: $s-blue;
        cursor: pointer;
        margin-top: rem-calc(20);
        display: none;
        &--show {
            @include breakpoint(medium) {
                display: block;
            }
        }
        &--expanded {
            display: none;
        }
    }
    &__extend-text {
        @include link-underline($s-blue);
        #{$extend-button}:hover & {
            border-bottom: none;
        }
    }
    &__extend-icon {
        margin-left: rem-calc(10);
    }
    &__applied {
        display: none;
        align-items: flex-start;
        background: $s-gray;
        padding: rem-calc(10 20);
        margin-top: rem-calc(30);
        flex-wrap: wrap;
        display: none;
        &--show {
            display: flex;
        }
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }
    &__clear-filters {
        height: rem-calc(50);
        line-height: rem-clac(50);
        width: 100%;
        text-align: right;
        cursor: pointer;
        @include breakpoint(medium) {
            width: auto;
        }
        &::before {
            content: "\f057";
            font-family: $s-font-icon;
            font-size: rem-calc(14);
            margin-right: rem-calc(5);
            color: $s-blue;
            font-weight: bold;
        }
    }
    &__clear-filters-text {
        color: $s-blue;
        @include link-underline($s-blue);
        white-space: nowrap;
        .c-permit-filter__clear-filters:hover & {
            border-bottom: 0;
        }
    }
    &__remove-filters {
        flex-grow: 1;
        display: inline-flex;
        flex-wrap: wrap;
        margin-left: rem-calc(-$button-margin);
        margin-right: rem-calc(-$button-margin);
        @include breakpoint(medium) {
            padding: rem-calc(0 15);
        }
    }
}
