.c-panic-button {
    background: $s-warning;
    height: rem-calc(75);
    width: 100%;
    z-index: 999;
    position: sticky;
    top: 0;
    @include breakpoint(medium) {
        height: rem-calc(100);
    }

    &:hover {
        background: darken($s-warning, 2%);
    }

    &__link {
        color: $s-white;
        font-size: rem-calc(20);
        font-weight: $s-font-bold;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: none;
        height: 100%;

        &:hover, &:focus {
            color: $s-white;
        }

        span {

            &::after {
                content: "\00a0\00a0\f35d";
                font-family: $s-font-icon;
            }
        }
    }
}
