.c-logotypes {
  $c-logotypes: '.c-logotypes';

  &__outer {
    text-align: center;
  }

  &__preamble {
    padding: rem-calc(20 20);

    &::after {
      content: "";
      display: block;
      background: $s-gray-light;
      height: rem-calc(1);
      width: 100%;
      margin-top: rem-calc(20);
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    padding: rem-calc(20 0 0 0);

    @include breakpoint(large) {
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
    }

    img {
      width: rem-calc(116);
      height: rem-calc(64);
      object-fit: contain;
    }
  }

  &__item {
    flex: 50%;
    padding: rem-calc(0 0 40 0);

    @include breakpoint(large) {
      padding: 0;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      padding: 0;
    }
  }
}
