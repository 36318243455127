.c-information-block {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: rem-calc(15);
  background: $s-gray;
  @include breakpoint(medium) {
    flex-wrap: nowrap;
    padding: rem-calc(40 50);
  }
  .c-editor__link,
  .c-editor-files__list {
    background: $s-white;
  }
  &--icon {
    border-bottom: rem-calc(2) solid $s-gray-light;
    background: transparent;
    padding: rem-calc(15 0);
    .c-editor__link,
    .c-editor-files__list {
      background: $s-teal-light;
    }
  }
  &__icon-container {
    width: 100%;
    @include breakpoint(medium) {
      width: auto;
    }
  }
  &__icon {
    width: rem-calc(40);
    height: rem-calc(40);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $s-gray-light;
    margin-right: rem-calc(15);
    margin-bottom: rem-calc(10);
    &--fa-exclamation {
      background: $s-pink-light;
      color: $s-pink;
    }
    &--fa-tools {
      background: $s-teal-light;
      color: $s-blue;
    }
  }
  &__content {
    width: 100%;
  }
  &__heading {
    font-size: rem-calc(18);
    line-height: rem-calc(28);
    @include breakpoint(medium) {
      font-size: rem-calc(24);
      line-height: rem-calc(40);
    }
  }
  h3 {
    font-size: rem-calc(16);
    line-height: rem-calc(26);
    margin-bottom: 0;
    @include breakpoint(medium) {
      font-size: rem-calc(20);
      line-height: rem-calc(30);
    }
  }
  h4 {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    margin-bottom: 0;
  }
}
