.c-page-footer {
    $crisis-web: '.crisis-web';

    #{$crisis-web} & {
        width: 100%;
        padding: rem-calc(15 0 10 0);

        @include breakpoint(medium) {
            padding: rem-calc(15 0 15 0);
        }
    }
}