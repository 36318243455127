.c-jobs {
    $c-jobs: '.c-jobs';
    $p-jobs: '.p-jobs';
    margin-bottom: rem-calc(15);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(60);
    }

    &--all {

        #{$c-jobs}__panel {
            
            #{$c-jobs}__inner {

                @include breakpoint(small down) {
                    flex-direction: column;
                    align-items: normal;
                }
            }
        }
    }

    &__panel {
        background: $s-teal-light;
        margin: rem-calc(0 -15 20 -15);
        padding: rem-calc(15 0 15 0);

        @include breakpoint(medium) {
            height: rem-calc(100);
            margin: rem-calc(0 0 40 0);
            padding: 0;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                background: $s-teal-light;
                height: rem-calc(70);
                width: 100%;
                z-index: -9;
    
                @include breakpoint(medium) {
                    height: rem-calc(100);
                }
            }
        }

        @include breakpoint(large) {
            
            &::before {
                content: none;
            }
        }
        
        #{$c-jobs}__outer {
            @include xy-grid();
            @include xy-gutters($negative: true);
            justify-content: center;
            height: 100%;
            max-width: 100%;
        }

        #{$c-jobs}__inner {
            @include xy-cell(12);
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: rem-calc(15);
            padding-right: rem-calc(15);

            @include breakpoint(medium) {
                @include xy-cell(12);
                flex-direction: row;
                align-items: center;
                padding-left: 0;
                padding-right: 0;
            }

            @include breakpoint(large) {
                @include xy-cell(8);
            }
        }
    }

    &__filter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: rem-calc(10 0 10 0);

        @include breakpoint(medium) {
            align-items: center;
            flex-direction: row;
        }
    }

    &__all {
        font-weight: $s-font-bold;

        span {
            @include link-underline($s-blue);
        }
    }

    &__label {
        font-weight: $s-font-bold;
        font-size: rem-calc(16);
        margin-right: rem-calc(20);
    }

    &__select {
        @extend .c-form__select;
        margin-bottom: 0;
        width: 100%;

        @include breakpoint(medium) {
            width: rem-calc(290);
        }
    }

    &__wrapper {
        @include xy-grid();
        @include xy-gutters($negative: true);
        justify-content: center;
    }

    &__content {
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(12);
        }

        @include breakpoint(large) {
            @include xy-cell(8);
        }

        tbody {
            border: 0px solid #f1f1f1;
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(15 8 14 8);
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
        }

        &:last-child {
            border-bottom: 0;
        }

        td {
            padding: 0px;
        }
    }

    &__head {
        display: flex;
        border-bottom: rem-calc(2) solid $s-gray-light;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    &__heading {
        font-size: rem-calc(24);
        flex-grow: 1;
        margin: 0;
        max-width: none;

        @include breakpoint(large) {
            font-size: rem-calc(28);
        }
    }

    &__subheading {
        font-weight: $s-font-bold;
        margin-bottom: rem-calc(10);
        text-align: left;
        padding: 0px;

        &--title {
            @include breakpoint(medium) {
                flex: 60%;
            }
        }

        &--published,
        &--end {
            display: none;

            @include breakpoint(medium) {
                flex: 20%;
                display: block;
            }
        }

        &--end {
            max-width: rem-calc(96);
        }
    }

    &__title {
        position: relative;
        padding: 0rem;

        &::before {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
            display: block;
            width: rem-calc(12);
            height: rem-calc(11);
            position: absolute;
            left: rem-calc(0);
            top: rem-calc(7);
        }

        @include breakpoint(medium) {
            flex: 60%;
        }
    }

    &__link {
        display: inline-block;
        margin-left: rem-calc(18);

        span {
            color: $s-black;
        }

        &:hover, &:focus {

            span {
                @include link-underline($s-black);
            }
        }
    }

    &__published,
    &__end {
        padding: 0rem;
        @include breakpoint(medium) {
            flex: 20%;
        }
    }

    &__published {
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__end {

        @include breakpoint(medium) {
            max-width: rem-calc(96);
        }

        span {
            font-weight: $s-font-bold;

            @include breakpoint(medium) {
                display: none;
            }
        }
    }

    &__subscribe {
        color: $s-white;
        background: $s-blue;
        padding: rem-calc(0 15);
        margin: rem-calc(10 -15 20 -15);
        

        @include breakpoint(medium) {
            margin: rem-calc(20 0 0 0);
            padding: rem-calc(0 20);
            margin-left: calc(-50vw + 50%);
            margin-right: calc(-50vw + 50%);
        }

        @include breakpoint(large) {
            margin: rem-calc(20 0 0 0);
            padding: 0;
        }

        #{$c-jobs}__outer {
            @include xy-grid();
            @include xy-gutters($negative: true);
            @include flex-align(center);
            padding: rem-calc(20 0 30 0);
            
            @include breakpoint(large) {
                padding: rem-calc(40 0 50 0);
            }
        }
    }

    &__intro,
    &__actions {
        @include xy-cell(12);

        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }

    &__intro {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem-calc(10);
    }

    &__actions {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--right;
        margin: rem-calc(0 0 15 0);
        min-width: rem-calc(200);

        @include breakpoint(medium) {
            margin: rem-calc(0 15 0 0) !important;
        }

        @include breakpoint(large) {
            max-width: rem-calc(200);
            min-width: auto;
            width: 100%;
        }

        &:last-of-type {
            margin: rem-calc(12) auto 0 auto !important;
            @include breakpoint(medium) {
                margin: 0 !important;
            }
        }

        &:hover {
            background: $s-teal-light;
            color: $s-blue;

            span {

                &::after {
                    background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                }
            }
        }

        span {

            &::after {
                background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat;
            }
        }

        &--login {
            background: $s-teal-light;
            border-color: $s-teal-light;
           
            &:hover {
                background: $s-teal-light;
                border-color: $s-teal-light;
            }

            span {

                &::after {
                    background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                }
            }
        }

        &--subscribe {
            border-color: $s-teal-light;
            color: $s-white;
        }
    }

    #{$p-jobs} & {

        &__panel {

            &::before {
                content: "";
                position: absolute;
                left: 0;
                background: $s-teal-light;
                height: rem-calc(100);
                width: 100%;
                z-index: -9;
            }
        }

        &__subscribe {

            @include breakpoint(large) {
                margin-left: calc(-50vw + 50%);
                margin-right: calc(-50vw + 50%);
            }
        }
    }
}
