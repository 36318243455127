.c-contact {
    $c-contact: '.c-contact';
    $c-editor: '.c-editor';
    $using-keyboard: '.using-keyboard';
    max-width: rem-calc(1440);
    margin: 0 auto;
    &__outer {
        &--top {
            background: $s-teal-light;
            display: flex;
            align-items: center;
            margin-top: rem-calc(15);
            padding: rem-calc(20 0 40 0);
            margin-top: 0;
            margin-bottom: rem-calc(16);

            @include breakpoint(large) {
                margin-bottom: rem-calc(40);
            }
    
            @include breakpoint(large) {
                height: rem-calc(120);
                padding: 0;
            }
            
            #{$c-contact}__inner {
                width: 100%;
            }

            #{$c-contact}__wrapper {
                @include xy-cell(12);

                @include breakpoint(medium) {
                    @include xy-cell(10);

                }

                @include breakpoint(large) {
                    @include xy-cell(12);
                }                    
            }
        }
    
        &--welcome {
            height: rem-calc(170);
            position: relative;
            overflow: hidden;
            @include flex;
            @include flex-align($y:middle);
            @include breakpoint(medium) {
                height: rem-calc(300);
            }
            @include breakpoint(large) {
                height: rem-calc(350);
            }
    
            #{$c-contact}__inner {
                width: 100%;
            }

            #{$c-contact}__wrapper {
               // @include xy-cell(12);
               // padding: rem-calc(25 20 15 20);
                background: $white;
                text-align: center;
                border-radius: rem-calc(4);

                @include breakpoint(medium) {
                   // padding: rem-calc(40 30 25 30);
                    //@include xy-cell(10);
                }

                @include breakpoint(large) {
                    padding: rem-calc(6 40);
                    //max-width: rem-calc(610);
                }

                h2, p {
                    max-width: 100%;
                    margin-bottom: unset;
                }

                .c-contact__heading {
                    display: none;
                    line-height: rem-calc(42);
                    margin-bottom: 0;
                    @include breakpoint(large) {
                        font-size: rem-calc(38);
                        display: block;
                    }
                }
            }
        }

        &--info {
            
            margin-top: rem-calc(27);

            @include breakpoint(large) {
                margin-bottom: rem-calc(30);
                margin-top: rem-calc(48);
            }


            #{$c-contact}__wrapper {
                @include xy-cell(12);
                @include xy-cell(12,$gutters:0);
                @include breakpoint(small) {
                    padding: rem-calc(30 15 20 15);
                }

                @include breakpoint(medium) {
                    @include xy-cell(10);
                    padding: rem-calc(25 30 20 30);
                }
        
                @include breakpoint(large) {
                    @include xy-cell(6);
                    padding: rem-calc(40 50);
                }
        
                &--bg {
                    background: $s-gray-silver;
                    border-radius: rem-calc(4);

                    #{$c-editor}__link {
                        background: $s-white;

                        #{$using-keyboard} & {

                            &:focus {
                                background: $s-white;
                            }
                        }
                    }
                }
            }
        }
    }
    #{$c-contact}__preamble {
        p{
                margin: 0 auto;
                font-size: rem-calc(18);
                font-style: normal;
                font-weight: 500;
                line-height: 160%;
                max-width: rem-calc(843);
                padding: 0 15px;
                text-align: start;
                @include breakpoint(medium){
                    font-size: rem-calc(20);
                    text-align: center;
                }
        }
    }
    &__inner {
        @include xy-grid-container;
    }

    &__content {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
          .c-contact__heading{
              font-size: rem-calc(28);
              margin-bottom: rem-calc(24);
              @include breakpoint(large){
                  display: none;
              }
          }
    }

    &__link {
        font-family: $s-font-family;
        font-size: rem-calc(16);
        color: $s-blue;
        margin: rem-calc(0 0 30 0);
        display: inline-block;
        align-items: center;
        position: relative;
        padding-left: rem-calc(40);
        @include breakpoint(large) {
            padding-left: rem-calc(45);
        }
        span{
          @include link-underline($s-blue);
        }
        &:last-of-type {
            margin: 0;
        }

        @include breakpoint(large) {
            margin: rem-calc(0 40 0 0);
        }

        &::before {
            font-family: $s-font-icon;
            color: $s-black;
            background: $s-white;
            height: rem-calc(30);
            width: rem-calc(30);
            border-radius: rem-calc(30);
            display: flex;
            margin-right: rem-calc(10);
            justify-content: center;
            align-items: center;
            position: absolute;
            top: rem-calc(2);
            left: 0;

            @include breakpoint(large) {
                top: rem-calc(-2);
                height: rem-calc(35);
                width: rem-calc(35);
                border-radius: rem-calc(35);
            }
        }

        &--tel {

            &::before {
                content: "\f3cd";
                font-size: rem-calc(18);

                @include breakpoint(large) {
                    font-size: rem-calc(18);
                }
            }
        }

        &--email {
            word-break: break-word;

            &::before {
                content: "\f0e0";
                font-weight: $s-font-regular;
                font-size: rem-calc(16);

                @include breakpoint(large) {
                    font-size: rem-calc(16);
                }
            }
        }

        &--report {

            &::before {
                content: "\f071";
                font-size: rem-calc(14);

                @include breakpoint(large) {
                    font-size: rem-calc(15);
                }
            }
        }

        &:hover {

            span {
                @include link-underline($s-blue);
            }
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        
        @include breakpoint(large) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    &__item {
        margin: rem-calc(0 0 22 0);

        &:last-child {
            margin: 0;
        }

        @include breakpoint(large) {
            margin: rem-calc(0 56 0 0);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
