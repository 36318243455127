$using-keyboard: '.using-keyboard';

/* cabin-regular - latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    src: url('/static/assets/fonts/google/cabin-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('/static/assets/fonts/google/cabin-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('/static/assets/fonts/google/cabin-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('/static/assets/fonts/google/cabin-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('/static/assets/fonts/google/cabin-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('/static/assets/fonts/google/cabin-v18-latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* cabin-700 - latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    src: url('/static/assets/fonts/google/cabin-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('/static/assets/fonts/google/cabin-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('/static/assets/fonts/google/cabin-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('/static/assets/fonts/google/cabin-v18-latin-700.woff') format('woff'), /* Modern Browsers */
            url('/static/assets/fonts/google/cabin-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('/static/assets/fonts/google/cabin-v18-latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/static/assets/fonts/google/source-sans-pro-v14-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* Inter - Regular */
@font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: 400;
    src: url('/static/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
    src: local(''),
         url('/static/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

/* Inter - Bold */
@font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: 600;
    src: url('/static/assets/fonts/Inter/static/Inter-Bold.ttf');
    src: local(''),
         url('/static/assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
}

h1, h2, h3, h4, h5 {
    font-family: $s-font-family-alt;
    font-weight: $s-font-bold;
    max-width: rem-calc(610);
}

h1 {
    font-size: rem-calc(28);
    line-height: 120%;
    margin-bottom: rem-calc(18);

    @include breakpoint(medium) {
        font-size: rem-calc(32);
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(38);
    }
}

h2 {
    font-size: rem-calc(24);
    line-height: 120%;
    margin-bottom: rem-calc(8);

    @include breakpoint(medium) {
        font-size: rem-calc(26);
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(28);
    }
}

h3 {
    font-size: rem-calc(20);
    line-height: 120%;
    margin-bottom: rem-calc(8);

    @include breakpoint(xlarge) {
        font-size: rem-calc(22);
    }
}

h4 {
    font-size: rem-calc(18);
    line-height: rem-calc(28);
    margin-bottom: rem-calc(8);
}

h5 {
    font-size: rem-calc(16);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(8);
}

p {
    font-family: $s-font-family;
    margin-bottom: rem-calc(15);
    max-width: rem-calc(610);

    @include breakpoint(large) {
        margin-bottom: rem-calc(20);
    }

    iframe {
        width: 100%;
        border: 0;
    }

    a {
        word-break: break-word;
        @include link-underline($s-blue);

        &[href^="http://"]:not([href*="www.sollentuna.se"]),
        &[href^="https://"]:not([href*="www.sollentuna.se"]) {
            margin-right: rem-calc(25);
            display: inline;
            position: relative;
            @include link-underline($s-blue);

            &::after {
                @include external-link-editor();
                margin-right: rem-calc(-20);
            }
    
            &:hover {
                color: $s-blue;
            }

            #{$using-keyboard} & {

                &:focus {
                    @include link-underline($s-black);

                    &[href^="http://"]:not([href*="www.sollentuna.se"]),
                    &[href^="https://"]:not([href*="www.sollentuna.se"]) {

                        &::after {
                            color: $s-black;
                            border-color: $s-black;
                        }
                    }
                }
            }

            &:focus {
                @include link-underline($s-blue);
            }
        }
    }
}

a {
    max-width: rem-calc(610);
    color: $s-blue;

    &:hover {
        color: $s-blue;
    }
}

blockquote {
    max-width: rem-calc(610);
    border-left: 1px solid $s-black;
    
    p {
        color: $s-black;
    }
}

:focus {

    @include breakpoint(large) {
        outline: none;
    }
}

body.using-keyboard {

    :focus {
        outline: none;
        background: $s-yellow;
        color: $s-black !important;
        border-color: $s-black;

        span {
            border-color: $s-black !important;
        }
    }
}

.preamble {
    font-size: rem-calc(18);
    line-height: 160%;
    margin-bottom: rem-calc(25);

    @include breakpoint(medium) {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(30);
    }
}
