.c-select-bar {
    $c-select-bar: '.c-select-bar';
    $using-keyboard: '.using-keyboard';
    position: relative;
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(60);
    }

    &::after {
        content: "";
        height: rem-calc(2);
        width: 100%;
        background: $s-gray-light;
        display: block;
        position: absolute;
        bottom: rem-calc(20);
    }

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
        @include flex-align(center);
    }

    &__content {
        z-index: 9;
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(8);
        }

        @include breakpoint(large) {
            @include xy-cell(5);
        }
    }

    &__row {
        background: $s-white;
        padding: 0;

        @include breakpoint(medium) {
            display: flex;
            padding: rem-calc(0 15);
        }
    }

    &__label {
        margin-right: rem-calc(15);
        position: relative;
        top: rem-calc(6);
        display: block;
        @extend .c-form__label;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        &--list {
            font-weight: $s-font-bold;
            font-size: rem-calc(16);
            padding-left: rem-calc(25);
            margin-bottom: 0;
    
            &:hover, &:focus {
                cursor: pointer;

                span {
                    @include link-underline($s-black);
                }
            }
        }
    }

    &__select {
        margin-bottom: 0;
        @extend .c-form__select;
    }

    &__button {
        width: 100%;
        border: rem-calc(2) solid $s-gray-border;
        border-radius: rem-calc(2);
        height: rem-calc(40);
        text-align: left;
        padding: rem-calc(0 10);
        position: relative;

        &:focus {
            border-color: $s-black;
        }

        &:hover {
            cursor: pointer;
        }

        &::after {
            content: "\f0dd";
            font-family: $s-font-icon;
            font-weight: $s-font-semibold;
            position: absolute;
            top: rem-calc(7);
            right: rem-calc(10);
        }

        #{$c-select-bar}__categories--expanded & {
            border-color: $s-black;
        }
    }

    &__list {
        display: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        background: $s-white;
        width: 100%;
        padding: rem-calc(0 20 20 20);
        border: rem-calc(2) solid $s-black;
        border-top: 0;
        border-radius: rem-calc(2);
        top: rem-calc(38);

        li {
            border-bottom: rem-calc(1) solid $s-gray-light;
            padding: rem-calc(5 0 15 0);

            &:last-child {
                border: 0;
                padding-bottom: rem-calc(5);
            }
        }
    }

    &__filter {
        width: 100%;
        display: flex;
    }

    &__categories {
        position: relative;
        flex-grow: 1;
    }

    &__input {
        @extend .c-form__input;
        height: rem-calc(15);
        width: rem-calc(15);
        position: absolute;
        top: rem-calc(5);
        left: 0;

        #{$using-keyboard} & {

            &:focus {

                + span {
                    background: $s-yellow;
                }
            }
        }

        &:hover, &:focus {
            cursor: pointer;
        }

        &:focus {
            border: rem-calc(2) solid $s-black;
            box-shadow: none;
            background: $s-white !important;

            + span {
                border: 0;
            }
        }
    }

    &__reset {
        color: $s-blue;
        margin: rem-calc(0 15 0 10);
        flex: 0 0 auto;

        @include breakpoint(medium) {
            margin: rem-calc(0 0 0 10);
        }

        &:hover {
            cursor: pointer;
        }

        &::before {
            content: "\f057";
            font-family: $s-font-icon;
            font-size: rem-calc(14);
            font-weight: $s-font-bold;
        }

        span {
            font-weight: $s-font-bold;
            font-size: rem-calc(15);
            @include link-underline($s-blue);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }
}