.c-editor {
    $c-editor: '.c-editor';
    $using-keyboard: '.using-keyboard';
    $crisis-web: '.crisis-web';
    &__head {
        position: relative;
    }
    &__space {
        display: inline;
        white-space: nowrap;
    }
    &__zero {
        width: rem-calc(120);
        display: inline-block;
    }
    &__preamble {
        @extend .preamble;
    }
    &__iframe {
        @include responsive-embed($ratio: 16 by 9);
    }
    img {
        @include editor-img();
    }
    >ul,
    >div:not([class])>ul {
        @include editor-ul-list();
    }
    >ol {
        @include editor-ol-list();
    }
    >ul,
    >ol {
        a {
            &[href^="http://"]:not([href*="www.sollentuna.se"]),
            &[href^="https://"]:not([href*="www.sollentuna.se"]) {
                #{$using-keyboard} & {
                    &:focus {
                        &::after {
                            color: $s-black;
                        }
                    }
                }
            }
        }
    }
    &__highlight {
        margin-bottom: rem-calc(10);
        &--last {
            margin-bottom: rem-calc(20);
            @include breakpoint(large) {
                margin-bottom: rem-calc(25);
            }
        }
    }
    &__link {
        display: flex;
        min-height: rem-calc(50);
        align-items: center;
        background: $s-teal-light;
        border: 0;
        border-radius: rem-calc(4);
        padding: rem-calc(10 15);
        position: relative;
        font-weight: $s-font-bold;
        @include breakpoint(medium) {
            padding: rem-calc(10 20);
        }
        @include breakpoint(large) {
            padding: rem-calc(10 30);
        }
        #{$crisis-web} & {
            background: $s-brand-tint;
        }
        &[href^="http://"]:not([href*="www.sollentuna.se"]),
        &[href^="https://"]:not([href*="www.sollentuna.se"]) {
            display: flex;
            border: rem-calc(2) solid $s-gray-light;
            border: 0;
            margin: 0;
            padding: rem-calc(10 15);
            @include breakpoint(medium) {
                padding: rem-calc(10 20);
            }
            @include breakpoint(large) {
                padding: rem-calc(10 30);
            }
            #{$c-editor}__text {
                display: inline-block;
                padding-right: rem-calc(20);
                position: relative;
                margin-right: 0;
                &::after {
                    @include external-link-item();
                    background: none;
                    line-height: 0;
                    color: $s-blue;
                }
            }
            &:focus {
                border: 0;
                padding-bottom: rem-calc(10);
            }
            &::after {
                content: none !important;
            }
        }
        #{$using-keyboard} & {
            &:focus {
                background: $s-teal-light;
                #{$c-editor}__text {
                    span {
                        background: $s-yellow;
                        @include link-underline($s-black);
                    }
                    &::after {
                        content: "";
                        background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat bottom right;
                        background-position-x: rem-calc(5);
                    }
                }
                &[href^="http://"]:not([href*="www.sollentuna.se"]),
                &[href^="https://"]:not([href*="www.sollentuna.se"]) {
                    border: 0;
                    padding-bottom: rem-calc(10);
                    #{$c-editor}__text {
                        &::after {
                            @include external-link-item();
                            background: none;
                        }
                    }
                }
            }
        }
    }
    &__text {
        position: relative;
        margin-right: rem-calc(20);
        display: inline-block;
        span {
            display: inline;
            &:hover,
            &:focus {
                @include link-underline($s-blue);
            }
        }
        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat bottom right;
            background-position-x: rem-calc(5);
            position: absolute;
            width: rem-calc(20);
            height: rem-calc(11);
            display: inline;
            bottom: rem-calc(7);
        }
    }
    &--contact {
        h2 {
            font-size: rem-calc(20);
            line-height: rem-calc(30);
        }
        h3 {
            font-size: rem-calc(18);
            line-height: rem-calc(27);
        }
        h4 {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
        }
        h5 {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
        }
    }
}
