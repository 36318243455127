@media print {

    @page {
        margin: rem-calc(40 100) !important;    
    }

    * {
        padding: 0 !important;
        margin: 0 !important;

        &:focus, &:hover {
            border: 0 !important;
        }

        a {
            text-decoration: none !important;
        }
    }

    h1, h2, h3, h4, h5, h6, article {
        page-break-after: avoid !important;
    }

    img, tr {
        page-break-inside: avoid;
    }

    /* Component Typography */
    p {
        margin-bottom: rem-calc(20) !important;
    }

    .c-entry-list__item {
        text-align: left !important;
    }
    
    /* Component Display */
    .g-navigation-side,
    .g-header,
    .g-footer,
    .c-cookie,
    .c-feedback,
    .c-select-bar,
    .c-media.c-media--embed,
    .c-search-filter,
    .c-entry-list__icon,
    .c-hero--login,
    .c-services-list__support,
    .c-call-to-action,
    .c-go-back,
    .c-services-info__back-to-top,
    .c-editor-files__col--size,
    .c-search-form,
    .c-search-filter__button--reset,
    .c-search-filter__button,
    .c-icon-list__extend,
    .c-events-calendar__footer,
    .c-event-info__button,
    .c-warning-message__top::before,
    .c-contact-card__tabs,
    .c-instructions__back-to-top,
    .c-instructions__close,
    .c-translate,
    .c-event-info__button,
    .c-hero__block, 
    .c-breadcrumbs {
        display: none !important;
    }

    .c-news__item,
    .c-button,
    .c-entry-list__list,
    .c-entry-list__link,
    .c-article-row__outer,
    .c-events-calendar__list,
    .c-article__link,
    .c-hero__content,
    .c-contact__list,
    .c-event-info__details,
    .c-events-calendar__footer,
    .c-article__outer,
    .p-landing__wrapper,
    .c-search-filter,
    .p-standard__container {
        display: block !important;
    }

    .c-news__category {
        display: inline-block !important;
    }

    /* Component Lists */
    .c-editor > ol,
    .c-facts ol {
        counter-reset: none !important;
    }

    .c-facts ul li,
    .c-editor > ul li {
        list-style-type: disc !important;
    }

    .c-facts ol li,
    .c-editor > ol li {
        counter-increment: none !important;
    }

    .c-facts ol li,
    .c-editor > ol li {
        list-style-type: decimal !important;
    }

    .c-editor > ul,
    .c-facts ul,
    .c-editor > ol,
    .c-facts ol {
        list-style-position: inside !important;
    }

    /* Component Order */
    .c-article__image,
    .c-hero--list .c-hero__background {
        order: 1 !important;
    }

    .c-article__inner,
    .c-hero--list .c-hero__content {
        order: 2 !important;
    }

    /* Component Width */
    .c-page-intro__content,
    .c-page-intro__preamble,
    .p-standard__article,
    .p-news__article,
    .p-event__article,
    .p-news-archive__content,
    .p-news__content,
    .p-landing__block-area,
    .p-search__content,
    .p-standard__article,
    .p-standard__inner,
    .p-event__content,
    .c-block-grid,
    .c-block-grid--half,
    .c-list-links__outer,
    .c-list-links__item,
    .c-contact__outer--info .c-contact__wrapper,
    .c-services-list__item,
    .c-entry-list__item,
    .c-entry-list__item:nth-child(3n+2),
    .c-events-calendar__item,
    .c-news__inner,
    .c-cols-quicklinks__col,
    .c-article__image,
    .c-article__inner,
    .c-news__info,
    .c-hero--list .c-hero__background,
    .c-hero--list .c-hero__block,
    .c-media__outer,
    .c-media__text,
    .c-call-to-action__inner,
    .c-services-info__inner,
    .c-services-info__content,
    .c-events__content,
    .c-search-filter__settings,
    .c-hero img,
    .c-media.c-media--on-page img {
        width: 100% !important;
    }

    .c-article-row__inner,
    .c-media__outer img,
    .c-events-calendar img
    .c-article img,
    .c-events-calendar img,
    .c-article img {
        width: 75% !important;
    }

    .c-article-row__inner {
        width: 50% !important;
    }

    .c-page-intro__content,
    .c-page-intro__preamble,
    .c-calendar__inner {
        max-width: 100% !important;
    }

    .c-services-list,
    .c-services-list--background,
    .c-services-list--background .c-services-list__inner,
    .c-events-calendar__inner,
    .c-events-calendar__content,
    .c-contact-people__inner,
    .c-article__image,
    .c-contact-card__right,
    .p-landing__inner .p-landing__block-area .c-news__content,
    .c-instructions__inner,
    .c-instructions__content,
    .c-contact__outer--top .c-contact__wrapper,
    .c-contact__inner,
    .c-contact__outer--info .c-contact__wrapper,
    .c-contact__content,
    .c-article__image,
    .c-warning-message__outer,
    .c-warning-message__top,
    .c-warning-message__info,
    .c-events__content,
    .p-events-list__content,
    .c-events__outer,
    .c-events__section,
    .c-events__group,
    .c-events__content,
    .c-hero__links  {
        max-width: none !important;
        width: 100% !important;
    }

    @media print, screen and (min-width: 64em) {

        .c-contact-card__right,
        .c-contact__outer--top .c-contact__wrapper {
            max-width: none !important;
            width: 100% !important;
        }
    }

    @media print, screen and (min-width: 40em) {

        .c-contact__outer--top .c-contact__wrapper {
            max-width: none !important;
            width: 100% !important;
        }
    }

    /* Component Margin */
    .c-editor-files__col--title {
        margin-right: rem-calc(20) !important;
    }

    .c-breadcrumbs__item:not(:last-child)::after {
        margin-right: rem-calc(6) !important;
    }

    .c-events__tag {
        margin: rem-calc(10 0 0 0) !important;
    }

    .c-block-grid,
    .c-contact__outer--info .c-contact__wrapper {
        margin-bottom: rem-calc(40) !important;
    }

    .c-news__item,
    .c-calendar__item,
    .c-events__subheading {
        margin-bottom: rem-calc(15) !important;
    }

    .c-list-links__heading {
        margin-bottom: rem-calc(5) !important;
    }

    .c-icon-list-item img {
        margin-top: rem-calc(10) !important;
    }

    .c-page-intro,
    .c-contact__outer.c-contact__outer--welcome,
    .c-contact__wrapper,
    .c-hero,
    .c-hero.c-hero--list {
        margin-bottom: rem-calc(30) !important;
    }

    .c-article-row__inner,
    .c-entry-list__item,
    .c-services-list__item,
    .c-events-calendar__item,
    .c-contact-people__item,
    .c-contact-card__left,
    .c-contact-card__subheading,
    .c-calendar__heading,
    .c-events-calendar__heading
    .c-contact__outer--top,
    .c-contact__outer--welcome,
    .c-breadcrumbs,
    .c-event-info,
    .c-media.c-media--on-page,
    .c-event-info__panel,
    .c-page-header,
    .c-editor__preamble,
    .c-services-info__preamble,
    .c-cols-quicklinks__col,
    .c-warning-message,
    .c-editor-files,
    .c-event-info__details,
    .c-article-list__item,
    .c-calendar__list,
    .c-instructions__intro,
    .c-event-info__details,
    .c-hero--list .c-hero__background {
        margin-bottom: rem-calc(20) !important;
    }

    .c-contact-card__item,
    .c-events-calendar img,
    .c-media img,
    .c-article img,
    .c-contact-people__heading,
    .c-icon-list-item,
    .c-event-info__item,
    .c-editor > ul,
    .c-editor > ol,
    .c-facts ul, 
    .c-facts ol,
    .c-services-info__item,
    .c-events__content--all .c-events__list--events .c-events__item,
    .c-news__item,
    .c-editor__link,
    .c-event-info__date,
    .c-editor-files__heading,
    .c-editor__highlight,
    .c-page-intro__heading,
    .c-news__heading,
    .c-cols-quicklinks__heading,
    .c-instructions__item {
        margin-bottom: rem-calc(10) !important;
    }

    .c-page-footer,
    .c-events__label,
    .c-event-info__events,
    .c-editor-files {
        margin-top: rem-calc(20) !important;
    }

    .c-article-list__figure,
    .c-event-info__location {
        margin-right: rem-calc(30) !important;
    }

    .c-event-info__date,
    .c-event-info__time,
    .c-contact__content *,
    .c-contact__outer.c-contact__outer--info,
    .c-article-list__inner,
    .p-landing .c-block-grid--half .c-article-list__inner {
        margin: 0 !important;
    }

    .c-event-info__list,
    .c-event-info__secondary {
        margin-top: 0 !important;
    }

    .c-event-info__secondary {
        margin-top: rem-calc(-10) !important;
    }

    .c-entry-list__item:last-child,
    .c-news__item:last-child,
    .c-calendar__item:last-child,
    .c-services-list__item:last-child,
    .c-events-calendar__item:last-child,
    .c-contact-card__item:last-child,
    .c-contact-people__item:last-child,
    .c-editor__highlight * {
        margin-bottom: 0 !important;
    }

    /* Component Padding */
    .c-icon-list-item {
        padding: rem-calc(20 0 0 0) !important;
    }

    .c-editor-files__link--title[href$=".pdf"],
    .c-editor-files__link--title[href$=".doc"], 
    .c-editor-files__link--title[href$=".docx"],
    .c-editor-files__link--title[href$=".odt"], 
    .c-editor-files__link--title[href$=".rtf"], 
    .c-editor-files__link--title[href$=".txt"],
    .c-editor-files__link--title[href$=".pps"], 
    .c-editor-files__link--title[href$=".ppt"], 
    .c-editor-files__link--title[href$=".pptx"],
    .c-editor-files__link--title[href$=".ai"], 
    .c-editor-files__link--title[href$=".eps"], 
    .c-editor-files__link--title[href$=".ps"], 
    .c-editor-files__link--title[href$=".svg"], 
    .c-editor-files__link--title[href$=".gif"], 
    .c-editor-files__link--title[href$=".jpg"], 
    .c-editor-files__link--title[href$=".png"], 
    .c-editor-files__link--title[href$=".psd"], 
    .c-editor-files__link--title[href$=".tif"], 
    .c-editor-files__link--title[href$=".tiff"],
    .c-editor-files__link--title[href$=".mp3"], 
    .c-editor-files__link--title[href$=".wav"], 
    .c-editor-files__link--title[href$=".aiff"], 
    .c-editor-files__link--title[href$=".aiff"], 
    .c-editor-files__link--title[href$=".aac"], 
    .c-editor-files__link--title[href$=".ogg"], 
    .c-editor-files__link--title[href$=".wma"], 
    .c-editor-files__link--title[href$=".flac"] {
        padding-left: rem-calc(25) !important;
    }

    .c-breadcrumbs__item {
        padding-right: rem-calc(16) !important;
    }

    .c-events__button {
        padding-left: rem-calc(55) !important;
    }

    .c-news__item,
    .c-calendar__item {
        padding-bottom: rem-calc(15) !important;
    }

    .c-entry-list__item:last-child,
    .c-news__item:last-child,
    .c-calendar__item:last-child,
    .c-services-list__item:last-child,
    .c-events-calendar__item:last-child,
    .c-contact-card__item:last-child,
    .c-contact-people__item:last-child {
        padding-bottom: 0 !important;
    }

    .p-landing__inner .p-landing__block-area .c-news__item,
    .p-landing__inner .p-landing__block-area .c-block-grid--half .c-news__item,
    .c-contact__content *,
    .c-contact__outer.c-contact__outer--info *,
    .c-editor__highlight * {
        padding: 0 !important;
    }

    .c-contact__outer--welcome .c-contact__wrapper {
        padding: rem-calc(20) !important;
    }

    .c-page-footer,
    .c-event-info__item {
        padding-top: rem-calc(10) !important;
    }

    .c-contact-card__item,
    .c-instructions__item,
    .c-icon-list-item,
    .c-event-info__item,
    .c-services-info__item,
    .c-events__content--all .c-events__list--events .c-events__item,
    .c-event-info__item {
        padding-bottom: rem-calc(10) !important;
    }

    .c-services-info__link,
    .c-instructions__link,
    .c-event-info__link {
        padding-left: rem-calc(30) !important;
    }

    .c-event-info__item {
        padding-top: rem-calc(5) !important;
    }

    .c-hero__heading {
        padding: rem-calc(0 20) !important;
    }

    /* Component Border */
    .c-button {
        border-color: $s-black !important;
    }

    .c-events__section--panel .c-events__wrapper {
        border-bottom: rem-calc(2) solid $s-black !important;
    }

    .c-page-footer {
        border-top: rem-calc(2) solid $s-black !important;
    }

    .c-block-grid a span {
        border-bottom: rem-calc(1) solid $s-black !important;
    }

    a,
    p a,
    a span,
    .c-go-back span,
    .c-list-links__item:nth-child(odd),
    .c-list-links__item:nth-child(even),
    .p-landing__outer:first-of-type,
    .p-start__outer:last-of-type,
    .c-events-calendar__inner,
    .p-services__outer:last-of-type,
    .p-contact__outer,
    .c-editor-files__list,
    .c-events__section--panel:hover .c-events__button time,
    .p-landing__inner .p-landing__block-area .c-news__item:last-child,
    .c-news__link *,
    .c-news__link:hover *,
    .c-news__link:focus * {
        border: 0 !important;
    }

    .p-landing__inner .p-landing__block-area .c-news__item {
        border-bottom: rem-calc(2) solid $s-gray-light !important;
    }

    .c-events__button::before,
    .c-instructions__item--instructions .c-instructions__link::before,
    .c-event-info__secondary .c-event-info__link::before {
        border: rem-calc(2) solid $s-black !important;
    }

    /* Component Color */
    .c-events__button::before {
        color: $s-white !important;
    }

    .c-article__outer--bg-blue .c-article__inner,
    .c-article__link,
    .c-editor__link[href^="http://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after, 
    .c-editor__link[href^="https://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after,
    .c-events__button::before,
    .c-instructions__item--instructions .c-instructions__link::before,
    .c-event-info__secondary .c-event-info__link::before {
        color: $s-black !important;
    }

    .c-article-list__figure {
        max-width: 33.33333% !important;
    }

    /* Component Heading */
    .c-hero__heading,
    .c-page-intro__heading {
        font-size: rem-calc(32) !important;
        line-height: rem-calc(48) !important;
    }

    .c-cols-quicklinks__heading {
        line-height: normal !important;
    }

    /* Componet Flex */
    .c-contact__content,
    .c-hero.c-hero--list .c-hero__wrapper *,
    .c-event-info__duration,
    .c-event-info__item,
    .c-event-info__link::before,
    .c-hero--list .c-hero__inner {
        display: flex !important;
    }

    .c-events__tag {
        justify-content: left !important;
    }
    
    .c-contact__outer--welcome,
    .c-contact__content,
    .c-hero.c-hero--list .c-hero__wrapper *,
    .c-event-info__link::before {
        justify-content: center !important;
    }

    .c-contact__content,
    .c-hero.c-hero--list .c-hero__wrapper *,
    .c-event-info__duration,
    .c-event-info__link::before {
        align-items: center !important;
    }

    .c-events__details,
    .c-event-info__details,
    .c-contact-card__inner,
    .c-event-info__details,
    .c-contact__content,
    .c-hero--start .c-hero__list,
    .c-hero--list .c-hero__inner {
        flex-direction: column !important;
    }

    .p-landing .c-block-grid--half .c-article-list__item,
    .c-event-info__item {
        flex-direction: row !important;
    }

    /* Component Height */
    .c-search-form__input {
        height: rem-calc(40) !important;
    }

    .c-contact-card iframe,
    .c-contact__outer--welcome {
        height: rem-calc(250) !important;
    }

    .c-hero__heading {
        height: rem-calc(50) !important;
    }

    .c-editor__link,
    .c-article__overflow,
    .c-editor__highlight *,
    .c-hero.c-hero--list *,
    .c-hero__content * {
        min-height: auto !important;
    }

    .c-editor__link,
    .c-article__image,
    .c-article__image img,
    .c-hero.c-hero--list,
    .c-hero.c-hero--list *,
    .c-hero__content * {
        height: auto !important;
    }

    .c-hero__wrapper {
        max-height: rem-calc(200) !important;
    }
    
    .c-hero {
        height: rem-calc(200) !important;
        min-height: rem-calc(200) !important;
    }

    /* Component Width */
    .c-article__image,
    .c-article__image img {
        min-width: auto !important;
    }

    .c-editor-files__col--title,
    .c-editor__link[href^="http://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after, 
    .c-editor__link[href^="https://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after,
    .c-article-list__inner,
    .p-landing .c-block-grid--half .c-article-list__inner {
        width: auto !important;
    }

    /* Component Background */
    .c-hero__heading,
    .c-contact__wrapper {
        background: $s-white !important;
    }
    
    .c-events__button::before,
    .c-services-info__item--instructions .c-services-info__link::before,
    .c-events__button::before {
        background: $s-black !important;
    }

    .c-editor__text::after {
        background: none !important;
    }

    /* Component Pseudo */
    .c-go-back::before,
    .c-event-info__primary .c-event-info__content::before,
    .c-icon-list-item__outer::before,
    .c-entry-list__link span::before,
    .c-contact__link::before,
    .c-editor__link::after,
    .c-editor-files__item:not(:last-child)::after,
    .c-editor > ol li::before,
    .c-editor > ul li::before,
    .c-facts ul li::before,
    .c-facts ol li::before,
    a[href]:after {
        content: none !important;
    }

    .c-editor__link[href^="http://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after, 
    .c-editor__link[href^="https://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after {
        content: "\00a0\f35d" !important;
    }

    /* Component Position */
    .c-editor-files__link--title[href$=".pdf"]::before,
    .c-editor-files__link--title[href$=".doc"]::before, 
    .c-editor-files__link--title[href$=".docx"]::before,
    .c-editor-files__link--title[href$=".odt"]::before, 
    .c-editor-files__link--title[href$=".rtf"]::before, 
    .c-editor-files__link--title[href$=".txt"]::before,
    .c-editor-files__link--title[href$=".pps"]::before, 
    .c-editor-files__link--title[href$=".ppt"]::before, 
    .c-editor-files__link--title[href$=".pptx"]::before,
    .c-editor-files__link--title[href$=".ai"]::before, 
    .c-editor-files__link--title[href$=".eps"]::before, 
    .c-editor-files__link--title[href$=".ps"]::before, 
    .c-editor-files__link--title[href$=".svg"]::before, 
    .c-editor-files__link--title[href$=".gif"]::before, 
    .c-editor-files__link--title[href$=".jpg"]::before, 
    .c-editor-files__link--title[href$=".png"]::before, 
    .c-editor-files__link--title[href$=".psd"]::before, 
    .c-editor-files__link--title[href$=".tif"]::before, 
    .c-editor-files__link--title[href$=".tiff"]::before,
    .c-editor-files__link--title[href$=".mp3"]::before, 
    .c-editor-files__link--title[href$=".wav"]::before, 
    .c-editor-files__link--title[href$=".aiff"]::before, 
    .c-editor-files__link--title[href$=".aiff"]::before, 
    .c-editor-files__link--title[href$=".aac"]::before, 
    .c-editor-files__link--title[href$=".ogg"]::before, 
    .c-editor-files__link--title[href$=".wma"]::before, 
    .c-editor-files__link--title[href$=".flac"]::before,
    .c-events__button::before {
        left: 0 !important;
    }

    p a[href^="http://"]:not([href*="www.sollentuna.se"])::after, 
    p a[href^="https://"]:not([href*="www.sollentuna.se"])::after,
    .c-article__image,
    .c-article__image img,
    .c-editor__link[href^="http://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after, 
    .c-editor__link[href^="https://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after,
    .c-hero--list .c-hero__background {
        position: relative !important;
    }

    .c-editor__link[href^="http://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after, 
    .c-editor__link[href^="https://"]:not([href*="www.sollentuna.se"]) .c-editor__text::after {
        bottom: 0 !important;
    }

    /* Component Opacity */
    .c-events__button::before,
    .c-instructions__item--instructions .c-instructions__link::before,
    .c-event-info__secondary .c-event-info__link::before {
        opacity: 1 !important;
    }
    .c-control-plan-print{
        &__heading{
            line-height: normal !important;
        }
        &__table{
            font-size: 0.65rem !important;
            margin-top: 1rem !important;
        }
    }
}
