/* Different layout on Landing Page, Block - Half width */

.c-calendar {
    $c-block-grid-half: '.c-block-grid--half';
    $p-landing: '.p-landing';
    $using-keyboard: '.using-keyboard';
    height: 100%;
    margin-bottom: rem-calc(15);

    @include breakpoint(small down) {
        margin: rem-calc(0 -15);
    }

    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
    }
    
    @include breakpoint(large) {
        padding-bottom: rem-calc(60);
    }

    &__outer {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: rem-calc(15);
        height: 100%;

        @include breakpoint(medium) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include breakpoint(large) {
            padding: rem-calc(40 70);
        }
    }

    &__inner {
        background: $s-white;
        border-radius: rem-calc(4);
        padding: rem-calc(20 30 30 30);

        @include breakpoint(medium) {
            width: 100%;
            max-width: rem-calc(610);
            align-self: center;
        }

        @include breakpoint(large) {
            padding: rem-calc(20 40 30 40);
        }
    }

    &__list {
        list-style-type: none;
        margin: rem-calc(20 0 40 0);
        padding: 0;
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(10 0 5 0);

        &:last-child {
            border: 0;
        }
    }

    &__time {
        @include tag-date();
    }

    &__category {
        @include tag-category();

        &::before {
            content: "\00a0|\00a0";
        }
    }

    &__heading {
        font-size: rem-calc(28);
    }

    &__subheading {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        max-width: rem-calc(610);
    }

    &__link {
        color: $s-black;
        padding-right: rem-calc(14);
        display: inline-block;

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            width: rem-calc(14);
            height: rem-calc(11);
            position: relative;
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        span {
            display: inline;
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--right;
        max-width: rem-calc(610);
    }

    /* Different layout on Landing Page + Block - Half Width */
    #{$p-landing} #{$c-block-grid-half} & {

        &__outer {

            @include breakpoint(large) {
                padding: rem-calc(20 30);
            }
        }

        &__inner {

            @include breakpoint(large) {
                padding: rem-calc(20 30 30 30);
            }
        }

        &__list {
            margin: rem-calc(10 0 30 0);
        }

        &__button {

            @include breakpoint(large) {
                min-width: auto;
            }
        }
    }
}