/* Different layout on Landing Page */

.c-services-list {
    $c-services-list: '.c-services-list';
    $p-landing: '.p-landing';
    $using-keyboard: '.using-keyboard';
    padding-bottom: rem-calc(30);

    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        padding-bottom: rem-calc(60);
    }

    &--background {
        @extend .c-block-grid--full-bleed;

        #{$c-services-list} {

            &__outer {
                @extend .c-block-grid--full-bleed__outer;
                background: $s-teal-light;
                padding: rem-calc(20 0 30 0);
                
                @include breakpoint(large) {
                    padding: rem-calc(40 0 70 0);
                }
            }
    
            &__inner {
                @extend .c-block-grid--full-bleed__inner;
            }

            &__item {
                background: $s-white;
            }

            &__top {
                margin-top: 16px;
                margin-bottom: 8px;

                @include breakpoint(medium) {
                    margin-bottom: rem-calc(10);
                    margin-top: 32px;
                    margin-bottom: 16px;
                }
        
                @include breakpoint(large) {
                    margin-bottom: rem-calc(15);
                }
            }
        }
    }
    &__top {
        margin-top: 16px;
        margin-bottom: 8px;

        @include breakpoint(large) {
            margin-top: 32px;
            margin-bottom: 16px;
        }
    }
    &__heading {
        font-size: rem-calc(12);
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        margin: 0;
        flex-grow: 1;
        line-height: 22.4px;
    }

    &__subheading {
        font-size: rem-calc(18);
        line-height: rem-calc(28);
        margin-bottom: rem-calc(5);
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include xy-grid();
        @include xy-gutters($negative: true);

        @include breakpoint(medium only) {
            padding: rem-calc(0 5);
        }
    }

    &__item {
        @include xy-cell(12);
        background: $s-teal-light;
        margin-top: rem-calc(15);
        padding: rem-calc(15 20);

        &:nth-child(1) {
            margin-top: 0;
        }

        @include breakpoint(medium) {
            @include xy-cell(6, $gutters:15);
            margin-top: rem-calc(15);

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include breakpoint(large) {
            @include xy-cell(4);
            margin-top: rem-calc(30);
            padding: rem-calc(20 30);

            &:nth-child(3) {
                margin-top: 0;
            }
        }
    }

    &__link {
        color: $s-black;
        padding-right: rem-calc(16);
        display: inline-block;

        &:hover, &:focus {
            color: $s-black;

            span {
                @include link-underline($s-black);
                padding-bottom: 0;
            }
        }

        &::after {
            content: "";
            background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat center right;
            position: relative;
            width: rem-calc(14);
            height: rem-calc(11);
            display: inline-block;
            margin-right: rem-calc(-14);
        }

        &[href^="https://"]:not([href*="www.sollentuna.se"]),
        &[href^="http://"]:not([href*="www.sollentuna.se"]) {
    
            &::after {
                @include external-link-item();
                background: none;
            }
        }

        span {
            display: inline;
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__support {
        font-weight: $s-font-bold;
        font-size: rem-calc(15);
        align-self: center;
        margin-top: rem-calc(20);
        display: block;
        text-align: right;
        line-height: 22.4px;
        @include breakpoint(medium) {
            margin-top: 0;
            position: absolute;
            right: 0;
        }

        span {
            position: relative;
            @include link-underline($s-blue);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    &__top {
        display: flex;
        width: 100%;
        position: relative;
    }

    /* Different styles on Landing Page */
    #{$p-landing} & {

        @include breakpoint(large) {
            padding-bottom: rem-calc(50);
            margin-top: rem-calc(-30);
        }
    }
}
