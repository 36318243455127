.Sleek .s9-modal-container {
    background-color: #999;
}
.s9-modal-container {
    height: 80vh;
    width: 80vw;
}
.s9-modal-body {
    padding: 0;
    overflow: hidden;
}
.s9-modal-container .dijitDialogPaneContent {
    padding: 0px;
    border: 1px solid #999;
}
.s9-iframe {
    position: absolute;
    width: 100%;
    height: calc(80vh - 38px); 
    border: none;
}
.s9-video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}
.s9-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}