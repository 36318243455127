.c-nav-support {
  $using-keyboard: '.using-keyboard';

  @include breakpoint(large) {
    max-width: rem-calc(1280);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: block;
    height: rem-calc(42);
  }

  &__list {
    padding: rem-calc(25 15);
    list-style-type: none;
    margin: 0;
    display: flex;

    @include breakpoint(large) {
      padding: 0;
      position: absolute;
      top: rem-calc(-42);
      right: rem-calc(15);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: center;

    @media (max-width: rem-calc(420px)) {
      width: 25%;
    }

    @include breakpoint(large) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &:first-of-type {
      padding: 0;
    }

    &:last-of-type {
      @include breakpoint(large) {
        display: block;
        position: relative;
      }
    }
  }

  &__link {
    font-family: $s-font-family;
    font-size: rem-calc(15);
    display: block;
    position: relative;

    @include breakpoint(large) {
      padding: rem-calc(5 20);
      background: none;
      padding: 0;
      border-radius: 0;
      color: $s-blue;
      font-size: rem-calc(16);
    }

    span {
      position: relative;
      font-size: rem-calc(12);
      font-style: normal;
      font-weight: 600;
      line-height: rem-calc(24);
      letter-spacing: 0.2px;
      color: #0A0A0A;

      @include breakpoint(medium down) {
        color: $s-white;
        font-size: rem-calc(11);
        font-style: normal;
        font-weight: 500;
        line-height: rem-calc(24);
        letter-spacing: 0.2px;
      }
    }

    &:hover, &:focus {
      span {
        @include breakpoint(medium down) {
          text-decoration: underline;
          color: $s-white;
        }

        @include breakpoint(large) {
          text-decoration: underline;
          color: $s-black;
        }
      }
    }

    #{$using-keyboard} & {

      &:focus {
        background: none;

        span {
          background: $s-yellow;
        }
      }
    }

    &--active {

      @include breakpoint(large) {

        span {
          @include link-underline($s-blue);
        }
      }
    }

    &--listen {
      font-family: $s-font-family;
      font-size: rem-calc(15);
      position: relative;

      @include breakpoint(large) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: rem-calc(86);
      }

      &::before {
        content: "\f2a2";
        font-family: $s-font-icon;
        font-weight: 400;
        font-size: rem-calc(16);
        margin-right: rem-calc(5);
        color: $s-white;

        @include breakpoint(large) {
          color: $s-blue;
          top: rem-calc(-3);
          left: 0;
          font-size: rem-calc(19);
          margin-right: 0;
          border-radius: rem-calc(200);
          background: $s-teal;
          width: rem-calc(40);
          height: rem-calc(40);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 6px;
        }

        @include breakpoint(small down) {
          margin-right: 0;
        }
      }
    }

    &--translate {
      font-family: $s-font-family;
      font-size: rem-calc(15);
      position: relative;

      @include breakpoint(large) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: rem-calc(86);
      }

      &::before {
        content: "\f0ac";
        font-family: $s-font-icon;
        font-weight: 400;
        font-size: rem-calc(16);
        margin-right: rem-calc(5);
        color: $s-white;

        @include breakpoint(large) {
          color: $s-blue;
          top: rem-calc(-3);
          left: 0;
          font-size: rem-calc(19);
          margin-right: 0;
          border-radius: 200px;
          background: $s-teal;
          width: rem-calc(40);
          height: rem-calc(40);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 6px;
        }

        @include breakpoint(small down) {
          margin-right: 0;
        }
      }
    }
  }
}
