.c-banners {
  display: contents;
  width: 100%;
  z-index: 999;
}

.c-banners:has(.c-cookie--hidden) {
  height: 0;
  transition: transform .3s ease;
}
