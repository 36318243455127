.c-go-back {
    $using-keyboard: '.using-keyboard';
    $p-news: '.p-news';
    display: inline-block;
    
    &::before {
        content: "";
        background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
        width: rem-calc(12);
        height: rem-calc(11);
        transform: rotate(180deg);
        display: inline-block;
        margin-right: rem-calc(5);
    }

    span {
        @include link-underline($s-blue);
        font-weight: $s-font-bold;
        font-size: rem-calc(15);
    }

    #{$using-keyboard} & {

        &:focus {
            background: none;

            &::before {
                background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat;
            }

            span {
                background: $s-yellow;
            }
        }
    }

    /* Different styles on News Page */
    #{$p-news} & {
        margin-top: rem-calc(20);
    }
}