.p-kitchensink {
    @extend .c-page-grid;

    &__outer {
        @extend .c-page-grid__outer;
    }

    &__heading {
        background: $s-yellow;
        @include xy-cell(12);
        max-width: 100%;
        margin-top: rem-calc(100);
        margin-bottom: rem-calc(100);
    }
}