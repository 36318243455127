$c-checklist: ".c-checklist";
$c-editor-files: ".c-editor-files";
$c-block-grid: ".c-block-grid";
$c-editor: ".c-editor";
$using-keyboard: ".using-keyboard";

.c-checklist {
    margin-bottom: rem-calc(30);
    width: 100%;
    max-width: rem-calc(610);
    margin: 0 auto;

    &__outer {
        @extend .c-faq__outer;
        margin-top: rem-calc(20);
        padding: rem-calc(50 40);
        p {
            margin: 0;
        }
    }
    &__inner{
        width: 100%;
    }

    &__heading {
        font-size: rem-calc(24);
    }
    &__preamble {
        margin-bottom: 0;
    }

    &__list {
        list-style-type: none;
        margin: rem-calc(0 -15);
        padding: 0;
        @include breakpoint(medium) {
            margin: 0;
        }
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;

        &:last-child {
            border: 0;
        }

        &--hidden {
            visibility: hidden;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
            margin: 0;
            opacity: 0;
            height: 0;
        }

        &--visible {
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        &--expanded {
            border: 0;
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        position: relative;
        display: inline-flex;
        align-items: flex-start;
        cursor: pointer;
        padding-left: rem-calc(35);
        padding-right: rem-calc(10);
        span {
            @include link-underline(transparent);
        }

        &:hover,
        &:focus {
            .c-checklist__step-name {
                color: $s-black;
                @include link-underline($s-black);
            }
        }

        &::before {
            content: "+";
            background: $s-teal-light;
            height: rem-calc(24);
            width: rem-calc(24);
            border-radius: rem-calc(24);
            color: $s-blue;
            font-size: rem-calc(20);
            font-family: $s-font-family-alt;
            top: rem-calc(-3);
            left: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #{$using-keyboard} & {
            &:focus {
                &::before {
                    background: $s-black;
                    color: $s-yellow;
                }
            }
        }

        #{$c-checklist}__item--expanded & {
            &::before {
                content: "−";
                background: $s-blue;
                color: $s-white;
            }
        }
    }

    &__content {
        padding: rem-calc(0 15);
        @include breakpoint(medium){
            padding: rem-calc(0 40);
        }
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__title {
        @include xy-grid();
        @include flex-align(center);
        padding: rem-calc(18 0);

        #{$c-checklist}__content {
            position: relative;
        }
    }

    &__area {
        display: none;
    }

    &__button {
        &--expanded {
            @extend .c-button--minus;
        }
    }
    &__checkmark {
        font-size: rem-calc(20);
        i {
            &.fas {
                display: none;
                color: $s-blue;
            }
            &.far {
                display: block;
                color: $s-gray-medium;
            }
        }
    }
    &__checkbox[type="checkbox"] {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        &:checked {
            ~ .c-checklist__checkmark {
                i {
                    &.fas {
                        display: block;
                    }
                    &.far {
                        display: none;
                    }
                }
            }
        }
        &:focus {
            + .c-checklist__checkmark {
                i {
                    #{$using-keyboard} &{
                        &.fas {
                            display: block;
                            color: $s-yellow;
                            background: $s-black;
                            outline: rem-calc(2) solid $s-black;
                        }
                        &.far {
                            display: none;
                        }
                    }  
                }
            }
        }
    }
    &__label {
        position: relative;
        cursor: pointer;
        &:before {
            content: "";
            width: 300%;
            height: 300%;
            position: absolute;
            top: -100%;
            left: -100%;
        }
    }
    &__label-text {
        @extend .show-for-sr;
    }
    
    &__step{
        display: flex;
        text-align: left;
    }
    &__step-index {
        white-space: nowrap;
        margin-right: rem-calc(10);
    }
}
