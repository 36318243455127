.c-entry-list {
  $using-keyboard: '.using-keyboard';
  $c-entry-list: '.c-entry-list';
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    padding-bottom: rem-calc(60);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include breakpoint(large) {
      justify-content: space-between;
    }

    @include breakpoint(xlarge) {
      padding: rem-calc(80 0 0 0);
    }
  }

  &__item {
    flex: 0 0 auto;
    margin-bottom: rem-calc(15);
    padding: rem-calc(0 10);
    text-align: center;

    @include breakpoint(medium) {
      margin-bottom: rem-calc(30);
      width: 50%;

      &:nth-child(3n+2) {
        width: 50%;
      }
    }

    @include breakpoint(large) {
      display: flex;
      flex-direction: column;
      width: auto;
      padding: rem-calc(0 30);
      margin-bottom: rem-calc(20);

      &:nth-child(3n+2) {
        width: auto;
      }
    }

    &:nth-child(2) {

      #{$c-entry-list}__icon {
        background: $s-teal-dark;
        color: $s-white;
      }

      #{$c-entry-list}__link:hover,
      #{$c-entry-list}__link:focus {

        #{$c-entry-list}__icon {
          background: $s-teal-lighter;
          color: $s-teal-dark;
        }
      }
    }

    &:nth-child(3) {

      #{$c-entry-list}__icon {
        background: $s-pink-dark;
        color: $s-white;
      }

      #{$c-entry-list}__link:hover,
      #{$c-entry-list}__link:focus {

        #{$c-entry-list}__icon {
          background: $s-pink-light;
          color: $s-pink-dark;
        }
      }
    }

    &:nth-child(4) {

      #{$c-entry-list}__icon {
        background: $s-pink;
        color: $s-white;
      }

      #{$c-entry-list}__link:hover,
      #{$c-entry-list}__link:focus {

        #{$c-entry-list}__icon {
          background: $s-pink-light;
          color: $s-pink;
        }
      }
    }

    &:nth-child(5) {

      #{$c-entry-list}__icon {
        background: $s-purple;
        color: $s-white;
      }

      #{$c-entry-list}__link:hover,
      #{$c-entry-list}__link:focus {

        #{$c-entry-list}__icon {
          background: $s-purple-light;
          color: $s-purple;
        }
      }
    }
  }

  &__icon {
    background: $s-blue;
    color: $s-white;
    width: rem-calc(50);
    height: rem-calc(50);
    border-radius: rem-calc(50);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(5);
    transition: all .3s ease 0s;
    margin-right: 16px;
    min-width: rem-calc(50);

    @include breakpoint(large) {
      margin-bottom: rem-calc(16);
    }

    @include breakpoint(xlarge) {
      width: rem-calc(64);
      height: rem-calc(64);
      border-radius: rem-calc(64);
      margin-bottom: rem-calc(10);
      position: absolute;
      top: rem-calc(-80);
      left: 50%;
      margin-left: rem-calc(-32);
    }
  }

  &__text {
    @include show-for(large);
    color: $s-gray-dark;
    margin-top: rem-calc(8);
  }

  &__link {
    color: $s-black;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    font-family: $s-font-family-alt;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @include breakpoint(large) {
      font-size: rem-calc(18);
      flex-direction: column;
    }

    &:hover, &:focus {
      color: $s-black;

      #{$c-entry-list}__icon {
        background: $s-teal-lighter;
        color: $s-blue;
      }

      span {
        @include link-underline($s-black);
      }
    }

          &[href^="https://"]:not([href*="www.sollentuna.se"]),
          &[href^="http://"]:not([href*="www.sollentuna.se"]) {
              span{
                &::after {
                  @include external-link-item();
                  position: absolute;
                  margin-right: unset;
                }
              }
          }
            
    span {
      position: relative;
      @include link-underline($s-white);

      &::after {
          content: url(/static/assets/images/icon-arrow-right-black.svg);
          position: absolute;
          margin-left: rem-calc(6);
          width: rem-calc(16);
          height: rem-calc(21);
      }

      }
      #{$using-keyboard} & {

        &:focus {
          background: none;

          span {
            background: $s-yellow;
          }
        }
      }
    }
  }
