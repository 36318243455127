.rekAiContent {
  margin-top: rem-calc(32);
  background-color: $s-gray;
  padding: rem-calc(32 40 48 40);

  h3 {
    padding-bottom: rem-calc(24);
    margin: 0;
    font-size: rem-calc(22);
    font-style: normal;
    font-weight: $s-font-bold;
    line-height: 120%;
  }

  ul {
    margin: 0;
  }

  .rek-prediction__item.rekai-pill a {
    color: $s-white;
    text-decoration: none;
    font-family: $s-font-family-alt;
    font-size: rem-calc(15);
    font-style: normal;
    font-weight: $s-font-semibold;
    padding: rem-calc(8 25);

    &:hover, &:focus {
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 1.5px;
    }
  }

  .rek-prediction__item.rekai-pill {
    background-color: $s-blue;
    margin: rem-calc(4);
    border-radius: rem-calc(200);
    transition: all .3s ease 0s;
    border-style: none;

    &:hover, &:focus {
      background-color: $s-blue-dark;
    }
  }
}
