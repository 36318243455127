$component: ".c-permit-card";

.c-permit-card {
  margin-left: rem-calc(6);
  margin-right: rem-calc(6);
  margin-bottom: rem-calc(15);
  display: block;
  color: $s-black;
  width: 100%;

  @include breakpoint(medium) {
    @include xy-cell(6);
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  &__image-wrapper {
    position: relative;
    @include breakpoint(small down){
      width: 40%;
    }
  }

  &__permit-desktop {
  }

  &:hover {
    color: $s-black;
  }

  &__content {
    background: $s-gray;
    position: relative;
    height: 100%;

    @include breakpoint(small down) {
      display: flex;
      height: 140px;
    }

    #{$component}:focus & {
      #{$using-keyboard} & {
        background: transparent;
      }
    }
  }

  &__text {
    padding: rem-calc(20 22 15 15);

    @include breakpoint(small down) {
      width: 60%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__permit {
    &-mobile {
      background: $s-teal-dark;
      padding: rem-calc(6 8);
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: rem-calc(12);
      color: $s-white;
      letter-spacing: rem-calc(1);
      //white-space: nowrap;
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      position: relative;
      bottom: 0;
      width: fit-content;

      @include breakpoint(medium) {
        display: none;
      }

      p {
        margin: 0;
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;


        @include breakpoint(medium down) {
          font-size: rem-calc(12);
        }

        @include breakpoint(small down) {
          font-size: rem-calc(10);
        }
      }

      svg {
        margin-right: 4px;
      }
    }

    &-desktop {
      display: none;

      @include breakpoint(medium) {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $s-teal-dark;
        color: $s-white;
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        margin: rem-calc(8);
        padding: rem-calc(6 8);
      }

      p {
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  &__category {
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem-calc(10);
    letter-spacing: rem-calc(1);

    @include breakpoint(medium) {
      font-size: rem-calc(12);
    }
  }

  &__link {
    font-size: rem-calc(20);
    line-height: normal;
    margin-bottom: 0;
  }

  &__name {
    font-size: rem-calc(14);
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }

  &__name {
    #{$component}:hover & {
      @include link-underline($s-black);
    }
  }

  &__icon {
    font-size: rem-calc(18);
  }

  &__image {
    @include breakpoint(small down) {
      /*max-height: 140px;
      max-width: 140px;*/
      object-fit: cover;
      height: 100%;
    }
  }
}
