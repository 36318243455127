.c-entrepeneurs {
    &__heading {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(15);
        line-height: normal;
    }
    &__block {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: rem-calc(20);
        background: $s-gray;
        @include breakpoint(medium) {
            padding: rem-calc(40 50);
        }
        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }
    &__content {
        width: 100%;
    }
}