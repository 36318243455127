.p-control-plan {
    &__head {
        position: relative;
        h1 {
            max-width: calc(100% - 120px);
        }
    }
    &__preamble {
        @extend .preamble;
    }
    &__container {
        @extend .c-page-grid--two-cols__article;
    }

    &__inner {
        @extend .c-page-grid--two-cols__content;
        position: relative;
    }
    &--no-print {
        @include print {
            display: none !important;
        }
    }
}
