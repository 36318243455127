.c-page-intro {
    $crisis-web: '.crisis-web';

    #{$crisis-web} & {
        border-bottom: rem-calc(2) solid $s-gray-light;
        margin-bottom: rem-calc(10);
        padding-bottom: rem-calc(15);

        @include breakpoint(large) {
            margin-bottom: rem-calc(40);
        }
    }

    &__outer {
        @extend .c-page-grid;
    }

    &__inner {
        @extend .c-page-grid__outer;
        @include flex-align(center);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            @include xy-cell(6);
        }
    }

    &__heading {
        text-align: center;
    }

    &__preamble {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(15);
        text-align: center;

        @include breakpoint(medium) {
            margin-bottom: rem-calc(40);
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(60);
        }
    }
}