.c-landing-links {
  margin-bottom: rem-calc(32);

  ul {
    list-style: none;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @include breakpoint(large) {
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    li {
      background: $s-blue;
      font-family: $s-font-family-alt;
      justify-content: space-between;
      display: flex;
      width: 49%;
      align-items: center;
      margin-bottom: rem-calc(10);
      line-height: 0;
      border-radius: rem-calc(100);

      a {
        padding: rem-calc(15 17 15 17);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: #FFF;

        &::after {
          content: "";
          background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat center right;
          position: relative;
          width: 14.002px;
          height: rem-calc(11);
          display: inline-block;
          margin-left: 8px;
        }

        @include breakpoint(large) {
          padding: rem-calc(12 32 12 32);
        }

        &:hover span {
          text-decoration: underline;
        }
      }

      @include breakpoint(large) {
        width: rem-calc(224);
        border-radius: rem-calc(70);
        margin-right: rem-calc(10);
      }

      span {
        color: #FFF;
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include breakpoint(large) {
          font-size: rem-calc(16);
          font-style: normal;
          font-weight: 700;
          line-height: rem-calc(28);
        }
      }
    }
  }
}
