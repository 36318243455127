body.lock-scroll {
    overflow: hidden;
}

.cookieModal{
    display: none;
    position: fixed;
    left: 0px;
    top: 0;
    background: rgba(0, 0, 0, 0.44);
    height: 100vh;
    width: 100vw;
    color: #fff;
    z-index: 99999;
    font-family: "Inter Regular", sans-serif;
    h2{
        font-size: 1.5rem;
    }
    h3{
        font-size: 1.7rem;
    }
    p, ul, ol {
        font-size: 1.0rem !important;
        line-height: 1.4;
    }
    p {
        max-width: 100%;
    }
    li{
        margin-bottom: 8px;
    }
    a {
        color: #004b7a;
        text-decoration: underline;
        border-bottom: none;
        &:hover {
            text-decoration: none;
        }
        &.cookiePolicy{
            display: inline;
        }
    }
    table {
        font-size: 1.0rem;
        margin-bottom: 20px;
        tr {
            td, th {
                text-align: left;
                border: 1px solid;
                padding: 10px;
                font-family: "Inter Regular", sans-serif;
            }
        }
    }
    
    .dialog {
        display: flex;
        align-items: center;
        min-height: calc((100%) - 3.5rem);
        max-width: 900px;
        padding: 0 10px;
        height: 100%;
        padding: 0 15px;
        
        @include breakpoint(medium) {
            margin: 0 auto; 
            padding: 0 20px;
        }
    }
    .content {
        position: relative;
        display: none;
        flex-direction: column;
        width: 100%;
        background: transparent;
        color: black;
        font-size: 1.0rem;
        max-height: calc((100vh) - -7rem);
        box-sizing: border-box; 
        pointer-events: auto;
        background-clip: padding-box;
        outline: 0;
        &.show{
            display: flex;
        }
    }
    .heading_wrapper {
        padding: 20px 10px;
        background: #fff;
        border-top-left-radius: .3rem;
        border-top-right-radius: .3rem;        
        @include breakpoint(medium) {
            padding: 20px 40px; 
        }
        .heading {
            margin:0;
        }
    }
    .heading {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .text_wrapper {
        padding: 10px 15px;
        background:  #fff;
        max-width: 850px;
        
        @include breakpoint(medium) {
            padding: 30px 20px 0px 20px;
        }
        &:not(.settings) {
            border-top-left-radius: .3rem;
            border-top-right-radius: .3rem;
        }
        &.settings {
            background: #fff;
            color: #333;
            overflow: auto;
            max-height: calc((80vh) - 10rem);
            @include breakpoint(medium) {
                padding: 20px 40px;
            }
        }
        img{
            width: 100%;
            max-width: 70px;
        }
        .text {
            max-height: calc((60vh) - 84px);
            overflow: auto;
            font-size: 1.0rem;
            p {
                margin-top: 0;
                font-size: 1.0rem;
            }
        }
        .option_wrapper {
            padding: 10px;
            background: rgb(247, 247, 247);
            border: solid 1px rgb(204, 204, 204);
        }
    }
    .buttons {
        position: relative;
        text-align: right;
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        gap: 12px;
        background: #fff;
        padding: 20px 10px;
        border-bottom-left-radius: .3rem;
        border-bottom-right-radius: .3rem;
        // border-top: 1px solid #ccc;
        // margin-top: -35px;
        
        @include breakpoint(medium) {
            padding: 30px 40px;
        }
        button {
            color: #212529;
            background: #f8f9fa;
            border: 1px solid #dee2e6;
            text-align: center;
            transition: all .5s ease;
            padding: 12px;
            line-height: 18px;
            cursor: pointer;
            border-radius: .125rem;
            min-width: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-family: "Inter Regular", sans-serif;
            font-weight: 600;
            font-size: 1.0rem;
            letter-spacing:0.4px;
            width: 100%;
             
            @include breakpoint(medium) {
                width: auto;
                min-width: 90px;
            }
            &:hover {
                background-color: #e2e6ea;
                color: #212529;
                // text-decoration: underline; 
            }
        }
        .blueBtn {
            border-radius: 4.375rem;
            border: 1px solid  #004b7a;
            background: #004b7a;    
            color: #fff;
            &:hover {
                background-color: #00385C;
                color: #fff;
                // text-decoration: underline;
            }
        }
        .backbtn {
            border-radius: 4.375rem;
        }
        .whiteBtn {
            border-radius: 4.375rem;
            border: 1px solid #4b4b4b;
            background: transparent;    
            color: Black;
            &:hover {
                background-color: #00385C;
                color: #fff;
                // text-decoration: underline;
            }
        }
    }
    // input.input-checkbox:active + label, input.input-checkbox:focus + label {
    //     outline: 1px solid #555;
    // }

    .input-checkbox, .input-radio {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &:focus-visible{
            + label{
                color: #284898;
                outline: 2px solid black;
                border-radius: 3px;
            }
        }
        + .label {
            background: transparent;
            padding-bottom: 0.2rem;
            padding-left: 0;
            margin-left: 0;
            position: relative;
            cursor: pointer;
            font-size: 1.2rem;
            color: #000;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            &::after {
                content: "\f204";
                font-size: 2.0rem;
                font-family: 'FontAwesome';
                transition: .5s all;
            }
        }
        &:checked + .label::after {
            content: "\f205";
            color:#004b7a;
        }
        &:disabled + .label::after {
            content: "\f205";
            color: #ccc;
        }
    }
    .cookie-label {
        display: block;
        font-weight: 700;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-bottom: 0.6rem;
        font-size: 1.2rem;
    }
    .cookieinfo {
        color: #004b7a;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        background: transparent;
        font-size: 1.0rem;
        margin-bottom: 20px;
        &.expand{
            &:before {
                content: '\f078';
                font-family: 'FontAwesome';
                padding-right: 10px;
                font-size: 1.0rem;
                font-weight: bold;
                color: #284898;
            }
            &.minify:before {
                content: '\f077';
            }
        }
    }
    .cookieinfo_content {
        display: none;
        background: #fff;
        padding: 5px;
        border-radius: 3px;
        margin-top: 5px;
        &.show{
            display: block;
        }
    }
    .closebtn{
        display: none;
        &.show{
            display: block;
        }
    }
    .acceptAll{
        display: none;
        &.show{
            display: block;
        }
    }
    .hidden{
        display: none;
    }
    .policy-content{
        word-break: break-word;
    }
}