.g-navigation-mobile {
    $g-navigation-mobile: '.g-navigation-mobile';
    $using-keyboard: '.using-keyboard';
    background: $s-brand;
    display: none;
    position: absolute;
    width: 100%;
    z-index: 10;

    @function tint($color, $percentage) {
        @return mix($s-white, $color, $percentage);
    }
    
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-bottom: rem-calc(1) solid $s-brand-tint;
    }

    &__link {
        color: $s-white;
        font-family: $s-font-family-alt;
        font-size: rem-calc(15);

        &:hover, &:focus {
            color: $s-white;
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }

        span {
            line-height: rem-calc(22);
        }

        &--active {
            
            span {
                @include link-underline($s-white);
                padding-bottom: 0;
            }
        }
    }

    &__menu {
        
        #{$g-navigation-mobile}__item {
            position: relative;
            border-bottom: rem-calc(1) solid $s-brand-tint;

            &:last-of-type {
                border: 0;
            }

            &--expanded {
                border: 0;

                #{$g-navigation-mobile}__list {
                    border: 0;
                }
            }
        }

        #{$g-navigation-mobile}__link {
            min-height: rem-calc(50);
            display: flex;
            align-items: center;
            padding-right: rem-calc(60);
            
            span {
                position: relative;
                height: auto;
                display: inline-block;
                align-items: center;
            }
        }

        #{$g-navigation-mobile}__button {
            color: $s-white;
            background: $s-brand-tint;
            height: rem-calc(50);
            width: rem-calc(62);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: rem-calc(-15);
            text-align: center;
            font-size: rem-calc(25);
            font-family: $s-font-family-alt;

            &::before {
                content: "";
                border-top: 0.0625rem solid $s-brand-tint;
                display: block;
                position: absolute;
                top: rem-calc(-1);
                width: 100%;
            }

            &::after {
                content: "−";
            }

            &:hover {
                cursor: pointer;
            }

            &:focus {
                outline: 0;
            }

            &--collapsed {
                background: $s-brand;

                &::before {
                    content: none;
                }

                &::after {
                    content: "+";
                }
            }
        }

        #{$g-navigation-mobile}__list {
            padding: rem-calc(0 15);

            &--collapsed {
                display: none;
            }
    
            &--level2 {
                background: $s-teal-light;
                background: $s-white;
                margin: rem-calc(0 -15);
                
                #{$g-navigation-mobile}__link {
                    color: $s-blue;
    
                    &:hover {
                        color: $s-blue;
                    }

                    &--active {

                        span {
                            @include link-underline($s-blue);
                            padding-bottom: 0;
                        }
                    }
                }

                #{$g-navigation-mobile}__button {
                    color: $s-blue;
                    background: $s-teal-light;

                    &::before {
                        border-color: $s-teal-light;
                    }
    
                    &:hover {
                        color: $s-blue;
                        cursor: pointer;
                    }

                    #{$using-keyboard} & {

                        &:focus {
                            background: $s-yellow;
                            color: $s-black;
                        }
                    }

                    &--collapsed {
                        background: $s-white;
                    }
                }

                #{$g-navigation-mobile}__item {
                    border-color: tint($s-brand, 90);
                }
            }
    
            &--level3 {
                background: $s-teal-light;
                margin: rem-calc(0 -15);
                padding: rem-calc(0 15 0 30);
                display: none;
    
                #{$g-navigation-mobile}__link {
                    color: $s-blue;
    
                    &:hover {
                        color: $s-blue;
                    }

                    &--active {

                        span {
                            @include link-underline($s-blue);
                            padding-bottom: 0;
                        }
                    }
                }

                #{$g-navigation-mobile}__button {
                    background: tint($s-teal, 90);

                    &::before {
                        border-color: tint($s-teal, 90);
                    }

                    &--collapsed {
                        background: $s-teal-light;
                    }
                }

                #{$g-navigation-mobile}__item {
                    border-color: tint($s-brand, 90);
                }
            }

            &--level4 {
                background: tint($s-teal, 90);
                margin: rem-calc(0 -15 0 -30);
                padding: rem-calc(0 15 0 45);
                border: 0;
    
                #{$g-navigation-mobile}__link {
                    color: $s-blue;
    
                    &:hover {
                        color: $s-blue;
                    }

                    &--active {

                        span {
                            @include link-underline($s-blue);
                            padding-bottom: 0;
                        }
                    }
                }

                #{$g-navigation-mobile}__button {
                    background: tint($s-teal, 85);

                    &--collapsed {
                        background: tint($s-teal, 90);
                    }

                    &::before {
                        border-color: tint($s-teal, 85);
                    }
                }

                #{$g-navigation-mobile}__item {
                    border-color: tint($s-brand, 85);
                }
            }

            &--level5 {
                background: tint($s-teal, 85);
                margin: rem-calc(0 -15 0 -45);
                padding: rem-calc(0 15 0 60);
                border: 0;

                #{$g-navigation-mobile}__link {
                    color: $s-blue;
    
                    &:hover {
                        color: $s-blue;
                    }

                    &--active {

                        span {
                            @include link-underline($s-blue);
                            padding-bottom: 0;
                        }
                    }
                }

                #{$g-navigation-mobile}__item {
                    border-color: tint($s-brand, 80);
                }
            }
        }
    }
}
