.p-contact {
    padding: 0;
    @include breakpoint(large) {
        //padding-top: rem-calc(60);
        padding: rem-calc(0 95);
    }

    &__outer {
        
        @include breakpoint(large) {
            border-top: rem-calc(2) solid $s-gray-light;
        }
    }

    &__inner {
        @extend .c-page-grid;
    }

    &__block-area {
        @extend .c-page-grid__outer;
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(15);

        @include breakpoint(medium) {
            padding-top: rem-calc(30);
            padding-bottom: 0;
        }

        @include breakpoint(large) {
            padding-top: rem-calc(60);
        }
    }
}
