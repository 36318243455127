/* Different layout on Landing Page, Block - Half width */

.c-article {
    $c-article: '.c-article';
    $c-block-grid-half: '.c-block-grid--half';
    $p-landing: '.p-landing__inner .p-landing__block-area';
    $c-button: '.c-button';
    $using-keyboard: '.using-keyboard';
    height: 100%;

    @include breakpoint(large) {
        padding-bottom: rem-calc(20);
    }

    &__outer {
        @include xy-grid();
        height: 100%;
        flex-direction: column;
        padding-bottom: rem-calc(16);

        @include breakpoint(large) {
            flex-direction: row;
            padding-bottom: 0;
            position: relative;
        }

        &--bg-blue,
        &--bg-teal,
        &--bg-orange,
        &--bg-pink, 
        &--bg-yellow {

           /* @include breakpoint(small down) {
                padding: 0;
            }*/

            #{$c-article}__inner {
                padding: rem-calc(15 15 20 15);

                @include breakpoint(small down) {
                    margin: rem-calc(0 -15);
                }

                @include breakpoint(medium) {
                    padding: rem-calc(30 30 30 30);
                }

                @include breakpoint(large) {
                    padding: rem-calc(40 50 45 50);
                }
            }

            #{$c-article}__item {

                &:first-child,
                &:nth-child(2),
                &:last-child {

                    #{$c-article}__button {
                        background: none;
                        color: $s-blue;
                        border: rem-calc(2) solid $s-blue;

                        &:hover {
                            background: $s-blue;
                            color: $s-white;
                        }

                        #{$using-keyboard} & {

                            &:focus {
                                background: $s-yellow;
                                border-color: $s-black;
                            }
                        }
                    }
                }
            }

            #{$c-button} {
                border: rem-calc(2) solid $s-blue;
            }
        }

        &--bg-blue {
            
            #{$c-article}__inner {
                background: $s-brand;
                color: $s-white;
            }
            
            #{$c-article}__tag,
            #{$c-article}__link {
                color: $s-white;
            }
    
            #{$c-article}__link {
                
                &::after {
                    background: $s-white;
                }

                &:hover {
                    background: $s-teal-light;
                    border-color: $s-teal-light;
                    color: $s-blue;

                    span::after {
                        background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                    }
                }
            }

            #{$c-article}__item {

                &:first-child,
                &:nth-child(2),
                &:last-child {

                    #{$c-button} {
                        color: $s-white;
                        border-color: $s-white;

                        &:hover {
                            background: $s-teal-light;
                            color: $s-blue;
                            border-color: $s-teal-light;
                        }
                    }
                }
            }
        }

        &--bg-teal {
        
            #{$c-article}__inner {
                background: $s-teal-light;
            }
        }

        &--bg-yellow {
        
            #{$c-article}__inner {
                background: $s-orange-light;
            }
        }
        &--bg-pink {
        
            #{$c-article}__inner {
                background: $s-pink-light;
            }
        }
    }

    &__inner {
        padding: rem-calc(15 0 20 0);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(large) {
            @include xy-cell(6, $gutters: 0);
            padding: rem-calc(34 50 39 50);
            background: $s-white;
        }
    }

    &__tag {
        text-transform: uppercase;
        font-size: rem-calc(12);
        color: $s-gray-dark;
        font-weight: $s-font-bold;
        font-family: $s-font-family-alt;
        letter-spacing: rem-calc(1);
        margin-bottom: rem-calc(10);
        display: block;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: rem-calc(0 0 5 0);
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(large) {
            margin: 0;
        }
    }

    &__item {
        margin: rem-calc(10 10 0 0);

        #{$c-button} {
            border: 0;

            #{$using-keyboard} & {

                &:focus {
                    background: $s-yellow;
                }
            }
        }

        &:first-child {

            #{$c-button} {
                background: $s-orange-light;
                color: $s-orange;

                &:hover {
                    background: $s-orange;
                    color: $s-white;
                }
            }
        }

        &:nth-child(2) {

            #{$c-button} {
                background: $s-teal-light;
                color: $s-teal-dark;

                &:hover {
                    background: $s-teal;
                    color: $s-white;
                }
            }
        }

        &:last-child {

            #{$c-button} {
                background: $s-pink-light;
                color: $s-pink;

                &:hover {
                    background: $s-pink;
                    color: $s-white;
                }
            }

            @include breakpoint(large) {
                margin: rem-calc(10 0 0 0);
            }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--small;
    }

    &__link {
        border: rem-calc(2) solid $s-blue;
        border-radius: rem-calc(2);
        height: rem-calc(40);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease 0s;
        font-weight: $s-font-bold;

        @include breakpoint(medium) {
            margin-top: rem-calc(5);
            max-width: rem-calc(300);
        }

        #{$using-keyboard} & {

            &:focus {
                
                span::after {
                    background: url(/static/assets/images/icon-arrow-right-black.svg) no-repeat;
                }
            }
        }

        &:hover {
            background: $s-blue;
            color: $s-white;

            span::after {
                background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat;
            }
        }

        span {

            &::after {
                content: "";
                background: url(/static/assets/images/icon-arrow-right-blue.svg) no-repeat;
                display: inline-block;
                width: rem-calc(12);
                height: rem-calc(11);
                margin-left: rem-calc(5);
                position: relative;
                top: rem-calc(1);
                transition: all .3s ease 0s;
            }
        }
    }
    .rounded-button{
        margin: unset;
    }
    &__overflow {

        @include breakpoint(large) {
            @include xy-cell(6, $gutters: 0);
            @include flex-order(1);
            min-height: rem-calc(352);
        }
    }

    &__image {

        @include breakpoint(small down) {
            margin: rem-calc(0 -15);
        }

        @include breakpoint(large) {
            width: 100%;
            z-index: -1;
            position: absolute;
            right: 0;
            height: 100%;
        }

        img {

            @include breakpoint(large) {
                right: 0;
                position: absolute;
                min-width: 50%;
                height: 100%;
            }
        }
    }

    &__outer--bg-blue {
        color: $s-white;

        #{$c-article}__link {
            border-color: $s-white;

            span {

                &::after {
                    background: url(/static/assets/images/icon-arrow-right-white.svg) no-repeat;
                }
            }
        }
    }

    /* Different layout on Landing Page */
    #{$p-landing} & {

        &__inner {

            @include breakpoint(large) {
                padding: rem-calc(25 30 30 30);
            }
        }

        &__overflow {

            @include breakpoint(large) {
                min-height: rem-calc(261);
            }
        }
    }

    /* Different layout in Block - Half Width */
    #{$c-block-grid-half} & {

        &__link {
            position: relative;
            font-weight: $s-font-bold;
            font-size: rem-calc(15);
            border: 0;
            border-radius: 0;
            justify-content: normal;
            height: auto;
            max-width: inherit;
            width: auto;
            display: inline-block;
            padding: 0;
            &:hover {
                background: none;
                color: $s-blue;
            }

            span {
                @include link-underline($s-blue);

                &::after {
                    content: none;
                }
            }

            #{$using-keyboard} & {

                &:focus {
                    background: none;

                    span {
                        background: $s-yellow;
                    }
                }
            }
        }

        &__outer {
            flex-direction: column;
        }

        &__inner {
            justify-content: inherit;
            
            @include breakpoint(large) {
                width: 100%;
                padding: rem-calc(30 50 0 50);
            }
        }

        &__overflow {
            display: none;
        }

        &__image {

            @include breakpoint(large) {
                @include xy-cell(12, $gutters:0);
                order: inherit;
                position: relative;
                height: auto;
                right: auto;
            }

            img {

                @include breakpoint(large) {
                    position: relative;
                    height: auto;
                    min-width: auto;
                    right: auto;
                }
            }
        }

        &__outer--bg-blue {

            #{$c-article}__link {

                span {
                    @include link-underline($s-white);
                }

                &:hover {
                    color: $s-white;
                }
            }
        }

        &__outer--bg-blue,
        &__outer--bg-teal,
        &__outer--bg-orange,
        &__outer--bg-yellow,
        &__outer--bg-pink {

            #{$c-article}__inner {
                @include breakpoint(large) {
                    padding: rem-calc(30 50 45 50);
                }
            }
        }
    }
}
