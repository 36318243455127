.c-call-to-action {
    margin: rem-calc(0 -15);

    @include breakpoint(medium) {
        margin: rem-calc(0 0 30 0);
    }

    @include breakpoint(large) {
        margin: rem-calc(0 0 60 0);
    }
    
    &__outer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        @include breakpoint(large) {
            flex-direction: row;
            align-items: unset;
            background: $s-gray-silver;
        }
    }

    &__inner {
        @include xy-cell(12, $gutters:0);

        @include breakpoint(medium) {
            @include xy-cell(10, $gutters:0);
        }

        @include breakpoint(large) {
            @include xy-cell(6, $gutters:0);
        }

        &--left {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
        }

        &--right {
            background: $s-gray-silver;
            padding: rem-calc(15 15 5 15);
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include breakpoint(medium) {
                padding: rem-calc(20 30 15 30);
            }

            @include breakpoint(large) {
                padding: rem-calc(30 60 20 60);
            }
        }
    }

    &__background {
        @include responsive-embed($ratio:16 by 9);
        margin-bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 100%;
        height: 100%;
    }

    &__content {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        height: 100%;
        width: 100%;
    }

    &__heading {
        color: $s-white;
        @include text-shadow();
        font-size: rem-calc(32);
        margin-bottom: rem-calc(7);
    }

    &__link {
        @extend .c-button;
        @extend .c-button--alt;
        width: auto;

        span {
            font-family: $s-font-family-alt;
            top: rem-calc(1);

            &::before {
                content: '\f007';
                font-family: $s-font-icon;
                font-size: rem-calc(12);
                margin-right: rem-calc(5);
                position: relative;
                top: rem-calc(-1);
            }
        }
    }
}