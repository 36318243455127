$c-block-grid-half: '.c-block-grid--half';
$c-block-grid-third: '.c-block-grid--third';
$c-block-grid-two-thirds: '.c-block-grid--two-thirds';
.c-instagram {
    height: 100%;
    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
    }
    @include breakpoint(large) {
        padding-bottom: rem-calc(60);
    }
    &__heading {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(30);
        width: 100%;
    }
    &__outer {
        @include xy-grid();
        @include xy-gutters($negative: true);
        @include flex-align(center);
        height: 100%;
    }
    &__inner {
        background: $s-gray;
        @include xy-cell(12, $gutters: 0);
        padding: rem-calc(20 15 30 15);
        @include breakpoint(medium) {
            padding: rem-calc(15 30 30 30);
            @include xy-cell(12);
        }
        @include breakpoint(large) {
            padding: rem-calc(30 50 40 50);
            display: flex;
            flex-direction: column;
        }
    }
    &__icon {
        padding-right: rem-calc(5);
    }
    &__footer {
        display: flex;
        justify-content: flex-end;
    }
    &__link {
        @extend .c-button;
        width: 100%;
        min-width: 0;
        max-width: 100%;
        padding: rem-calc(0 40);
        display: inline-flex;
        align-items: center;
        @include breakpoint(large) {
            width: auto;
        }
        #{$c-block-grid-third} &,
        #{$c-block-grid-half} & {
            width: 100%;
        }
        &[href^="http://"]:not([href*="www.sollentuna.se"]),
        &[href^="https://"]:not([href*="www.sollentuna.se"]) {
            &:after {
                @include external-link-item();
                margin-left: rem-calc(5);
                color: inherit;
                margin-top: rem-calc(1);
            }
        }
    }
}