/* Sollentuna kommun - Primary - Shared */

$s-font-family: 'Inter Regular', sans-serif;
$s-font-family-alt: 'Inter Bold', sans-serif;
$s-font-icon: 'Font Awesome 6 Pro';

$s-font-regular: 400;
$s-font-semibold: 600;
$s-font-bold: 700;

$s-white: #fff;
$s-black: #1B1B1A;
$s-gray: #f5f5f5;
$s-gray-silver: #fafafa;
$s-gray-dark: #707070;
$s-gray-light: #E8E8E8;
$s-gray-border: #909090;
$s-gray-medium: #767676;

$s-blue: #004b7a; 
$s-blue-light: #E8EDF3;
$s-blue-tint: #266AAE;
$s-blue-dark: #00385C;

$s-pink: #dd052b;
$s-pink-light: #FDE4E8; 
$s-pink-dark: #91001a; 

$s-purple: #80388D;
$s-purple-light: #F6F1F7;

$s-yellow: #FBCE20;

$s-orange: #CF410E;
$s-orange-light: #FFF8D5;
$s-orange-dark: #c53e0d;

$s-red-light: #fccdcd6e;
$s-red-dark: #91001a;

$s-teal-dark: #0070ba;
$s-teal-quicklinks: #0070BA80; 
$s-teal: #e7f3fb; 
$s-teal-light: #eff7fc; 
$s-teal-lighter: #f7fbfd; 
$s-teal-dark: #0070ba; 
$s-teal: #e7f3fb;
$s-teal-light: #e7f3fb;
$s-teal-lighter: #e7f3fb;
$s-teal-portal: #E7F3FB;
$s-teal-light-portal: #EFF7FC;
$s-teal-lighter-portal: #F7FBFD; 

$s-warning: #FF0000;

/* Brand Color */
$s-brand: $s-blue;
$s-brand-tint: $s-blue-tint;
