.c-event-info {
    $c-event-info: '.c-event-info';
    $c-event-info-secondary: '.c-event-info__secondary';
    $using-keyboard: '.using-keyboard';
    @include xy-grid;
    @include xy-gutters($negative: true);
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);
    position: relative;

    @include breakpoint(large) {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(40);
    }

    &__background {
        background: $s-teal-light;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100%;
        right: -100%;
        z-index: -1;

        &--dates {
            background: $s-gray-silver;
        }
    }

    &__border {
        background: $s-gray-light;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100%;
        right: -100%;
        height: rem-calc(2);
        z-index: -1;
    }

    &__inner {
        @include xy-cell(12,$gutters:0);
        padding: rem-calc(20 0);
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            @include xy-cell(12);
        }
    }

    &__content {
        padding: rem-calc(0 15);

        @include breakpoint(large) {
            padding: 0;
        }
    }

    &__wrapper {

        @include breakpoint(large) {
            margin-top: rem-calc(-5);
        }

        @include breakpoint(xlarge) {
            padding-left: 0;
        }
    }

    &__date {
        font-size: rem-calc(24);
        font-family: $s-font-family-alt;
        color: $s-teal-dark;
        display: block;
        margin-bottom: rem-calc(15);
        padding-left: rem-calc(40);

        @include breakpoint(medium) {
            font-size: rem-calc(28);
            margin-bottom: rem-calc(10);
            padding-left: 0;
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(15);
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        margin-bottom: rem-calc(20);

        @include breakpoint(medium) {
            flex-direction: row;
            margin-bottom: rem-calc(-5);
        }

        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }

    &__time {
        display: inline-block;
        font-weight: $s-font-semibold;

        &::before {
            content: "\f017";
            font-family: $s-font-icon;
            margin-right: rem-calc(1);
            font-weight: $s-font-regular;
            font-size: rem-calc(15);
        }
    }

    &__location {
        font-weight: $s-font-bold;
        position: relative;
        margin: rem-calc(0 40 10 0);
        display: inline;

        @include breakpoint(large) {
            margin: rem-calc(0 40 0 0);
        }

        &::before {
            content: "\f3c5";
            font-family: $s-font-icon;
            font-size: rem-calc(13);
            margin-right: rem-calc(5);
        }

        #{$using-keyboard} & {

            &:focus {
                background: none;

                span {
                    background: $s-yellow;
                }
            }
        }
    }

    a {

        &#{$c-event-info}__location {

            &[href^="https://"]:not([href*="www.sollentuna.se"]),
            &[href^="http://"]:not([href*="www.sollentuna.se"]) {

                &::after {
                    @include external-link-single(3);
                }
            }

            span {
                @include link-underline($s-blue);
            }

            #{$using-keyboard} & {

                &:focus {
    
                    &::after {
                        color: $s-black;
                    }
                }
            }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--right;
        font-family: $s-font-family-alt;
        margin: rem-calc(20 0 0 0);

        @include breakpoint(medium only) {
            max-width: rem-calc(300);
        }

        @include breakpoint(xlarge) {
            margin: rem-calc(-20 0 0 0);
            width: rem-calc(200);
            min-width: auto;
            right: 0;
            position: absolute;
            top: 50%;
        }

        @include breakpoint(xlarge) {
            right: rem-calc(-90);
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        border-bottom: rem-calc(2) solid $s-gray-light;
        padding: rem-calc(15 0);
        position: relative;
        margin-left: rem-calc(44);

        @include breakpoint(large) {
            margin-left: rem-calc(54);
        }

        &::before {
            content: "\f073";
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $s-font-icon;
            background: $s-white;
            height: rem-calc(30);
            width: rem-calc(30);
            border-radius: rem-calc(30);
            position: absolute;
            top: rem-calc(10);
            left: rem-calc(-44);

            @include breakpoint(large) {
                left: rem-calc(-54);
            }
        }

        &:last-child {
            border: 0;
        }
    }

    &__duration {
        font-family: $s-font-family-alt;
        font-size: rem-calc(12);
        text-transform: uppercase;
        display: flex;
        align-items: center;

        #{$c-event-info}__time {
            display: inline-flex;
            letter-spacing: rem-calc(1);

            &::before {
                content: "\00a0\00a0|\00a0\00a0";
                font-family: $s-font-family-alt;
                font-size: rem-calc(12);
                position: relative;
                top: rem-calc(-1);
                margin: 0;
            }
        }

        #{$c-event-info}__date {
            color: $s-black;
            font-size: rem-calc(12);
            margin: 0;
            display: inline;
            letter-spacing: rem-calc(1);
            padding: 0;
        }
    }

    &__day {
        color: $s-black;
        font-size: rem-calc(12);

        &::after {
            content: "\00a0\00a0|\00a0\00a0";
            position: relative;
            top: rem-calc(-1);
        }
    }

    &__start {

        &::after {
            content: "\00a0\00a0\00a0";
        }
    }

    &__link {
        color: $s-black;
        font-weight: $s-font-bold;
        position: relative;
        padding-left: rem-calc(30);
        display: inline-block;

        #{$using-keyboard} & {

            &:focus {
                background: none;
                
                span {
                    background: $s-yellow;
                }
            }
        }

        &:hover, &:focus {

            span {
                color: $s-black;
                @include link-underline($s-black);
            }
        }

        &::before {
            content: "+";
            background: $s-teal-light;
            height: rem-calc(24);
            width: rem-calc(24);
            border-radius: rem-calc(24);
            display: inline-block;
            color: $s-blue;
            font-size: rem-calc(20);
            font-family: $s-font-family-alt;
            text-align: center;
            margin-right: rem-calc(10);
            top: rem-calc(-1);
            left: 0;
            position: absolute;
            line-height: rem-calc(24);
        }

        #{$c-event-info-secondary}--expanded & {

            &::before {
                content: "−";
                background: $s-blue;
                color: $s-white;
            }
        }
    }

    &__primary, &__panel, &__events {
        position: relative;
        width: 100%;
    }

    &__primary {

        #{$c-event-info}__inner {

            @include breakpoint(large) {
                padding: rem-calc(40 0);
            }
        }

        #{$c-event-info}__content {

            &::before {
                content: "\f073";
                font-family: $s-font-icon;
                font-size: rem-calc(20);
                color: $s-teal-dark;
                position: absolute;
                background: $s-white;
                height: rem-calc(30);
                width: rem-calc(30);
                border-radius: rem-calc(30);
                display: flex;
                justify-content: center;
                align-items: center;
                top: rem-calc(23);
                left: rem-calc(15);
                font-size: rem-calc(16);
    
                @include breakpoint(medium) {
                    top: rem-calc(22);
                    height: rem-calc(40);
                    width: rem-calc(40);
                    left: rem-calc(-40);
                    border-radius: rem-calc(40);
                }
    
                @include breakpoint(large) {
                    font-size: rem-calc(18);
                    top: rem-calc(40);
                }
    
                @include breakpoint(xlarge) {
                    height: rem-calc(70);
                    width: rem-calc(70);
                    border-radius: rem-calc(70);
                    font-size: rem-calc(28);
                    margin-top: rem-calc(-35);
                    top: 50%;
                    left: rem-calc(-90);
                }
            }
        }
    }

    &__secondary {

        #{$c-event-info}__wrapper {
            margin: 0;
            display: none;
        }
    }

    &__panel {

        #{$c-event-info}__inner {
            padding: rem-calc(20 0 15 0);
        }
    }

    &__secondary {
        width: 100%;

        &--expanded {

            #{$c-event-info}__events {
                display: block;
            }
        }
    }
}