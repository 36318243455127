.p-custom-error{
    
    @extend .c-page-grid;
    @include gradient-bg();    
    $using-keyboard: '.using-keyboard';

    a{
        #{$using-keyboard} & {

            &:focus {
                background: no-repeat left center;
                outline: rem-calc(3) solid $s-yellow !important;
            }
        }
    }


    &__outer {
        @extend .c-page-grid--center__outer;
    }

    &__inner {
        @extend .c-page-grid;
    }

    header {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(20);

        img {
            max-width: 90px;
        }
    }

    .section-error {
        display: flex;
        justify-content: center;
    }

    .container-error{
        background-color: $s-teal-light;
        width: rem-calc(900);
        padding: rem-calc(40);
    }

    .area-heading{
        font-size: rem-calc(90);
        font-weight: 600;
        margin-bottom: rem-calc(50);
        color: $s-blue;
        text-align: center;
    }

}